// home page css start ////////////////////////////////////

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.header-bar {
  padding: 10px;
  background-color: #ab94e0;
}

.top-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .req-btn {
    color: #000;
  }
}

.head-btn {
  border: 2px solid black;
  padding: 1px 20px;
  border-radius: 35px;
  background-color: white;
  font-size: 14px;
  font-weight: 700;
  color: #000;

  a {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
  }
}

.searchform i.iconsearch {
  color: #1e1d1e;
}
.contact_us .pop-up-body p, .contact_us .pop-up-body small {
  color: #1e1d1e;
}

.productdetails .sorting {
  align-items: end;
}
.req-btn {
  border: 2px solid black;
  padding: 1px 20px;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  font-family: NeueTarasque;
}

.top-header button:not(:last-child) {
  margin-right: 50px;
}

.nav-section .inner-area {
  display: flex;
  padding: 20px;
  align-items: center;
}

.nav-wrapper {
  border-bottom: 0.5px solid #00000040;
}

.nav-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  max-width: 1620px;
}

.nav-heart-user {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 30px;
  margin-left: 20px;

  .nav-heart,
  .nav-user {
    position: relative;
    padding: 5px 15px;
    cursor: pointer;

    i {
      font-size: 18px;
    }
  }
}

.nav-heart:after {
  border-left: 1px solid black;
  content: "";
  display: block;
  height: 25px;
  left: 46px;
  position: absolute;
  top: 5px;
  width: 1px;
  z-index: 0;
}

.close-icon {
  position: relative;
}

.close-icon>i {
  position: absolute;
  top: -30px;
  right: 50px;
  font-size: 20px;
}

.main-navbar {
  display: flex;
  justify-content: center;
  gap: 60px;
  line-height: 26px;
  align-items: center;
  text-align: center;
  padding: 15px;
  box-shadow: 0 10px 10px -5px;
  color: #1e1d1e;
  cursor: pointer;
}

.home-page-banner {
  background-color: #f4f1ee;
  padding-bottom: 2rem;
}

.home-page-banner-section {
  text-align: center;
  // width: 77%;
  width: 100%;
  max-width: 1562px;
  margin: 0 auto;
}

.mini-nav {
  display: flex;
  justify-content: center;
  line-height: 26px;
  align-items: center;
  text-align: center;
  padding: 15px;
  color: #1e1d1e;
  list-style: none;

  li {
    font-size: 14px;
    padding: 2px 21px;
    line-height: 21px;
    color: #000000;
    opacity: 0.6;
    font-weight: 500;
    cursor: pointer;
  }

  .active {
    border-radius: 20px;
    background: #ab94e0;
    color: #000000;
    opacity: 1;
    font-weight: 700;

    a {
      font-weight: 600 !important;
      display: flex;
    }
  }
}

.black-line {
  height: 40px;
  background: #1e1d1e;
}

.proven-results-section {
  background-image: url("../assets/headerpage-icon/vector2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center, bottom;
}

.proven-results {
  width: 100%;
  padding: 80px 0 130px;
  margin: 0 auto;
  background: #f4f1ee;
  text-align: center;
  max-width: 1010px;

  @media (max-width:1024.5px) {
    max-width: 760px;
  }

  @media (max-width:769.5px) {
    max-width: 95%;
    padding: 20px;
  }

  .bold-txt {
    font-size: 200px;
    line-height: 300px;
    font-weight: 700;
    color: #ab94e0;
    border-bottom: 2px solid;
    margin: 0 80px;
    margin-bottom: 0 !important; 
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
  }


  .creativity {
    margin-bottom: 0 !important;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    font-family: RoslindaleDisplayCondensed;
    color: #000;
    font-weight: 300;
    padding: 40px 110px;
  }

  .bold-txt-1 {
    font-size: 200px;
    line-height: 300px;
    font-weight: 700;
    color: #ab94e0;
    border-top: 2px solid;
    margin: 0 80px;
    margin-bottom: 0 !important;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
  }

  @media (max-width:1024.5px) {

    .bold-txt,
    .bold-txt-1 {
      font-size: 150px;
    }
  }

  @media (max-width:769.5px) {

    .bold-txt,
    .bold-txt-1 {
      font-size: 70px;
      line-height: inherit;
    }
  }
}

.cont-us {
  width: 500px;
  // height: 50px;
  padding: 12px 50px 10px;
  border-radius: 34px;
  border: 1px solid #686868;
  font-size: 16px; 
  line-height: 24px;
  text-align: center;
  background: #ab94e0;
  font-family: NeueTarasque;
  font-weight: 600;
  color: #000;

  @media (max-width:769.5px) {
    width: 100%;
    max-width: 500px;
  }
}

button.btn.btn-lg.signupBtn {
  border: 1px solid;
  border-radius: 40px;
}

.decoration-card a {
  color: #000000;
  text-decoration: none;
}

.cont-us:hover {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, #ab94e0 0px 3px 6px;
  color: #000;
}

.serving-section {
  margin: 100px auto;
  width: 100%;
  max-width: 875px;
  text-align: center;
  background: #fff;

  @media (max-width:769.5px) {
    margin: 60px auto;
    // .serving-txt {

    // }
  }
}

.typewriter-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(2rem, 2.8vw, 5rem);
  font-weight: bold;
}

.typewriter {
  width: 21.5ch;
  white-space: nowrap;
  overflow: hidden;
  animation: cursor step-start infinite, text ease-in 7s alternate infinite;
}

@keyframes cursor {

  0%,
  100% {
    border-color: #212121;
  }
}

@keyframes text {
  0% {
    width: 0;
  }

  100% {
    width: 21.5ch;
  }
}

button.btn.btn-lg.loginBtn {
  border: 1px solid;
  padding: 10px 44px;
  border-radius: 40px;
  background: black;
  color: #fff;
}

a.d-flex.justify-content-end.loginSignup {
  color: #ff8f2b;
  text-decoration: none;
}

.serving-slider .slick-list .slick-slide>div {
  margin: 10px;
}

.serving-txt {
  font-family: RoslindaleDisplayCondensed;
  font-size: 36px;
  font-style: italic;
  font-weight: 300;
  line-height: 50px;
  text-align: center;
  padding: 34px 0;
  color: #7922d6;
}

.serving-card-section {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.sarving-card {
  border: 1px solid #ab94e0;
  margin: 10px 10px;
  text-align: center;
  background: #f4f1ee;
  border-radius: 10px;
  color: #7922d6;
  font-size: 24px !important;
  font-weight: 500;
  height: 308px;
  width: 209px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  @media (max-width: 1366px) {
    height: 238px;
  }
}

.clip-path-section {
  height: 700px;
  border-radius: 0;
  width: 100%;
  background: #EBE2FF;
  clip-path: polygon(0 0, 100% 100%, 0% 100%);

  @media (max-width: 1024.5px) {
    height: 360px;
  }
}

.serving-main-section {
  padding: 3em 0.5em;
}

.clip-path-img-section {
  z-index: 9;
  left: 50%;
  top: -20px;

  img {
    max-width: 244px;

    @media (max-width: 1024.5px) {
      max-width: 96px;
    }
  }
}

.animation-scroll-img-section {
  display: flex;
  justify-content: space-around;
  background: #c7c0d9;
  margin: 0 auto;
  gap: 100px;
  padding: 0 100px;
}

.animation-img {
  width: 100%;
  text-align: center;
}

.animation-img-img {
  width: 73%;
}

.animation-txt {
  width: 100%;
  margin: 50px 0;
}

.animation-txt-1 {
  border: 1px solid #7a56cb;
  width: 630px;
  height: 600px;
  margin: 70px 0;
}

.animation-txt-2 {
  width: 615px;
  border: 1px solid #7a56cb;
  height: 585px;
  margin: 8px;
}

.animation-txt-3 {
  width: 597px;
  border: 1px solid #7a56cb;
  height: 567px;
  margin: 8px;
  background: white;
}

////////////////////////////////////////////////////////////////////////
///

/* design */

.design {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #EBE2FF;
  padding-bottom: 77px;
  overflow-x: hidden;
}

.card-border {
  border: 1px solid #7922d6;
  padding: 10px;
}

.decoration-card {
  border: 1px solid #7922d6 !important;
  text-align: center;
  border-radius: 0 !important;
  margin: 0;
  height: 800px;
  background-color: #F4F1EE !important;

}

.decoration-card img {
  width: 88px;
}

.text-bg {
  width: 70%;
  background-image: url("../../src/assets/headerpage-icon/text-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #1e1d1e;
  font-size: 60px;
  font-family: "RoslindaleDisplayCondensed";
  font-weight: 300;
}

.animate-banner {
  padding: 0 50px;
}

.animate_banner1-img {
  text-align: end;
  padding-right: 100px !important;
}

.text-end {
  text-align: end;
}

.text {
  color: black;
  font-size: 36px;
  font-family: "RoslindaleDisplayCondensed";
  font-style: italic;
  font-weight: 300;
  line-height: 54px;
}

.card-btn {
  padding: 9px 50px 7px;
  background-color: #ab94e0;
  border-radius: 30px;
  width: fit-content;
  color: #1e1d1e;
font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border: 1px solid black;
  width: 308px;
  font-family: NeueTarasque;
  font-weight: 600;
  
  a {
    text-decoration: none;
    color: #000000;
  }
}

.card-btn:hover {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, #ab94e0 0px 3px 6px;
}

.img_ani {
  background-image: url("../assets/headerpage-icon/getmatched.gif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  display: block;
}

.get-txt {
  font-size: 22px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-align: center;
  max-width: 792px;
  color: #000;
}

.header-txt {
  font-family: RoslindaleDisplayCondensed;
  font-size: 36px !important;
  font-weight: 500;
  line-height: 43.56px;
  text-align: center;
}

.coming-soon {
  background: url("../assets/headerpage-icon/Group\ 3705.png") no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1010px;

  .iphone {
    background: white;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px 0;
    max-width: 960px;
    border-radius: 10px;

    .iphone-header {
      
      @media (max-width:1024.5px) {
        font-size: 46px !important;
      }
    }

    .iphone-ancr {
      @media (max-width:1024.5px) {
        font-size: 26px !important;
      }
    }

    .iphone-bg {
      position: relative;

      img {
        position: relative;
        z-index: 1;
      }

      &::before {
        position: absolute;
        content: '';
        display: block;
       width: 100%;
        height: 210px;
        background: #E2D5FF;
        z-index: 0;
        max-width: 350px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}


// .iphone-bg {
//   background: #c7c0d9;
//   width: 50%;
//   margin: auto;
//   display: flex;
//   justify-content: center;
//   align-items: center; 
// }

.iphone-para {
  font-size: 18px;
}

.iphone-header {
  font-family: RoslindaleDisplayCondensed;
  font-size: 64px !important;
  font-weight: 300;
  line-height: 96px;
  text-align: center;
  color: #1e1d1e;
}
p.iphone-para {
  color: #000;
}

.quotes-section {
  background: url("../assets/headerpage-icon/comments_bg.png") no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.comments-quotes {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 10vh);
}

.comments-txt {
  font-family: RoslindaleDisplayCondensed;
  text-align: center;
  width: 100%;
  padding: 0 1.5rem;

  .comments-header {
    font-size: 64px !important;
    font-weight: 300;
    line-height: 96px;
    text-align: center;
    color: #1e1d1e;

    margin-bottom: 70px;
  }

  p {
    font-size: 36px;
    font-style: italic;
    font-weight: 300;
    line-height: 54px;
    color: #000;
  }
}

.iphone-ancr {
  font-family: "RoslindaleDisplayCondensed";
  font-size: 36px !important;
  font-weight: 300;
  line-height: 54px;
  text-align: center;
  color: #7922d6;
  padding-bottom: 25px;
  text-transform: inherit !important;
}

.milford-market {
  background: #ebe2ff;
  padding: 76px 10px;
  border-bottom: 55px solid #1e1d1e;
}

.market-h2>h2 {
  font-family: RoslindaleDisplayCondensed;
  font-size: 64px !important;
  font-weight: 300;
  line-height: 96px;
  text-align: center;
}

.market-para {
  margin: 0 auto;
  width: 55%;
}

.market-para>p {
  font-family: Neue Tarasque;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  width: 545px;
  margin: 0 auto;
}

.market-h3 {
  margin: 0 auto;
  width: 47%;

  h2 {
    font-family: RoslindaleDisplayCondensed;
    font-size: 36px !important;
    font-weight: 300;
    line-height: 54px;
    text-align: center;
    margin: 30px 0 80px;
  }
}

.contact-form h5.contact-head {
  font-family: RoslindaleDisplayCondensed;
  font-size: 45px !important;
  font-weight: 300;

  text-align: center;
  color: #000000 !important;
}

.contact-form p {
  font-family: Neue Tarasque;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.15px;
  text-align: center;
}

.contact-form {
  width: 100%;
}

.contact-first {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.contact-name1 input,
.contact-phone input {
  width: 91%;
}

.contact-name1 label,
.contact-phone label {
  padding-right: 10px;
}

.contact-name1,
.contact-phone {
  width: 30%;
  border-bottom: 1px solid red;
  margin: 30px 10px;
}

.contact-email {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 59%;
  }
}

.headerFixed {
  position: fixed;
  width: 100%;
  z-index: 99;
  bottom: 0;
}

.milford-mrkt-cardimg {
  height: 192px;
  width: 100%;
  padding: 16px 16px 0px 16px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.contact_us {
  background-color: #f4f1ee;
  padding: 150px 20px 40px;

  .form-heading {
    margin-bottom: 60px;
  }
}

.for {
  border-bottom: 2px solid #ab94e0 !important;
}

.for:hover {
  border-bottom: 2px solid #7922d6 !important;
}

.for {
  border: none;
  background-color: transparent !important;
}

.for:focus {
  outline: none !important;
}

.for:focus-visible {
  outline: none !important;
}

.contact-us-heading {
  font-family: RoslindaleDisplayCondensed;
  font-size: 64px !important;
  font-weight: 300;
  line-height: 96px;
  text-align: center;
  color: #1e1d1e;
}
.contact_us  p.contact-us-text  {
  color: #000;
  font-family: NeueTarasque;
}
.contact_us form input::placeholder,.contact_us form input, .contact_us form textarea  {
  font-family: NeueTarasque;
}
.comment-form {
  height: 288px;
}

.for::placeholder {
  color: #000;
  font-weight: 600;
}

.text-area {
  border: 2px solid #ab94e0;
  border-radius: 10px;
  background-color: transparent;
  padding: 10px 10px 40px 10px;
}

.text-area:hover {
  border: 2px solid #7922d6 !important;
}

.text-area:focus-visible {
  outline: none !important;
}

a.submit-inquiry-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000000;
}

.early .example-left {
  font-family: RoslindaleDisplayCondensed;
  font-size: 128px;
  font-weight: 300;
  line-height: 192px;
  text-align: center;
  color: #1e1d1e;

  @media (max-width:1024.5px) {
    font-size: 88px;
  }
}

.footer-bg {
  background: url("../assets/headerpage-icon/footer-bg.png") no-repeat;
  width: 100%;
  height: 734px;
  background-size: cover;
  background-position: center;

  .footer-cont {
    margin: 0 80px;
    max-width: 1690px;
  }

  .footer-logo img {
    width: 100%;
    max-width: 710px;
  }

  .footer-left {
    color: #1e1d1e;


    h2 {
      font-family: NeueTarasque;
      font-size: 47px !important;
      padding: 40px 0;
      font-weight: 700;
      line-height: 36px;
      text-align: left;

      span {
        font-family: "Secular";
        font-size: 36px;
        font-weight: 400;
        line-height: 54px;
        text-align: left;
      }
    }
  }

  .footer-email {
    width: 100%;
    text-align: left;

    input {
      border: none;
      background-color: #a38dd500;
      border-bottom: 1px solid #000000;
      width: 438px;
      height: 55px;

      &::placeholder {
        color: black;
        opacity: 1;
        font-size: 26px;
      }
    }

    a {
      color: white;
      background-color: black;
      padding: 14px 26px;
      border: none;
      width: 100%;
      max-width: 240px;
      text-decoration: none;
      display: block;
      text-align: center;
      font-family: NeueTarasque;
      font-weight: 600 !important;
    }
  }

}

.social-links {
  justify-content: space-between;
  border-bottom: 2px solid black;
  width: 100%;
  border-top: 2px solid;
  margin: 0px 80px;
  padding: 20px 0px;
  text-align: left;
  font-family: NeueTarasque;
  margin: 0 !important;

  .cal {
    p {
      font-family: NeueTarasque;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 21px;
      text-align: left;
      color: red;

      a {
        text-decoration: none !important;
        color: #000000;
      }
    }
  }
}

.social-links>.col>p>a {
  text-decoration: none !important;
  color: #000000;
}

.footer-h {
  font-family: Secular !important;
  font-size: 36px !important;
  font-weight: 400;
  line-height: 54px;
  color: #000000;
}

.links {
  padding: 70px 0;

  p {
    font-size: 14px !important;
    font-family: NeueTarasque;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    color: #000;
    opacity: 0.7;

    a {
      text-decoration: none;
      color: #000000;
    }
  }

  h2 {
    color: #000;
    font-size: 20px !important;
    font-family: NeueTarasque;
    opacity: 0.7;
    font-weight: 600;
    margin-bottom: 15px;
  }
}
#root .mission-accordian button.btn.btn-docs {
  margin-bottom: 10px;
}
.property-dropdown {
  position: relative;
}
.property-dropdown ul.search-dropdown.name-dropdown {
  bottom: calc(100% + -42px);
}

.milford-magazine {
  display: flex;
  position: relative;
  // background: url("../assets/headerpage-icon/MagazineBackground.png") no-repeat;
  background: url("../assets/headerpage-icon/art-img.png") no-repeat;
  background-size: cover;
  background-position: center top;

  .milford-magazine-section {
    background-color: #f4f1ee;
    padding: 92px 0;
    max-width: 900px;
    width: 100%;
  }

  h2 {
    font-family: RoslindaleDisplayCondensed;
    font-size: 64px !important;
    font-weight: 300;
    line-height: 96px;
    text-align: center;
    color: #1e1d1e;
  }

  p {
    font-family: Neue Tarasque;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #000;
  }

  img {
    margin: 70px 0;
  }

  .subscribe-section {
    background: #ab94e0;
    text-align: center;
    padding: 51px 0;

    h3 {
      max-width: 550px;
      padding: 0 40px;
      font-size: 14px !important;
      font-weight: 700;
      font-family: NeueTarasque;
      text-align: right;
      text-transform: inherit !important;
    }

    button {
      border: none;
      background: #1e1d1e;
      color: #f4f1ee;
      padding: 10px 50px 10px 15px;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      height: 36px;
      max-width: 290px;
      font-family: NeueTarasque;

      a {
        color: white;
        text-decoration: none;
      }
    }

    .triangle-right {
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-left: 15px solid #ab94e0;
      border-bottom: 7px solid transparent;
      position: absolute;
      top: 13px;
      right: 10px;
    }
  }
}

.milford-img-border1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #7922d6;
  height: 179px;
  width: 179px;
  border-radius: 50%;

  .milford-img-border2 {
    border: 1px solid #7922d6;
    height: 157px;
    width: 157px;
    border-radius: 50%;
    background-color: #ebe2ff;
    color: #1e1d1e;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 16px;
      line-height: 24px;
      padding: 0 25px;
    }
  }
}

.milford-community-head {
  position: relative;

  .milford-community {
    position: absolute;
    top: 12%;
    left: 100px;
    width: inherit;
  }
}

.owl-theme.whatourclientsay .owl-dots .owl-dot.active span,
.owl-theme.whatourclientsay .owl-dots .owl-dot:hover span {
  background: #e93525;
  border: 1px solid #e93525;
}

.owl-theme.whatourclientsay .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: transparent;
  border: 1px solid #e93525;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.anc {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    position: relative;
    font-family: RoslindaleDisplayCondensed;
    text-transform: uppercase;
    font-size: 96px !important;
    font-weight: 300;
    line-height: 144px;
    overflow: hidden;
    background: linear-gradient(90deg, #000, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 8s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
  }

  @keyframes animate {
    0% {
      background-position: -500%;
    }

    100% {
      background-position: 500%;
    }
  }
}

button.btn.filters-product.filterdropdown {
  border: 1px solid;
}

// privacy  policy  css
.privacy-policy {
  background-color: #000000;
  color: #fff;

  .privacy-policy-head {
    font-family: Cormorant;
    letter-spacing: 0.1em;
    font-size: 40px;
    padding: 70px 0;
  }
}

.privacy-policy-txt {
  width: 50% !important;
  padding: 70px 0px;
  width: 50%;
  color: #000;

  h6 {
    color: #000000 !important;
    font-size: 21px !important;
    font-style: normal !important;
    margin-top: 25px;
    font-weight: bolder;
    font-size: 18px !important;
  }
}

// .text-container-animation {
//   display: flex;
//   gap: 20px;
// }
.animation-box {
  display: block;
  font-weight: 300;
  height: 144px;
  overflow: hidden;
}

.text-container-animation {
  font-family: RoslindaleDisplayCondensed;
  font-size: 96px !important;
  font-weight: 300;
  position: relative;
  width: 100%;
  animation: slideUpDown 8s infinite; /* Adjust the duration (6s) as needed */

  @media (max-width:1024.5px) {
    animation: slideUpMediumDown 8s infinite;
    
  }
  @media (max-width:576.5px) {
    animation: slideUpSmallDown 8s infinite;
    
  }
  @media (max-width:480.5px) {
    animation: slideUpXSmallDown 8s infinite;
    
  }

  p {
    color: #1e1d1e;
    margin-bottom: 0 !important;
    // animation: fadeAnimation 8s infinite ease-in-out;
    // opacity: 0;

    // &:nth-child(1) {
    //   animation-delay: 0s;
    // }

    &:nth-child(2) {
      margin-top: 144px;
      @media (max-width:1024.5px) {
        margin-top: 114px; 
      }
      @media (max-width:576.5px) {
        margin-top: 140px; 
      }
      @media (max-width:480.5px) {
        margin-top: 95px; 
      }
    }
    
  }
}

@keyframes slideUpDown {
  0% {
    transform: translateY(144px);
  }
  17%{
    transform: translateY(0px);
  }
  34%{
    transform: translateY(0px);
  }
  51%{
    transform: translateY(-144px);
  }
68%{
    transform: translateY(-288px);
  }
  85%{
    transform: translateY(-288px);
  }
  100% {
    transform: translateY(-432px);
  }
}
@keyframes slideUpMediumDown {
  0% {
    transform: translateY(114px);
  }
  17%{
    transform: translateY(0px);
  }
  34%{
    transform: translateY(0px);
  }
  51%{
    transform: translateY(-114px);
  }
  68%{
    transform: translateY(-228px);
  }
  85%{
    transform: translateY(-228px);
  }
  100% {
    transform: translateY(-342px);
  }
}

@keyframes slideUpSmallDown {
  0% {
    transform: translateY(110px);
  }
  17%{
    transform: translateY(0px);
  }
  34%{
    transform: translateY(0px);
  }
  51%{
    transform: translateY(-110px);
  }
  68%{
    transform: translateY(-220px);
  }
  85%{
    transform: translateY(-220px);
  }
  100% {
    transform: translateY(-330px);
  }
}

@keyframes slideUpXSmallDown {
  0% {
    transform: translateY(75px);
  }
  17%{
    transform: translateY(0px);
  }
  34%{
    transform: translateY(0px);
  }
  51%{
    transform: translateY(-75px);
  }
  68%{
    transform: translateY(-150px);
  }
  85%{
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-225px);
  }
}



@keyframes fadeAnimation {

  0%,
  20% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }

  80%,
  100% {
    opacity: 1;
  }
}



// .item-1,
// .item-2 {
//   position: absolute;
//   display: block;

//   animation-duration: 20s;
//   animation-timing-function: ease-in-out;
//   animation-iteration-count: infinite;
// }

// .item-1 {
//   animation-name: anim-1;
//   animation-delay: 0;
// }

// .item-2 {
//   animation-name: anim-2;
//   animation-delay: 4s;
// }



@keyframes anim-1 {

  0%,
  8.3% {
    opacity: 0;
  }

  8.3%,
  25% {
    opacity: 1;
  }

  33.33%,
  100% {
    opacity: 0;
  }
}

@keyframes anim-2 {

  0%,
  33.33% {
    opacity: 0;
  }

  41.63%,
  58.29% {
    opacity: 1;
  }

  66.66%,
  100% {
    opacity: 0;
  }
}




.search-quick-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 13px 15px #eee;

  @media (max-width:1280px) {
    overflow-x: auto;
    justify-content: left;
  }

  @media (max-width:576px) {
    display: none;
  }

  li {
    list-style-type: none;
    padding: 10px 20px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    cursor: pointer;

    @media (max-width:1280px) {
      min-width: 100px;
    }


    &.nevfilteractive {
      background-color: #1E1D1E;

      img {
        filter: brightness(0)invert(1);
      }

      a {

        color: #fff;
      }
    }

    a {
      text-decoration: none;
      color: #1e1d1e;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      white-space: nowrap;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.show {
  display: block !important;
}

.custom-modal-wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1024;
  display: none;

  .custom-modal-box {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40%;
    height: 60vh;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .pop-up-body {
      p {
        font-family: RoslindaleDisplayCondensed;
        font-size: 36px;
        font-style: italic;
        font-weight: 300;
        line-height: 43.56px;
        text-align: center;
      }

      small {
        font-family: NeueTarasque;
        font-size: 12px;
        font-weight: 700;
        line-height: 17.36px;
        text-align: center;
      }
    }
  }
}

.active-modal {
  display: block;
}


.milford-google-form {
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  right: 50%;
  background: #fff;
  transform: translate(50%, 10%);
  padding: 10px;
  width: 100%;
  max-width: 570px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .googleform {
    width: 100%;
    display: flex;
    align-items: end;
  }

  .distance-box {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 1rem;

    &>div {
      display: flex;
      min-width: 188px;
    }
  }
}

.profile-dropdown ul {
  z-index: 99;
}

.estimatedvalue .chartcalc ul li span:first-child {
  min-width: 130px;
  margin-right: 80px;
  display: inline-block;
}





// modal pop up scss

// url("/static/media/Group 3705.053983f2.png") no-repeat
.app-notify-modal {
  .modal-dialog {
    .modal-content {
      background: #F4F1EE;
    }
  }
  &.quiz-modal {
    .modal-dialog {
      width: 100%;
      max-width: 1604px;

      @media (max-width:1440px) {
        max-width: 1240px;
      }

      @media (max-width:1024.5px) {
        max-width: 90%;
      }

      .modal-content {
        .modal-form {


          &.thankyou-section {
            min-height: calc(100vh - 80px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;

            .head-section {
              h2 {
                font-size: 64px !important;
                font-family: RoslindaleDisplayCondensed;
                font-weight: 300;
                color: #000;

              }

              p {
                font-family: NeueTarasque;
                font-size: 16px;
                color: #000;
              }
            }

            .cont-us {
              max-width: 225px;
              margin-top: 5rem;
            }

            .img-fluid {
              position: absolute;
              bottom: 40px;
            }
          }

          &.quiz-inner-body {

            .quiz-left {
              width: 100%;
              max-width: 523px;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              @media (max-width:1440px) {
                max-width: 423px;
              }

              @media (max-width:991.5px) {
                display: none;
              }

              .card-border {
                .card-border {
                  overflow: hidden;
                  max-height: 686px;
                  position: relative;
                  min-height: 686px;

                  @media (max-width:1440px) {
                    max-height: 566px;
                    min-height: 556px;
                  }
                }
              }

              p {
                color: #7922D6;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 600;
                font-family: NeueTarasque;
                margin-bottom: 1rem !important;
              }

              img {
                max-height: calc(100% - 20px);
                position: absolute;
                transition: 0.5s;
                width: calc(100% - 20px) !important;
                opacity: 0;
                top: 600px;

                &.showFields {
                  opacity: 1;
                  top: 10px;
                }
              }
            }

            .quiz-right-section {
              background-color: #EBE2FF;
              border-left: 1px solid #000;
              min-height: calc(100vh - 76px);
              border-top-right-radius: 100px;
              border-bottom-right-radius: 100px;

              @media (max-width:991.5px) {
                border-radius: 100px;
              }

              .quiz-inner-body {
                max-width: 635px;
                width: 100%;
                margin: 0 auto;
                padding: 3rem 1rem;

                @media (max-width:1440px) {
                  max-width: 526px;
                }


                .hideField {
                  display: none;
                }

                .showFields {
                  display: block;
                }

                p {
                  color: #7922D6;
                  font-size: 16px;
                  font-weight: 600;
                  font-family: NeueTarasque;
                }

                .steps {
                  display: flex;
                  gap: 6px;
                  align-items: center;
                  margin-top: 1rem;

                  &>div {
                    height: 14px;
                    width: 100%;
                    max-width: 47px;
                    background-color: #1E1D1E;
                    opacity: 0.5;
                    border-radius: 100px;

                    &.active {
                      opacity: 1;
                      background-color: #7922D6;
                    }
                  }
                }

                .quiz-question {
                  margin: 5rem 0 2rem;

                  @media (max-width:1440px) {
                    margin: 3rem 0 2rem;
                  }

                  h3 {
                    font-size: 28px !important;
                    font-weight: 700;
                    font-family: NeueTarasque;

                    @media (max-width:1440px) {
                      font-size: 24px !important;
                   }

                 }

                  .quiz-answer {
                    margin: 4rem 0;

                    @media (max-width:1440px) {
                      margin: 3rem 0;
                    }

                    .quiz-box {
                      height: 92px;
                      background: #fff;
                      border-radius: 12px;
                      display: flex;
                      align-items: center;
                      padding: 0 2rem;
                      gap: 20px;
                      margin-bottom: 10px;
                      cursor: pointer;
                      border: 1px solid #0000;

                      @media (max-width:1440px) {
                        min-height: 72px;
                       height: inherit;
                        padding: 0 1rem;
                      }


                      &.active {
                        border: 1px solid #000;

                        .inner {
                          border: 1px solid #000;
                        }
                      }

                      .inner {
                        height: 34px;
                        width: 34px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #E2D5FF;
                        color: #000;
                        font-size: 20px;
                        font-weight: 700;
                      }

                      p {
                        color: #000;
                        font-size: 18px;
                        font-family: NeueTarasque;
                        margin: 0 !important;
                        max-width: calc(100% - 54px);


                      }
                    }
                  }

                  .quiz-footer-btn {
                    position: absolute;
                    z-index: 9;
                    bottom: 70px;
                    width: calc(100% - 110px);

                    .btn {
                      color: #000;
                      text-transform: capitalize;
                      font-family: NeueTarasque;
                      font-size: 16px;
                      font-weight: 600;
                      padding: 0;
                      border-bottom: 1px solid #000;
                      border-radius: 0;
                      margin-right: 3rem;
                    }

                    .cont-us {
                      max-width: 220px;
                      font-family: NeueTarasque;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }

          .modal-left-section {
            width: 100%;
            max-width: 635px;

            @media (max-width:1440px) {
              max-width: 475px;
            }

            // @media (max-width:1024.5px) {
            //   max-width: 400px;
            // }
          }

        }

        .modal-right-section {
          min-height: calc(100vh - 80px);

          @media (max-width: 1024.5px) {
            min-height: 560px;
          }
        }
      }
    }
  }

  .modal-dialog {
    width: 100%;
    max-width: 1275px;

    @media (max-width:1024.5px) {
      max-width: 90%;
    }

    .modal-content {
      border-radius: 100px;

      .btn-close {
        right: 60px;
        z-index: 11;
        top: 30px;
        font-size: 31px;
        padding: 0;
      }

      .modal-form {
        .modal-left-section {
          width: 100%;
          max-width: 505px;
          margin: 0 auto;

          &.thankyou-section {
            min-height: calc(100vh - 83px);
            display: flex;
            flex-direction: column;
            justify-content: center;

          }

          .head-section {
            padding-top: 5rem;
            color: #000000;

            @media (max-width:1024.5px) {
              margin-top: 4rem;
            }

            @media (max-width:991px) {
              margin-top: 0;
            }

            h2 {
              font-size: 64px !important;
              font-family: RoslindaleDisplayCondensed;
              font-weight: 300;

              @media (max-width:1440px) {
                font-size: 34px !important;
              }
            }

            p {
              font-family: NeueTarasque;
              font-size: 16px;
            }
          }

          .modal-inner-form {
            margin-top: 6rem;

            @media (max-width:1440px) {
              margin-top: 3rem;

              .cont-us {
                margin-top: 2rem;
              }
            }

            .form-group {
              margin-bottom: 3rem;

              @media (max-width:1024.5px) {
                margin-bottom: 2rem;

              }

              input {
                border: none;
                background-color: transparent;
                border-bottom: 2px solid #ab94e0;
                border-radius: 0;
                padding-left: 0;

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .cont-us {
              width: 100%;
              margin-top: 3rem;

              @media (max-width:1024.5px) {
                margin-top: 1rem;
              }
            }
          }
        }

        .modal-right-section {
          background-color: #E2D5FF;
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;

          img.iphoneImg {
            max-height: 800px;

            @media (max-width:1440px) {
              max-height: 670px;

            }
          }

          @media (max-width:991px) {
            display: none;
          }
        }

        .row {
          .col-md-6 {

            @media (max-width:991.2px) {
              flex: inherit;
              max-width: inherit;

              .modal-left-section {
                min-height: calc(100vh - 80px);
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}



.singleproduct {
  .sale {
    .action {
      .spancircle {
        height: 50px;
        width: 50px;
        border: 1px solid #000000;
        filter: inherit;
        cursor: pointer;

        img {
          width: 20px;
        }

        &.disliked {
          background-color: #000;

          img {
            filter: brightness(0)invert(1);
          }
        }
      }
    }
  }

  .productdetail {
    .property-fecility {
      max-width: 1450px;
      margin: 0 auto;
      div {
        align-items: flex-start;
        span {
          &:nth-child(2) {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
          }
          img {
            max-width: 34px;
          }
        }
      }
    }
  }
}

.HomeNamv {
  z-index: 1000;

  .mini-nav {
    margin: 0;
    li {
      opacity: 1;
      font-family: "Poppins", sans-serif;
      a {
        color: #000;
      }
   }
  }

  .nav-heart-user {
    .nav-heart {
      .link-dark {
        color: #000 !important;
      }
    }

    color: #000;

    .profile-dropdown {
      margin: 0;
    }
  }
}

.parent-search.form-control {
  .searchBtn {
    top: 5px;

    .btn {
      border: none;
    }
  }
}

.nav-wrapper {
  .nav-container {
    @media (max-width:769.5px) {
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 10px;

      .parent-filter-flex {
        order: 2;
        margin-top: 8px;
      }
    }

    @media (max-width:991.5px) {
      button.btn.btn-login.signupbtn.loginbtn-top {
        display: block !important;
      }

      // justify-content: space-between;
      .parent-filter-flex {
        display: flex;

        .filter-flex {
          display: block;
          margin: 0 0 0 10px;

          .filterdropdown {
            max-width: 60px;

            @media (max-width:576.9px) {
              padding: 10px 15px;

              img {
                margin: 0 !important;
              }
            }

            span:last-child {

              display: none;
            }
          }
        }

      }

    }

    .navbarLogo {
      min-width: 300px;

      @media (max-width:1024.5px) {
        min-width: inherit;
        margin-right: 1rem;
      }

      img {

        @media (max-width:991.5px) {
          max-width: 170px;
        }
      }
    }


    .nav-heart-user {
      @media (max-width:576.5px) {
        padding: 0;
        width: inherit;
      }

      .nav-heart {
        .link-dark {
          color: #000 !important;
        }
      }

      color: #000;

      .profile-dropdown {
        margin: 0;
      }
    }

    .parent-filter-flex {
      @media (max-width:769.5px) {
        width: calc(100% - 110px);
      }

      @media (max-width:576.5px) {
        width: calc(100% - 50px);
      }

      .searchform {
        min-width: 700px;

        @media (max-width:769.5px) {
          width: 100%;
        }

        @media (max-width:1440px) {
          min-width: 630;
        }
        @media (max-width:1280px) {
          min-width: 450px;
        }

        @media (max-width:576.5px) {
          min-width: inherit;

          .searchfilter,
          .iconsearch {
            display: none;
         }

          .parent-search.form-control {
            // height: 40px;
            padding-left: 10px;
          }
        }

        .close-icon>i {
          top: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

// .gallery-modal  {
//   .modal {
//     .modal-dialog {
//       max-width: 1700px;
//     }
//   }
// }

.sale .address .sub-address-inner {
  font-weight: 400;
}
.property-overview-updated  .siderow .btnSchedule, .property-overview-updated  .siderow .btnSchedule:hover {
  color: #000 !important;

}
.property-overview-updated  .siderow .btnSchedule:hover  {
  background-color: #fff;
}
.property-overview-updated  .paymentgraph span.pricehead  {
  margin-left: 10px;
}
.property-overview-updated  .siderow .table-responsive table tr td, .property-overview-updated  .siderow .table-responsive table tr th {
  color: #000;
}
.property-overview-updated  .siderow .attractiondiv:hover{
  background-color: #000;
}
.property-overview-updated  .siderow .attractiondiv:hover span, .property-overview-updated  .siderow .attractiondiv:hover span a {
  color: #fff;
}
.property-overview-updated  .siderow .attractiondiv:hover span img {
  filter: brightness(0)invert(1);
}
.property-overview-updated {
  .siderow {
    .square {
      span {
        span.d-flex {
          color: #000;
        }
      }
    }
  }
  .accordion-main {
    .accordion {
      .accordion-item {
        border: 0;
        padding: 3rem 3rem 2rem !important;

        .property-overview-list {
          font-size: 18px;
          color: #000; 
          line-height: 28px;
          margin-left: 26px;
        }

        &.key-info {
          background-color: #F8F8F8 !important;

          border-bottom: 0 !important;

          .accordion-body {
            background: transparent !important;

            .table-responsive {
              max-width: 556px;
              overflow: visible;
              tr {
                td {
                  border-color: #000;
                  a.text-heading {
                    color: #000;
                  }
                }
              }
            } 
          }
        }
        .key-information-table {
            table {
              tr {
                td, th {
                  color: #000;
                }
              }
            }
          
        }
        .accordion-heading {
          color: #000;
          font-size: 26px !important;
          font-style: normal;
          font-weight: 500;
        }

        .accordion-header {
          button.accordion-button {
            font-size: 16px !important;
            text-decoration: underline;
            padding-left: 0 !important;

            &::after {
              display: none;
            }

            img {
              max-width: 25px;
            }
          }

        }
      }
    }
  }

  .upcomings {
    .checkbox-alias {
      background: #fff;
      border-color: #000;
    }
  }

  .paymentgraph {
    svg {

      //   .apexcharts-series {
      //     &:hover {
      //       path {  
      //         stroke: #fff;
      // filter: inherit;
      // stroke-width: 4px;
      //       }
      //     }
      //   }
      path {
        stroke: none;
     }

      [selected="true"] {
        stroke: #fff;
        filter: inherit;
        stroke-width: 4px;
      }
    }

    .pricesection {
      .pb-5>a:hover {
        text-decoration: none !important;
        color: inherit !important;
      }
    }

    .chartcalc {
      ul {
        li {
          display: flex;
          align-items: center;

          span {
            &:first-child {
              min-width: 130px;
              margin-right: 80px;
            }

            &.payment-price {
             margin-left: inherit;
            }
          }

          a {
            .fa-save {
              font-size: 20px;
            }

            margin: 0 1rem;
            cursor: pointer;
          }
        }
      }
    }

    .mortEdit {
      cursor: pointer;
    }
  }

  .similarproduct {
    .wishlist {
      z-index: 9;

      .wishlist-regular-icon {
        font-size: 17px;
     }
    }
  }
  
  .scheduleTour {
    img.karie {
      border-radius: 100%;
    }

    .agent-btn-section {
      display: flex;
      align-items: center;

      button.btnSchedule {
        flex-shrink: inherit !important;
        border-radius: 8px;
        padding: 15px;
        font-size: 16px;
        max-width: 240px;
        margin: inherit; 
      }
    }
  }
}

.dougnutChart {
  width: 500px;
}

button.signaturebtn {
  border: 1px solid #000;
  color: #000;
  font-weight: 500;
  padding: 7px 14px;
  margin-bottom: 10px;
}

.signature-footer {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .signsave-btn {
    background-color: #000;
    border-color: #000;
    font-weight: 500;
    padding: 7px 20px;
    color: #fff;
  }

  .clear-btn {
    border: 1px solid #000;
    color: #000;
    font-weight: 500;
    padding: 7px 14px;
    margin-right: 10px;
  }
}

#root {
  .schedule-tour-modal {
    .modal-dialog {
      max-width: 577px;

      .modal-content {
        .tour-form {
          h3 {
            margin-bottom: 1.5rem;
            font-size: 22px !important;
            font-weight: 600;
            max-width: 330px;
            line-height: 34px;
          }

          .form-group {
            label {
              color: #000;
              font-size: 16px;
            }

            input {
              border-color: #1E1D1E;
              height: 52px;
              border-radius: 12px
            }
          }

          .tour-small {
            font-size: 12px;
          }
        }

        .modal-header {
          h5 {
            font-style: normal;
            color: #000 !important;
            font-weight: 600;
          }

          .btn-close {
            right: 20px;
            font-size: 20px;
          }
        }

        .modal-body {
          margin-bottom: 1rem;

          .schedule-details {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

           img {
              max-width: 220px;
              margin-right: 10px;
            }

            .address-fields {
              width: calc(100% - 210px);
              color: #000;
              font-size: 18px;

              p {
                span:first-child {
                  display: block;
                }

                font-weight: 500;
              }

              .room-details {
                display: flex;
                font-size: 16px;

                div {
                  margin-right: 10px;

                  &:nth-child(2) {
                    border: 1px solid #000;
                    position: relative;
                    border-width: 0 1.6px;
                    padding: 0px 8px;
                  }

                  span {
                    margin-left: 4px;
                  }
               }
              }
            }
          }

          .schedule-time {
            .added-time {
              span {
                margin-right: 10px;
                background: #e1d5fb;
                padding: 8px 12px;
                border-radius: 30px;
                margin-top: 1rem;
                display: inline-block;
              }
            }

            h4 {
              font-size: 20px;
              margin-bottom: 10px;
            }

            h4,
           .form-select,
            p {
              color: #000;
            }

            .add-time {
              display: inline-flex;
              align-items: center;
              margin-top: 1.4rem;
              cursor: pointer;

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #7922D6;
                height: 30px;
                width: 30px;
                border-radius: 100%;
                margin-right: 8px;
              }

              p {
                margin: 0;
                font-weight: 500;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  .react-multiple-carousel__arrow {
    z-index: 99;
  }

}

.home-page-banner {
  .nav-wrapper {
    background-color: #fff;

  }
}

.filterdropdown.purchase-page {
  // opacity: 1;
  // visibility: visible; 
  display: block !important;
  @media (max-width:1280px) {
    display: block !important;
  }
}

.filterdropdown {
  // opacity: 0;
  // visibility: hidden;
  display: none !important;
  @media (max-width:1280px) {
    display: none !important;
  }
}

.favourite,
.account,
.profile.message {
  .back {
    top: inherit !important;
    transform: translateY(7px);
  }
}

.top-right-menu.c-header-nav .nav-heart-user .profile-dropdown {
  margin: 0;
}

.top-right-menu.c-header-nav .nav-heart-user .nav-heart .link-dark {
  color: #000 !important;
}

.top-right-menu.c-header-nav .nav-heart-user {
  color: #000;
  margin: 8px 10px 10px 10px;
}

.productlist {
  .productcard {
    height: calc(100% - 30px);
    margin-bottom: 2.2rem !important;
  }
}

.input-validation-wrapper select.form-control option {
  text-transform: capitalize;
}
.quiz-parent {
  background: #F4F1EE;
}
.filter-bar {
  @media (min-width: 1340.5px) {
    .add-new-email-button-container {
      margin-bottom: 10px
    }
  }
}
.animate-banner {

  .decoration-img {
    @media (max-width: 1024.5px) {
      .animate_banner1-img {
        padding: 0 !important;
      }
    }

    @media (max-width: 769.5px) {
      .animate-img-parent {
        display: none;
      }

      .row {
        .col-6 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }


    .card-width {
      max-width: 750px;

      a {
        text-decoration: none !important;
        color: inherit !important;
      }

      @media (max-width: 1440px) {
        .decoration-card {
          height: inherit;

          .text-bg {
            font-size: 36px;
          }

          .text {
            font-size: 26px;
            line-height: 44px;
            margin-bottom: 1rem;
          }
        }
      }

      @media (max-width: 1024.5px) {
        .decoration-card {
          padding: 20px 10px !important;

          img {
            width: 100%;
            max-width: 50px;
          }

          .text-bg {
            font-size: 30px;
          }

          .text {
            font-size: 22px;
            line-height: 33px;
            margin-bottom: 1rem;
          }
        }
      }
    }

    .animate-img {
      max-width: 740px;
      width: 100%;

      @media (max-width: 1440px) {
        max-height: 720px;

      }

      @media (max-width: 1024.5px) {

        max-height: 610px;

      }
    }

    .card-spacing {
      overflow-x: hidden;
      margin: 8rem 0 !important;

      @media (max-width: 1440px) {
        margin: 4rem 0 !important;

      }
    }
  }
}

.singleproduct > * {
  font-family: "Poppins", sans-serif;
}
section.account > * {
 font-family: "Poppins", sans-serif;
}
.productlist  > * {
  font-family: "Poppins", sans-serif;
}
.productcard  > * {
  font-family: "Poppins", sans-serif;
}
.filter-modal-form {
  .grid-cusom {
    gap: 10px;
    justify-content: flex-start;
  }
}
.HomeNamv.sticky-nav {
  position: inherit;
}
.singleproduct section.sale {
  margin-top: 10px;
  padding: 15px 60px 0;
  background-color: #fff;
  transition: .5s;
  position: sticky;
  top: 0;
  z-index: 999;
  // @media (min-width: 991.5px) {
  //   position: sticky;
  //   top: 0;
  //   z-index: 999;
  //   background-color: #fff;
  // }
}
.productcard  .truncate-line .truncate-line {
  color: #000;
  opacity: 0.7;
}
.productcard  .cHVLag ul li{
  color: #000; 
}
// .singleproduct .sale.is-sticky .price-details {
//   position: relative;
// }
// .singleproduct .sale.is-sticky .address {
//   text-align: left;
// }
// .singleproduct .sale.is-sticky .price-details::after {
//   content: '';
//   display: block;
//   height: 60px;
//   width: 1px;
//   background: black;
//   position: absolute;
//   top: 0;
//   right: 0;

// }
// .singleproduct .sale.is-sticky .propBackbtn {
//   display: flex;
// }
// .singleproduct .sale.is-sticky {
//   box-shadow: 0 0 10px #ccc;
//   padding: 15px 60px 0 130px;
// }
.singleproduct .sale .propBackbtn {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  box-shadow: 0 0 5px #ccc;
  display: none;
  justify-content: center;
  align-items: baseline;
  img {
    width: 24px;
  }
}

.singleproduct button.viewallphoto {
  bottom: 30px;
  right: inherit !important;
  left: 30px;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.form-check a.d-flex.justify-content-end.loginSignup {
  color: #e93525;
}
.profileDetails button.btn.paired-btn:hover {
  background: #000;
  color: #fff;
}
.icon-social .check-icon i.fa-solid.fa-xmark {
  border: 1px solid #e93525;
  background: #e93525;
}
#root .signup-bg .signup-heading, #root .signup-bg .card-left .left-card-title {
  font-family: NeueTarasque;
}

.home-page-banner a:hover {
  text-decoration: none !important;
  color: inherit !important;
}

.serving-main-section .slick-slider {
  overflow-x: hidden;
}

.quiz-parent {
  .get-txt {
    @media (max-width:1024.5px) {
      font-size: 16px !important;
    }
  }
}

.home-page-banner-section {
  .animation-box {
    .text-container-animation {
      @media (max-width:1024.5px) {
        font-size: 76px !important;

        p {
          width: 100%;
        }
      }
    }
  }
}

.header-bar {
  .top-header {
    .req-btn {
      padding: 8px 20px 5px;
    }
    .head-btn {
      padding: 8px 20px 5px;
      button {
        font-family: NeueTarasque;
        font-weight: 600 !important;
        color: #000 !important;
      }
      a {
        font-family: NeueTarasque;
        font-weight: 600 !important;
        text-decoration: none !important;
        color: #000 !important;
        span {
          color: #000 !important;
        }
      }
    }
  }
}

.desktopHidden {
  display: none !important;
}

ul.contact-dropdown {
  width: 100%;
}

.contact-dropdown .contact-details-card .btn.callbtn {
  width: 50%;
}

.mission-accordian .accordion .accordion-item .accordion-header button h6 svg {
  display: none;
}
.productcard span.wishlist, .productcard span.dislike {
  height: 56px;
  width: 56px;
  color: #000;
}
.mission-card-header .parent-search.form-control .searchBtn {
  width: 35px;
  height: 35px;
  right: 18px;
  top: 3px;
}
button.btn.signupbtn a {
  color: #000 !important;
  font-family: NeueTarasque;
  font-weight: 600 !important;
}
button.btn.signupbtn:hover{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, #ab94e0 0px 3px 6px;
  color: #000;
}
.checklistMain {
  .checklistitem.dashboardColumn .icon {
    height: inherit;
    width: inherit;
  }

  .checklistitem.dashboardColumn .icon .custom_icon_style .d-flex {
    align-items: center;
    font-size: 14px;

    span {
      position: relative;
      top: 1px;
    }
  }

  .checklistitem {
    grid-template-columns: inherit;
    column-count: 4;
    display: block;

    .permission-check>label {
      position: relative;
      top: -2px;
      min-width: 170px;
    }
  }

  .checklistitem.dashboardColumn {
    grid-template-columns: inherit;
    column-count: 3;
    display: block;
  }
}
.profile .back.profile-arrow-back {
  top: inherit ;
}
.similarproduct .content-row {
  flex-wrap: nowrap;
}
.similar-viewALL a {
  font-size: 20px ;
  color: #000 !important;
  text-decoration: underline !important;
  position: relative;
  top: -18px;
  cursor: pointer;
}
.footer-bg .footer-email a:hover {
  background: #fff;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, #ab94e0 0px 3px 6px;
}
.filter-flex button.btn.filters-product.filterdropdown:hover {
  background: #000;  
}
.filter-flex button.btn.filters-product.filterdropdown:hover span img {
  filter: brightness(0)invert(1);
}
.filter-flex button.btn.filters-product.filterdropdown:hover span {
  color: #fff;
}
.profile button.btn.edit-btn:hover {
  background: #000;
  color: #fff; 
}
.profile .save-btn:hover {
  background: #fff;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, #ab94e0 0px 3px 6px;
}
.header-bar .top-header .head-btn:hover, .header-bar .top-header .req-btn:hover {
  background: #000; 
  color: #fff; 
}
.header-bar .top-header .head-btn:hover a, .header-bar .top-header .head-btn:hover a span{ 
  color: #fff !important;
}
.searchform .iconsearch {
  color: #1e1d1e;
}
.singleproduct button.viewallphoto:hover {
  background: #000; 
  color: #fff; 
}
.singleproduct button.viewallphoto:hover img {
  filter: brightness(0)invert(1);
}
// ====================================== lead generation scss // ====================================== //
#root {
  .leadGeneration {

    .lead-subheading {
      color: #000;

     h3 {
        font-family: RoslindaleDisplayCondensed;
        font-size: 46px;
        font-weight: 300;
      }

      p {
        font-size: 18px;
        font-family: NeueTarasque;
        line-height: 40px;
        max-width: 600px;
      }
    }

    .lead-bg,
    .homepage-nav {
      background-color: #F4F1EE;
      margin: 0;
    }

    .main-btn {
      height: 50px;
     border-radius: 34px;
      border: 1px solid #7922D6;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      padding: 8px 35px;
      background: #AB94E0;
      color: #000;
      font-family: NeueTarasque;
      &:hover {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, #ab94e0 0px 3px 6px;
   
      }
    }

    .banner-heading {
      color: #282829;
      margin-top: 2rem;

      h1 {
        font-size: 72px;
        font-family: RoslindaleDisplayCondensed;

        font-weight: 300;
        margin: 0;
      }

      p {
        font-size: 18px;
        margin: 30px 0;
        font-family: NeueTarasque;
      }

      .btn {
        width: 100%;
        max-width: 310px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .lead-container {
      max-width: 1562px;
      margin: 0 auto;
      padding: 0 2rem;
    }

    .lead-state {
      position: relative;

      &::before {
        content: '';
        background: url(../assets/headerpage-icon/lead-bg.jpg);
        height: 100%;
        width: 40%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
      }

      .lead-bg-img {

        padding: 4rem 0;

        img {
          position: relative;
          left: -110px;
        }
      }
    }

    .lead-texture {
      padding: 4rem 0;
      background: url(../assets/headerpage-icon/Paper_Texture.png);
      background-size: cover;

      .texture-end {
        img {
          width: 100%;
          max-width: 520px
        }
      }
    }

    .lead-contactus {
      padding: 6rem 0;
      background-color: #F4F1EE;

      .lead-subheading p {
        max-width: 480px;
      }

      .lead-contact-form {
        .form-group {
          margin-bottom: 3rem;

          label {
            font-family: NeueTarasque;
            color: #000;
            font-weight: 600;
          }

          input.form-control {
            background-color: #0000;
            border: 0;
            border-bottom: 1px solid #AB94E0;
            border-radius: 0;
            padding-left: 0;
          }

          .form-control {
            background-color: #0000;
            border: 1px solid #AB94E0;

            &:focus {
              outline: 0;
              box-shadow: none;
            }
          }
        }

        .btn {
          width: 100%;
          max-width: 310px;
        }
      }
    }

    .lead-banner-img {
      padding-bottom: 70px;

      img {
        width: 100%;
      }
    }
  }
}
.singleproduct .scheduled-square .customCheckbox img {
  display: none;
}
.singleproduct .scheduled-square  input[type="radio"] {
  opacity: 0;
}
.singleproduct .scheduled-square.active .customCheckbox img {
  display: block;
}
.singleproduct .scheduled-square.active .customCheckbox {
  background-color: #000000;
}
.singleproduct .scheduled-square .customCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
  top: -9px;
}
.singleproduct .scheduled-square.active .tile {
  background-color: #ebe2ff;
    border: 1.5px solid #000;
}
.testimonial-carousel .comments-header {
  font-size: 64px !important;
  font-weight: 300;
  line-height: 96px;
  text-align: center;
  color: #1e1d1e;
  margin-bottom: 70px;
  font-family: RoslindaleDisplayCondensed;
  text-align: center;
}
.testimonial-carousel .inner-quotes {
  max-width: 1260px;
  margin: 0 auto;
}
.testimonial-carousel {
  padding: 7rem 0;
  background: url(/static/media/comments_bg.920bd33f.png) no-repeat; 
  background-size: cover;
  background-position: center;
}
.testimonial-carousel .quotes {
  text-align: center;
  margin-bottom: 20px;

}
.message-bubble {
  position: fixed;
  bottom: 72px;
  right: 18px;
  z-index: 9999;
  .btn {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AB94E0;
    &:hover {
      background-color: #000;
      img {
        filter: brightness(0)invert(1);
      }
    }
  }
}
.updated-serving {
  max-width: 1590px;
  margin: 0 auto 7rem;
  padding: 0 2rem;
  h3 {
    font-family: NeueTarasque;
    font-size: 24px !important;
    margin-bottom: 30px;
    font-weight: 600;
    color: #000;
  }
  .updated-serving-card {
    background-color: #fff; 
    box-shadow: 0 4px 10px #00000021;
    border-radius: 10px; 
    cursor: pointer;
      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
      }
    .serving-bottom {
      padding: 30px 10px;
      text-transform: uppercase;
       
      h3 { 
        font-size: 32px !important;
        margin-bottom: 10px; 
        text-transform: uppercase !important;
        color: #000;
      }
      p {
        color: #000;
        font-family: NeueTarasque; 
        margin-bottom: 0
      }
    }
    &:hover {
      box-shadow:rgba(0, 0, 0, 0.16) 0px 3px 6px, #ab94e0 0px 6px 8px;
    }
    &:active {
      background-color: #EBE2FF;
    }
  }
}
.bottom-footers {
  background-color: #F4F1EE;
  border-top: 1px solid #000;
  .bottom-filter {
    max-width: 1590px;
    margin: 0 auto;
    padding: 4rem 2rem;

    .bottom-accordion {
      &.statAc {
        .ac-body ul li {
          text-decoration: none;
        }
      }
     
      text-align: center;
      .ac-header {
        margin-bottom: 18px;
        cursor: pointer;
        span {
          font-family: NeueTarasque;
          font-size: 16px ;
          display: inline-block;
          font-weight: 600;
          color: #7922D6 ;  
        }
        img {
          position: relative;
          left: 13px;
          width: 22px;
          top: -2px;
          transition: 0.5s;
          rotate: 180deg;
      }
      }
      .ac-body {
        transition: .5s;
        overflow-y: hidden;
        max-height: 0;
       
        p {
          font-weight: 600;
          color: #7922D6 ;
          font-family: NeueTarasque;
          font-size: 16px ;
          i {
            font-size: 14px ;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          text-align: center;
          li {
            color: #000;
            font-family: NeueTarasque; 
            margin-bottom: 10px;
            text-decoration: underline;
          }
        }
      }
      &.showAc {
        .ac-header {
          img {
            rotate: 0deg;
          }
        }
        .ac-body { 
          transition: .5s;
          max-height: 400px;
          overflow-y: hidden;
          
        }
      }
    }
  }
}

/// ====================================== admin update scss // ====================================== //
// for lines 
.main-content-wrapper table.stageinput tr td.reltionTop::before {
  content: '';
  height: 100%;
  width: 2px;
  background: #000;
  position: absolute;
  left: -12px;
  top: 21px;
}
.main-content-wrapper table.stageinput tr td.reltionTop::after {
  content: '';
  height: 2px;
  position: absolute;
  width: 20px;
  background: #000;
  left: -12px;
  top: 21px;
}
.main-content-wrapper table.stageinput tr td.relationBottom::before {
  content: '';
  position: absolute;
  height: 24px;
  width: 2px;
  background: #000;
  left: -12px;
  top: 0;
}
.main-content-wrapper table.stageinput tr td.relationMiddle::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  background: #000;
  left: -12px;
  top: 0;
  z-index: 99;
}
.main-content-wrapper table.stageinput tr td.relationMiddle .forDot::after {
  content: '';
  display: block;
  position: absolute;
  height: 8px;
  width: 8px;
  background: #000;
  border-radius: 100%;
  top: 19px;
  left: 1px;
}
.main-content-wrapper table.stageinput tr td.relationMiddle::after {
  content: '';
  height: 2px;
  position: absolute;
  width: 20px;
  background: #000;
  left: -12px;
  top: 22px;
}
.main-content-wrapper table.stageinput tr td.relationBottom::after {
  content: '';
  height: 2px;
  position: absolute;
  width: 20px;
  background: #000;
  left: -12px;
  top: 22px;
}
.main-content-wrapper table.stageinput tr td.relationBottom .forDot::after {
  content: '';
  display: block;
  position: absolute;
  height: 8px;
  width: 8px;
  background: #000;
  border-radius: 100%;
  top: 19px;
  left: 1px;
}
.main-content-wrapper table.stageinput tr td.reltionTop .forDot::before {
  content: '';
  display: block;
  position: absolute;
  height: 8px;
  width: 8px;
  background: #000;
  border-radius: 100%;
  top: 18px;
  left: 1px;
}
img.relation-img {
  display: none;
}
.main-content-wrapper table.stageinput tr td.reltionTop img.relation-img { 
  display: block;
    position: absolute;
    left: -23px;
    top: 50%;
    z-index: 999;
}
.main-content-wrapper table.stageinput tr th,  .main-content-wrapper table.updated-table tr th {
  background-color: #1E1D1E;
}
.main-content-wrapper table.stageinput tr th, .main-content-wrapper table.stageinput tr td, .main-content-wrapper table.updated-table tr th, .main-content-wrapper table.updated-table tr td {
  font-family: "Poppins", sans-serif;

}
.main-content-wrapper table.stageinput tr td, .main-content-wrapper table.updated-table tr td {
  color: #000;
}
#root .main-content-wrapper table.updated-table tr td button.btn {
  background-color: #0000;
  color: #000 ;
  border: 1px solid #000 ;
  border-radius: 60px;
  box-shadow: none;
  padding: 7px 20px;
}
.main-content-wrapper table.stageinput tr td select.form-control {
  color: #000;
  border-color: #000;
  min-width: 182px;
  background-color: var(--cui-form-select-bg, #fff);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 9px center;
  background-size: 13px 12px;
  padding: 7px 26px 7px 10px;
  border-radius: 8px;
}
.main-content-wrapper table.stageinput tr td p, .main-content-wrapper table.stageinput tr td .text-muted, .main-content-wrapper table.stageinput tr td .contact-name{
  color: #000 !important;
}
.main-content-wrapper table.stageinput tr td .CustomDatepicker {
  color: #000;
  border-color: #000;
  border-radius: 8px;
}
.main-content-wrapper table.stageinput tr td .form-multi-select-selection-tags {
  color: #000;
  border-color: #000;
  border-radius: 8px;
}

.main-content-wrapper table.stageinput tr td .form-multi-select-selection-tags .form-multi-select-search {
  color: #000;
}
.main-content-wrapper table.stageinput tr td .customTxtArea textarea.form-control {
  border: 0;
}
.main-content-wrapper table.stageinput th:nth-child(2), .main-content-wrapper table.stageinput td:nth-child(2) {
  left: 24px;
    min-width: 250px;
    padding-left: 26px;
}

.updated-filterBar .form-control, .user-manage-head .form-control{
  color: #000;
  border-color: #000;   
  padding: 7px 26px 7px 10px;
  border-radius: 8px;
}
.updated-filterBar .form-multi-select-selection-tags {
  color: #000;
  border-color: #000;   
  border-radius: 8px;
  background-color: var(--cui-form-select-bg, #fff);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 9px center;
  background-size: 13px 12px;
  min-width: 150px;
  max-width: 150px; 
}
.updated-filterBar .form-multi-select-selection-tags > div[aria-expanded="true"], .updated-filterBar .form-multi-select-selection-tags > div[aria-expanded="false"] {
  max-height: 37px !important;
}
.updated-filterBar select.form-control, .user-manage-head select.form-control {
  background-color: var(--cui-form-select-bg, #fff);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 9px center;
  background-size: 13px 12px;
}
.updated-search {
  position: relative;
}
.updated-search .magniBtn{
  background: #ab94e0;
    color: #000;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    right: 1px;
}
.updated-search .closeBtn {
  background-color: #0000;
  padding: 0;
  box-shadow: none !important;
  position: absolute;
  top: 8px;
  right: 41px;
}
#root button.btn.clear-primary {
  border: 1px solid #000;
  color: #000;
  background: #AB94E0;
  padding: 7px 21px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: none;
}
#root button.btn.clear-primary:hover {
  background-color: #000;
  color: #fff;
}
#root .updated-search input.form-control{
  border-radius: 80px;
  min-width: 240px;
}

.updated-primary-btn .btn{
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  box-shadow: none;
}
.updated-primary-btn .btn:hover {
  background-color: #fff;
  color: #000 !important;
}
.dropdownBtn-panel > ul {
  display: flex;
  align-items: center;
  list-style: none;
  border: 1px solid #000;
  border-radius: 80px;
  padding: 13px 21px;
  margin-right: 27px;
}
.dropdownBtn-panel > ul > li {
  cursor: pointer;
}
.dropdownBtn-panel > ul > li:nth-child(2) {
  margin: 0 12px;
  padding: 0 15px;
  border: 1px solid #000;
  border-width: 0 1px 0 1px;
}
.dropdownBtn-panel .profile-dropdown ul {
  padding: 8px 0;
  border-radius: 9px;
  min-width: 286px;
  top: 20px;
  right: 0;
}
.dropdownBtn-panel .profile-dropdown ul li {
  color: #000;
  font-size: 16px;
  padding: 8px 20px;
}
.dropdownBtn-panel .profile-dropdown ul li:hover {
  background-color: #ebe2ff;
}


// .search-dropdown.state-dropdown {
//   position: absolute;
// }
// .bottom-custom-dropdown {
//   position: relative;
// }
// .bottom-custom-dropdown .search-dropdown.state-dropdown {
//   bottom: 53px;
// }
// .c-wrapper .c-header{
//   min-height: 66px;
// }
// body #root .c-header {
//   background: #EBE2FF;
// }
// body #root .c-header .page-header-title {
//   color: #000;
// }


// ====================================== resposive scss // ====================================== //


@media (min-width: 1347.5px) {
  #root .content-wrapper .filter-bar .filters {
    margin-bottom: 0 !important;
  }
  
}


@media (min-width: 1024.5px) and (max-width:1680px) {
  .offer-form.right-section .offer-card .row .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .offer-form.left-section .offer-card .row .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 16px;
  }

}
@media (min-width: 1024.5px) {
  .nav-wrapper-sticky.nav-wrapper {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
  }
  .search-quick-filter.search-quick-filter-sticky {
    position: sticky;
    top: 121px;
    z-index: 99;
    background: #fff;
    box-shadow: 0 0 10px #ccc;

  }
}
@media (min-width: 768.9px) and (max-width: 1740px) {
  .sale.is-sticky .row .col-md-4:first-child {
    flex: 0 0 43.33333%;
    max-width: 43.33333%;
  }
  .sale.is-sticky .row .col-md-4:last-child {
    flex: 0 0 23.33333%;
    max-width: 23.33333%;
  }
}
@media (max-width:1440px) { 

  .similarproduct .content-row {
    flex-wrap: wrap-reverse;
}
    .similarproduct .content-row span.street-name {
    width: 100%;
    }
    .similar-viewALL a {
      top: inherit;
    }
  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .head-section p {
    font-size: 14px;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .head-section p strong {
    display: inline !important;
    margin-left: 5px;
  }

  .teamcard-parent .team-card .card .team-right span a {
    font-size: 16px !important;
  }

  .teamcard-parent .team-card .card .card-transaction-btn button.btn.team-btn {
    width: 100%;
    height: 50px;
  }

  .home-page-banner-section .animation-box .text-container-animation p {
    width: 100%;
  }

  .checklistMain {


    .checklistitem {
      grid-template-columns: inherit;
      column-count: 3;
      display: block;
    }

    .checklistitem.dashboardColumn {
      grid-template-columns: inherit;
      column-count: 2;
      display: block;
    }
  }

  body #root .login-bg {
    height: inherit;
    min-height: calc(100vh - 10px);
  }

  .login-bg .login {
    padding-bottom: 3rem;
  }

  body #root .btn-client-type {
    min-height: 50px;
    font-size: 14px;
  }

  body #modal-root .transaction-modal .modal-body {
    max-height: calc(100vh - 250px);
  }

  .singleproduct {
    .sale {
      .price-details {
        h1 {
          font-size: 60px !important;
        }
      }

      .address span:first-child {
        font-size: 30px;
      }
    }

    .property-overview-updated {
      .fixedheight {
        .square {
          height: inherit;
          min-height: 229px;
        }
      }
    }
  }
}

@media (min-width:991px) and (max-width:1440px) {
  .productdetails {
    .productlist {
      .row {
        .col-lg-3 {
          flex: 0 0 33.3%;
          max-width: 33.3%;
        }
      }
    }
  }
}

@media (min-width: 991.5px) {
  #idx-filter-modal .modal-body {
    max-height: calc(100vh - 230px) !important;
  }
}
@media (min-width:769.5px) {
  .singleproduct .sale.is-sticky .price-details {
    position: relative;
  }
  .singleproduct .sale.is-sticky .address {
    text-align: left;
  }
  .singleproduct .sale.is-sticky .price-details::after {
    content: '';
    display: block;
    height: 60px;
    width: 1px;
    background: black;
    position: absolute;
    top: 0;
    right: 0;
  
  }
  .singleproduct .sale.is-sticky .propBackbtn {
    display: flex;
  }
  .singleproduct .sale.is-sticky {
    box-shadow: 0 0 10px #ccc;
    padding: 15px 60px 0 130px;
  }
}

@media (min-width:991.5px) and (max-width:1360px) {

  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-right,
  .apexcharts-legend.apx-legend-position-left {
    display: none;
  }
}

@media (max-width:1366px) {

  .updated-serving .updated-serving-card .serving-bottom h3 {
    font-size: 24px !important;
  }
  #root .leadGeneration .banner-heading h1 {
    font-size: 52px;
  }

  #root .leadGeneration .lead-state .lead-bg-img img {
    left: -30px;
  }

  #root .leadGeneration .lead-subheading h3 {
    font-size: 40px;
  }

  #root .leadGeneration .lead-subheading p {
    font-size: 16px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-answer {
    margin: 2rem 0;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-right-section img {
    max-width: 540px;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .head-section {
    padding-top: 2rem;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .modal-inner-form .form-group {
    margin-bottom: 1.5rem;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-left .card-border .card-border {
    max-height: 416px;
    min-height: 416px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question h3 {
    font-size: 22px !important;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-answer .quiz-box {
    min-height: 58px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-answer .quiz-box p {
    font-size: 16px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-footer-btn {
    bottom: 30px;
  }


  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-answer .quiz-box .inner {
    height: 30px;
    width: 30px;
    font-size: 17px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-left {
    max-width: 393px;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-right-section img.iphoneImg {
    max-height: 570px;
  }

  .quotes-section {
    position: relative;
    height: inherit;
    // padding: 3rem 0;
  }

  .comments-quotes {
    height: inherit;
    position: relative;
  }


  // .property-overview .similarproduct .productcard {
  //   margin-right: 0;
  // }

  .checklistMain {


    .checklistitem {
      grid-template-columns: inherit;
      column-count: 2;
      display: block;
    }
  }
}

@media (max-width:1024.5px) {
  .singleproduct section.sale {
    padding: 15px 30px 0;
  } 
.singleproduct .sale.is-sticky { 
    padding: 15px 60px 0 80px;
}
.singleproduct .sale .propBackbtn {
  left: 23px;
}
.singleproduct .sale.is-sticky .price-details::after {
  height: 40px;
}

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question h3 {
    font-size: 18px !important;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-answer .quiz-box p {
    font-size: 14px !important;
  }

  .search-quick-filter li {
    min-width: 88px;
    padding: 8px 10px;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-right-section img.iphoneImg {
    max-height: 600px;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section {
    padding: 0 3rem;
 }

  .property-overview-updated .accordion-main .accordion .accordion-item.key-info .accordion-body .table-responsive {

    overflow: auto;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-left .card-border .card-border {
    max-height: 566px;
    min-height: 450px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-left {
    max-width: 333px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-footer-btn {
    bottom: 50px;
  }

  .HomeNamv {
    .navbar {
      .container-fluid {
        padding: 0px 30px;

        .navbar-collapse {
          .mini-nav {
            padding: 0;

            li {
              padding: 2px 10px;
            }
          }
        }
      }
    }
  }

  .singleproduct .sale .price-details h1 {
    font-size: 44px !important;
  }

  .singleproduct .sale .address span:first-child {
    font-size: 24px;
  }

  .singleproduct .productdetail .property-fecility div span:nth-child(2) {
    font-size: 18px;
  }

  .singleproduct {

    .property-overview-updated {

      .accordion-main .accordion .accordion-item .property-overview-list {
        font-size: 16px;
        line-height: inherit;
      }
    }

    #scheduled-square h6 {
      font-size: 17px;
    }

    .gallar {
      .left-img {
        .big-img {
          height: 100%;
        }

        .left-gallary {
          height: 100%;
        }
      }
    }

    .custom-container {
      padding: 0px 30px;
    }

    .property-overview-updated .accordion-main .accordion .accordion-item {
      padding: 3rem 1rem 1rem !important;
    }

    #property-overview .property-overview-heading {
      font-size: 22px;
    }

    .property-overview-updated .fixedheight .square {
      height: inherit;
      min-height: 189px;
    }

    .attractiondiv {
      height: 180px;
    }
  }

  .productdetails {
    .gallar {
      .big-img {
        height: 22rem;
      }
    }
  }

  .animate-banner .decoration-img .card-width .decoration-card {
    .card-btn {
      width: 100%;
      max-width: 308px;
    }
  }

  .footer-bg {
    height: inherit;

    .footer-cont {

      .row {
        .col {
          flex: inherit;

          &.footer-left {
            order: 1;
            max-width: 70%;

            h2 {
              font-size: 37px !important;
              padding: 10px 0;
              line-height: inherit;
            }
          }

          &.footer-logo {
            order: 0;
            max-width: 30%;
            text-align: left;
          }

          &.social-link-section {
            margin-top: 20px !important;
            order: 2;

            .social-links {
              justify-content: left;

              .col {
                max-width: 33.3%;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:991.5px) {
  #root .leadGeneration .lead-subheading p {
    max-width: inherit;
  }

  #root .leadGeneration .lead-state .lead-bg-img img {
    left: inherit;
  }

  #root .leadGeneration .lead-texture .texture-end {
    text-align: center;
    margin-bottom: 3rem;
  }

  #root .leadGeneration .lead-contactus .lead-subheading p {
    max-width: inherit;
  }

  #root .leadGeneration .lead-state {
    padding: 3rem 0;
  }

  #root .leadGeneration .lead-state .lead-bg-img {
    padding: 2rem 0;
    text-align: center;
  }

  #root .leadGeneration .lead-state::before {
    top: inherit;
    bottom: 0;
    width: 100%;
    height: 260px;
  }

  .HomeNamv .navbar .container-fluid .navbar-collapse .mini-nav {
    display: block;
    text-align: left;
  }

  .HomeNamv .btn.btn-login.loginbtn-top {
    top: 24px;
  }

  .HomeNamv .nav-heart-user.desktopHidden {
    display: flex !important;
    position: absolute;
    right: 110px;
    top: 22px;
    height: 42px;
    width: auto;
    padding: 0;
  }

  .HomeNamv .navbar .container-fluid .navbar-collapse .nav-heart-user {
    display: none;
  }
}

@media (max-width:769.5px) {
  .singleproduct section.sale.is-sticky {
    padding: 15px 30px 0;
}
  .singleproduct .sale .price-details h1 {
    font-size: 38px !important;
  }
  .singleproduct .sale .address {
    text-align: left;
  }
  .singleproduct section.sale {
    position: relative;
  }

  .singleproduct {
    .productdetail {
      height: inherit;
      min-height: 171px;

      .property-fecility {

        flex-wrap: wrap;

        span.facility-similar-style {
          font-size: 18px;
        }
      }
    }

    .sale .row .col-md-4 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .sale .row .col-md-4.text-center {
      order: 2;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .gallar {
      flex-wrap: wrap;

      .left-img {
        .big-img {
          max-height: 390px;
        }
      }

      .right.img {
        display: flex;

        img {
          width: 24%;
        }
      }
    }

    .property-overview-updated {

      .left-property,
      .fixedheight {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 2rem;
      }

      .fixedheight .square {
        height: inherit;
        min-height: 189px;
      }
    }
  }

  .footer-bg {


    .footer-cont {
      margin: 0 auto;
      padding: 0 1rem;
    }
  }

 ul.homepage-nav {
    overflow-x: auto;

    li {
      white-space: nowrap;
    }
  }

  .serving-section {
    img {
      max-width: 170px;
    }
  }

  .serving-txt {
    font-size: 26px;
    line-height: 34px;
  }

  .quiz-parent {
    flex-wrap: wrap;
    flex-direction: column-reverse;

    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;

      .d-flex {
        padding: 3rem 0;
      }

      .img_ani {
        height: 250px;
      }
    }
  }

  .quotes-section {
    height: inherit;
    // padding: 3rem 0;

    .comments-txt {
      .comments-header {
        font-size: 42px !important;
        margin-bottom: 10px;
      }

      p {
        font-size: 26px;
        line-height: 40px;
      }
    }

    .comments-quotes {
      height: inherit;
    }
  }

  .milford-magazine {
    .subscribe-section {
      flex-wrap: wrap;
      padding: 42px 0;

      h3 {
        width: 100%;
        max-width: inherit;
        margin-bottom: 20px;
      }
    }
  }

  .conatct-btn {
    width: 100%;
  }

  .coming-soon .iphone .iphone-header {
    font-size: 36px !important;

  }

  .coming-soon .iphone .iphone-bg img {
    width: 100%;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section {
    border-radius: 10px;
  }

  .favourite .back,
  .account .back,
  .profile.message .back,
  .back.profile-arrow-back {
    position: inherit;
    text-align: end;
    padding: 0 1rem;
  }


  .favourite .back:focus,
  .back:active,
  .account .back:focus,
  .back:active,
  .profile.message .back:focus,
  .back:active,
  .back.profile-arrow-back:active,
  .back.profile-arrow-back:focus {
    filter: none;
    height: auto;
    width: auto;
    border-radius: 0;
    background: inherit;
    text-align: end;
    display: block;
  }

  .back:focus img.arrow-Back,
  .back:active img.arrow-Back {
    filter: none !important;
  }

  .milford-community-head {
    flex-wrap: wrap;

    img {
      margin: 20px 0 50px;
    }

    .milford-community {
      position: static;
      width: 100%;
      z-index: 9;
      display: flex;
      justify-content: center;

      .milford-img-border1 {
        height: 150px;
        width: 150px;

        .milford-img-border2 {
          height: 137px;
          width: 137px;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .address,
  .square,
  .scheduled-square,
.pricediv,
  .attractiondiv {
    margin-bottom: 20px;
  }
  .singleproduct .gallar .right.img {
    overflow-x: auto;
    margin-top: 10px;
  }
}

@media (max-width:576.5px) {
  .productcard span.wishlist, .productcard span.dislike {
    height: 46px;
    width: 46px;
    color: #000;
}
  .contact_us form input {
    border-radius: 0;
}
   .proven-results .bold-txt, .proven-results .bold-txt-1 {
    text-shadow: 0.5px 0 #000, -0.5px 0 #000, 0 0.5px #000, 0 -0.5px #000, 0.5px 0.5px #000, -0.5px -0.5px #000, 0.5px -0.5px #000, -0.5px 0.5px #000;
  }
  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.thankyou-section .head-section h2 {
    font-size: 44px !important; 
}
.app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.thankyou-section .head-section p { 
  font-size: 14px; 
  padding: 0 6px;
}

  .animate-banner .decoration-img .animate_banner1-img {
    padding: 0 8px !important;
}


  .testimonial-carousel .comments-header {
    font-size: 44px !important;
    margin-bottom: 20px;
  }
  .testimonial-carousel {
    padding: 5rem 0; 
}
.comments-txt p {
  font-size: 24px; 
  line-height: inherit; 
}
  #root .leadGeneration .banner-heading h1 {
    font-size: 36px;
  }

  #root .leadGeneration .lead-contactus .lead-contact-form .form-group {
    margin-bottom: 1.22rem;
  }

  #root .leadGeneration .lead-contactus .lead-contact-form .form-group label {
    margin: 0;
  }

  #root .leadGeneration .lead-contactus .lead-contact-form .form-group textarea.form-control {
    margin-top: 15px;
  }

  #root .leadGeneration .banner-heading p {
    font-size: 15px;
    margin: 20px 0;
    font-family: NeueTarasque;
  }

  #root .leadGeneration .banner-heading .btn,
  #root .leadGeneration .lead-contactus .lead-contact-form .btn {
    max-width: 200px;
   height: 40px;
    font-size: 14px;
  }

  #root .leadGeneration .lead-subheading h3 {
    font-size: 32px;
  }

  #root .leadGeneration .lead-subheading p {
    font-size: 14px;
    line-height: 26px;
  }

  #root .leadGeneration .lead-contactus {
    padding: 3rem 0;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-footer-btn .btn {
    margin-right: 1rem;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-footer-btn .cont-us {
    max-width: 190px;
    font-size: 16px;
  }

  .animate-banner .decoration-img .card-spacing {
    margin: 2.5rem 0 !important;
  }

  section.property-overview .table tr {
    font-size: 14px;
  }

  .property-overview-updated .paymentgraph .chartcalc ul li span:first-child {
    min-width: 110px;
    margin-right: 60px;
  }

  .attractiondiv span:nth-child(3) {
    margin-top: 10px;
    font-size: 16px;
  }

  .profile-dropdown ul li .sign-out-btn {
    padding: 10px 18px;
  }

  .nav-heart-user .profile-dropdown ul {
    padding-top: 10px;
  }

  .nav-heart-user .profile-dropdown ul li a {
    padding: 10px 1rem;
    font-size: 14px;
  }

  .HomeNamv .nav-heart-user.desktopHidden {
    top: 11px;
  }

  .HomeNamv .btn.btn-login.loginbtn-top {
    top: 13px;
    right: 110px;
  }

  .singleproduct .custom-container {
    padding: 0px 20px;
  }

  .singleproduct .gallar .right.img {

    overflow-x: auto; 
  }

  .property-overview-updated .scheduleTour img.karie {
    height: 200px;
    width: 200px;
  }

  .property-overview-updated .accordion-main .accordion .accordion-item .accordion-heading {
    font-size: 20px !important;

  }

  .singleproduct .sale .price-details h1 {
    font-size: 28px !important;
    text-align: left !important;
  }

  .singleproduct .sale .address span:first-child {
    font-size: 25px;
  }

  .singleproduct button.viewallphoto {
    bottom: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 150px;
    justify-content: center;
    white-space: nowrap;
    flex-wrap: nowrap;
    left: 20px;
  }

  .singleproduct .sale .address {
    text-align: left;
  }

  .singleproduct span.sale-text {
    font-size: 12px;
  }

  .singleproduct .sale .row .action {
    justify-content: end;
  }

  .singleproduct .property-overview-updated .accordion-main .accordion .accordion-item {
    padding: 2rem 0rem 0rem !important;
  }

  .footer-bg .footer-cont .row .col.footer-left {
    display: block !important;
  }

  .footer-bg .footer-cont .row .col.footer-left h2,
  .col.footer-left span {
    font-size: 26px !important;
    line-height: 12px;
  }

  .footer-bg .footer-cont {

    .links {
      padding: 40px 0 100px;
    }
  }

  .footer-bg .footer-email a {
    padding: 10px 16px;
  }

  .card-btn {
    width: 100%;
    max-width: 308px;
  }

  .animate-banner {
    padding: 0 10px;
    overflow-x: hidden;
  }

  .milford-magazine {
    .milford-magazine-section {
      padding: 52px 0;
    }

    h2 {
      font-size: 44px !important;
     line-height: 66px;
    }


  }

  .sarving-card {
    height: 168px;
    font-size: 20px !important;
  }


  .cont-us {
    width: 100%;
    max-width: 400px;
  }

  .coming-soon {
    min-height: inherit;
    padding: 2rem;
  }

  .early .example-left {
    font-size: 68px;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .head-section {
    h2 {
      font-size: 34px !important;
    }

    p {

      font-size: 13px;
    }
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .row .col-md-6 .modal-left-section {
    max-width: inherit;
    padding: 0 2rem;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .head-section h2 {
    font-size: 28px !important;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .modal-inner-form {
    margin-top: 2rem;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .steps>div {
    height: 9px;
  }

  .coming-soon .iphone {
    max-width: 95%;
    padding: 40px 20px;
  }

  .serving-section {
    margin: 60px auto 0;
  }

  .clip-path-section {
    height: 190px;
  }

  .clip-path-img-section {

    top: -44px;
  }

  .home-page-banner-section .animation-box {
    height: 110px;

    .text-container-animation {
      font-size: 56px !important;
    }
  }

  .proven-results .creativity {
    padding: 40px 0;
    font-size: 26px;
    line-height: 38px;
  }

  ul.homepage-nav {
    justify-content: flex-start;
    padding: 10px;

    li {
      padding: 2px 14px;
    }
  }

  .header-bar {
    .top-header {
      a {
        .req-btn {
          margin: 0 20px !important; 
        }
      }

      .head-btn {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0 !important;

        a {
          .req-btn {
            margin: 0 10px !important;
          }

          i {
            margin: 0 !important;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  .app-notify-modal .modal-dialog {
    max-width: 90%;
    margin: 9px auto;
  }

  .app-notify-modal .modal-dialog .modal-content .btn-close {
    right: 20px;
    top: 20px;
    font-size: 21px;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question {
    margin: 2rem 0 1rem;
  }

  .app-notify-modal.quiz-modal .modal-dialog .modal-content .modal-form.quiz-inner-body .quiz-right-section .quiz-inner-body .quiz-question .quiz-footer-btn {
    bottom: 50px;
    width: 100%;
    left: 0;
    padding-left: 20px;
  }

  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-right,
  .apexcharts-legend.apx-legend-position-left {
    display: none;
  }

  .singleproduct .gallar .right.img img {
    width: 39%;
    height: inherit;
  }

  .animate-banner .decoration-img .card-width .decoration-card .text {
    font-size: 16px;
    line-height: inherit;
    margin-bottom: 1rem;
  }

  .animate-banner .decoration-img .card-width .decoration-card .text-bg {
    font-size: 22px;
  }

  body .header-bar {
    padding: 6px;
  }

  .nav-wrapper .nav-container .nav-heart-user {
    height: 42px;
    width: auto;
    padding: 0;
  } 

  .nav-wrapper .nav-container .nav-heart-user .profile-dropdown {
    padding-left: 4px;
  }

  .app-notify-modal .modal-dialog .modal-content {
    border-radius: 10px;
  }

  .animate-banner .decoration-img .card-width .decoration-card .card-btn {
    font-size: 14px;
  }

  .milford-magazine .subscribe-section h3 {
    text-align: center;
    padding: 0 10px;
  }
  .proven-results {
    max-width: 85%; 
}
  .proven-results a .cont-us {
    font-size: 14px;
    margin-top: 16px;
}
.serving-txt { 
  padding: 20px;
}
.serving-main-section {
  margin-bottom: 3rem;
}
.animate-banner .decoration-img .card-width .decoration-card { 
  min-height: 400px;
}
.milford-magazine .milford-magazine-section {
  max-width: 85%;
}
.milford-community-head .milford-community {
  display: none;
}
.singleproduct .property-overview-updated .fixedheight .row > .col-md-6 {
  flex: 0 0 50%;
  padding-left: 10px;
  padding-right: 10px;
}
#property-overview .square h6 {
  font-size: 16px !important;
}
.singleproduct section.sale, .singleproduct section.sale.is-sticky {
  padding: 12px 20px 0;
}
.singleproduct section.productdetail {
  background-color: #0000;
}
.singleproduct .productdetail .property-fecility {
  display: flex; 
  gap: 10px;
}
.singleproduct .productdetail .property-fecility div {
  border: 1px solid #E3E3E3;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 22.7%;
  width: 100%;
  border-radius: 10px;
}
.singleproduct .attractiondiv span:nth-child(2), .singleproduct .attractiondiv span:nth-child(3) {
  font-size: 16px;
  font-weight: 600;
}
.singleproduct .mydiv span:nth-child(2) {
  font-size: 16px;
  font-weight: 700;
}
.estimatedvalue .chartcalc ul li span:first-child {
  min-width: 130px;
  margin-right: 0;
  display: inline-block;
}


.singleproduct .productdetail { 
  margin-bottom: 0;
}
.singleproduct .property-overview-updated .fixedheight {
  padding: 0 10px;

}
.singleproduct .productdetail .property-fecility div span:nth-child(2) {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.singleproduct .productdetail .property-fecility span.facility-similar-style {
  display: block;
  margin: 5px 0 0 0;
  font-size: 14px;
}
.singleproduct .productdetail .property-fecility div span:nth-child(2) {
  font-size: 14px;
  margin-top: 8px;
  font-weight: 400;
}
.singleproduct .productdetail .property-fecility div span img {
  max-width: 23px;
}
.property-overview-updated .accordion-main .accordion .accordion-item .key-information-table table tr td, .property-overview-updated .accordion-main .accordion .accordion-item .key-information-table table tr th {
  font-size: 14px;
}
.property-overview-updated .accordion-main .accordion .accordion-item.key-info .accordion-body .table-responsive tr td {
  font-size: 14px;
}
.singleproduct .property-overview-updated .accordion-main .accordion .accordion-item.key-info {
  padding: 1rem 10px 0 !important;
}
.productcard  span.street-name,.productcard  .truncate-line {
  font-size: 14px;
}
.similarproduct .content-row {
  flex-wrap: wrap-reverse;
}
.property-overview-updated .siderow .table-responsive table tr td, .property-overview-updated .siderow .table-responsive table tr th {
  font-size: 14px;
}
.productcard span.street-name {
  width: inherit;
}
.productcard .card-price {
font-size: 20px !important;
}
.productcard .card-body {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
body .similarproduct .productcard {
  margin-right: 0;
}
.singleproduct .property-overview-updated .accordion-main .accordion .accordion-item.key-info .accordion-body{
  padding: 10px 0 !important;
}
.updated-serving {
  padding: 0 10px;
}
.updated-serving h3 {
  font-size: 18px !important;
  margin-bottom: 0;
  margin-top: 20px;
}
.updated-serving .updated-serving-card .serving-bottom h3 {
  font-size: 20px !important;
}
.updated-serving .updated-serving-card .serving-bottom {
  padding: 20px 10px; 
}
}


@media (max-width:480.5px) {
  .singleproduct section.sale, .singleproduct section.sale.is-sticky {
    padding: 12px 10px 0;
}
  .quiz-parent .col-6 .img_ani {
    height: 220px;
    background-size: contain;
}
  .nav-wrapper .nav-container .parent-filter-flex {
    width: calc(100% - 20px);
  }

  .HomeNamv .btn.btn-login.loginbtn-top {
    right: 71px;
  }

  .HomeNamv .nav-heart-user.desktopHidden {
    right: 70px;
  }

  .chartcalc ul {
    padding-left: 8px;
  }

  .singleproduct .gallar .left-img .big-img {
    max-height: 240px;
  }

  .singleproduct .sale .price-details h1 {
    font-size: 22px !important;
  }

  .singleproduct .property-overview-updated .accordion-main .accordion .accordion-item .accordion-body h3 {
    font-size: 18px !important;
  }

  .singleproduct .sale .address span:first-child {
    font-size: 18px;
  }

  .singleproduct .sale .action .spancircle {
    height: 40px;
    width: 40px;
  }

  .singleproduct section.property-overview ul li {
    font-size: 14px;
  }

  .HomeNamv .navbar .container-fluid {
    padding: 0px 10px;
  }

  .singleproduct .custom-container {
    padding: 0px 10px
  }

  .singleproduct .property-overview-updated .accordion-main .accordion .accordion-item .property-overview-list {
    font-size: 14px;
    margin-left: 0;
  }

  .home-page-banner-section .animation-box .text-container-animation {
    font-size: 36px !important;
  }

  .home-page-banner-section .animation-box {
    height: 75px;
  }

  .proven-results .bold-txt,
  .proven-results .bold-txt-1 {
    font-size: 40px;
    line-height: inherit;
    margin: inherit;
  }

  .proven-results .creativity {
    padding: 20px 0;
   font-size: 20px;
    line-height: inherit;
  }

  .cont-us {
    // height: 40px;
    max-width: 250px;
  }

  .quotes-section .comments-txt .comments-header,
  .header-txt {
    font-size: 26px !important;
  }

  .quotes-section .comments-txt p {
    font-size: 18px;
    line-height: inherit;
  }

  .serving-txt {
    font-size: 20px;
    line-height: inherit;
  }

  .coming-soon .iphone {
    max-width: inherit;
    padding: 20px 10px 40px;
  }

  .contact_us {
    background-color: #f4f1ee;
    padding: 20px 20px 40px;
  }

  .contact-us-heading,
  .milford-magazine h2 {
    font-size: 36px !important;
  }

  .comment-form {
    height: 138px;
  }

  .coming-soon .iphone .iphone-header {
    font-size: 26px !important;
    line-height: inherit;
  }

  .iphone-para {
    font-size: 14px;
    text-align: center;
  }

  .coming-soon .iphone .iphone-bg::before {
    height: 130px;
  }

  .quotes-section .comments-quotes {
    padding: 0 10px;
  }

  .coming-soon .iphone .iphone-ancr {
    line-height: inherit;
    font-size: 20px !important;
    max-width: 200px;
  }

  .comments-txt {
    width: inherit;
  }

  .app-notify-modal .modal-dialog .modal-content .modal-form .modal-left-section .modal-inner-form .cont-us {
    margin: 0 auto;
    max-width: inherit;
  }


  .HomeNamv .navbar {
    padding: 0px !important;

  }

  ul.homepage-nav li a {
    display: block;
    padding: 5px 12px;
    font-size: 13px;
  }

  .early .example-left {
    font-size: 58px;
    line-height: 102px;
  }

  .footer-bg .footer-cont .row .col.footer-logo {
    max-width: 40%;
  }

  .footer-bg .footer-cont .row .col.footer-left {
    max-width: 60%;
  }

  .header-bar .top-header a .req-btn {
    margin: 0 9px !important;
    font-size: 12px;
  }

  #root .leadGeneration .lead-container {
    padding: 0 10px;
  }
  .animate-banner .decoration-img .card-width .decoration-card .card-btn {
    font-size: 12px;
    padding: 9px 50px 5px;
}
ul.homepage-nav li {
  padding: 1px 8px;
}
.nav-wrapper .nav-container .parent-filter-flex .searchform .parent-search.form-control input{
  padding-left: 0;
}
}
