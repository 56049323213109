// Brand colors
$purple: #8B76BC;
$gold: #D48848;
$light-purple: #efebfa;

$primary: $gold;
$secondary: #231236;
$info: $purple;
$danger: #e55353;
$light: #eaecf0;
$text-color: #3c4b64;

$font-family-sans-serif: 'Roboto', sans-serif;


@mixin transition-default {
  transition: 0.3s all ease;
}
