.btn-primary {
  color: white;
}

.btn {
  .c-icon {
    margin: 0;
  }
}

.bg-dark {
  .btn-info {
    background-color: #a592db;
    &:hover {
      background-color: #8B76BC;
    }
  }
}