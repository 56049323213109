@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

.leadbasepaint > * {
    font-family: "Poppins", sans-serif;
}
.leadbasepaint {
    .leadbasepaint_table{   
        width: 1100px; margin:0 auto; background: #fff; padding: 15px;
    }
    .w-100{
        width: 100%;
    }
    .w-90{
        width: 90%;
    }
    .w-250px{
        width: 250px;
    }
    .p-10-0{
        padding: 10px 0px;
    }
    .soil_addendum_table_main{
        width: 1100px; margin:0 auto; background: #fff; padding: 30px;
    }
    .soiladd_sec_table{
        width: 1100px; margin:0 auto; background: #fff; padding: 30px;
    }
    .second_table_td{
        padding: 10px; border-radius: 5px; background-color: #007bff;
    }
    .second_table_td_a{
    color: #ffffff; text-decoration: none; font-size: 16px; font-weight: bold;
    }
    .text_input_soil_add{
        outline: none; border: none; border-bottom:2px solid #3b0083; width: 100px;
    }
    input {
        border: none;
    }
}
