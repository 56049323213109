.badge-primary {
  color: white;
}

.badge-selling {
  background: #a0c97c;
  color: white;
}

.badge-buying {
  background: #92AFDB;
  color: white;
}

.badge-buying-selling {
  background: #92AFDB;
  color: white;
  position: relative;
  z-index: 0;
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #a0c97c;
    border-radius: 0 10rem 10rem 0;
    z-index: -1;
  }
}
