@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

h1 {
  font-size:24px;
}

h2 {
  font-size:22px;
}

h3 {
  font-size:20px;
}

h4 {
  font-size:18px;
  font-weight: 500;
  margin-bottom: 1.3rem;
}

h5 {
  font-weight: normal;
  font-style: italic;
  font-size: 18px;
  color: #768192 !important;
}

h6 {
  font-weight: 500;
  font-size: 16px;
}

a {
  font-weight: 500;
}

p {
  margin-bottom: .5rem;
}

.bg-light {
  color: $text-color;
}

.bg-dark {
  color: white;
  .text-muted {
    color: white !important;
    opacity: .8;
  }
}

.bg-info {
  color: white;
  .text-muted {
    color: white !important;
    opacity: .8;
  }
}

.overflow-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
