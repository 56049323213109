.card {
  @include bp(laptop) {
    margin-bottom: 1rem;
  }
  .card-body {
    padding: 1rem;
    @include bp(laptop) {
      padding: .5rem;
    }
  }
}

.stat-card {
  background-color: $secondary !important;
  color: white;
  .display-1 {
    font-size: 40px;
    margin-bottom: 0;
  }
  h4 {
    opacity: .8;
    color: $info;
  }
  .card-footer {
    background: $info;
    color: $secondary;
    border: none;
    h4 {
      color: $secondary;
    }
  }
}

.equal-height-cards {
  > .col, .col-* {
    display: flex;
    align-items: stretch;
    > .card {
      width: 100%;
    }
  }
}


// no shadow on nested cards
.card {
  .card {
    box-shadow: none;
  }
}


.clickable-card {
  border: 2px dashed $info;
  background-color: transparent;
  color: $info;
  cursor: pointer;
  opacity: .65;
  &:hover {
    opacity: 1;
  }
  .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}