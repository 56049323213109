button.btn.btn-offer {
    display: block;
    background: #000;
    color: #fff;
    width: auto;
    margin: 0 auto;

}

.offermodal-parent {
    position: relative;
}

.offermodal .modal-backdrop {

    // left: 256px;
    opacity: 0.8;
}

.transaction-modal .modal-backdrop {
    opacity: 0.8;
}

.statebtn {
    width: 182px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.property-flex {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    align-items: flex-start;

}

// .property-grid {
//     display: grid;
//     grid-template-columns: auto auto;
//     grid-column-gap: 20px;
//     grid-row-gap: 15px;
// }

.property-grid {

    text-wrap: nowrap;
    max-width: 70%;
}

.inner-property-flex {
    margin-left: 30px;
}

.offercode {
    display: flex;
    align-items: baseline;
    grid-column-gap: 15px;
}

.property-type-grid {
    display: flex;
    align-items: start;

}

.property-address-input {
    width: 586px;
    min-height: 52px;
    border-radius: 12px;
}

.black-txt {
    color: #000;
}

.offersearchbtn {
    width: 50px;
}

.offersearch {
    width: 586px;
    position: relative;
}

.property-dropdown {
    min-width: 180px;
}

.transaction-dropdown .form-control {
    height: 40px;
    border-radius: 12px;
    border: 1px solid #000;
}

img.addcircle {
    border: 1px solid #000;
    border-radius: 50px;
}

button.btn.btn-primary.modal-createoffer-btn,
button.btn.btn-primary.modal-createoffer-btn:active {
    background-color: #000;
    border-color: #000;
    margin: 0 auto;
    width: 420px;
    height: 50px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
}

.transaction-modal .modal-footer {
    padding: 1.5rem 0.75rem;
}

#modal-root .transaction-modal .modal-body {
    max-height: calc(100vh - 205px);
    padding: 20px 40px;
}

.transaction-modal .modal-dialog {
    position: fixed;
    left: 0;
    right: 0;

}

.search-client-trans{
    position: absolute;
    top: 50px;
        border-radius: 8px;
}

.addtransbtn {
    width: 200px;
    text-wrap: nowrap;
    margin: 15px auto;
    display: block;
}

.btn-border.addtransbtn:hover {
    background-color: #000;
    color: #fff;
}

.offermodal .modal-header {
    display: none;
}

.offermodal .modal-content {
    background-color: #EBE2FF;
    border-color: #EBE2FF;
    border-radius: 12px;
    padding: 30px 0px;
    max-width: 300px;

    // position: absolute;
    // top: 524px;
    // left: -389px;
}

// transaction styling

.document-card-head {
    // padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.assigned-img {
    width: 32px;
    height: 35px;
    border-radius: 100%;
}

.document-card-head h1 a {
    color: #000;
    text-decoration: underline;
}

.transaction-table tbody tr td:nth-child(2) {
    background-color: #fff;
}

.transaction-table tbody tr td:nth-child(4) {
    background-color: #87D6A2;
}

.card.offer-documents-card {
    border-radius: 12px;
    padding: 16px;
}

#root .btn-sell,
#root .contrect-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    color: #000;
    border: 1px solid #000;
    text-transform: uppercase;
    margin-left: 20px;
    font-weight: 500;
}

#root .btn-sell {
    width: 182px;
    height: 30px;
}

#root .btn-sell:hover {
    background-color: #000;
    color: #fff;
}

#root .btn-sell.active {
    background-color: #000;
    color: #fff;
}

.contrect-btn {
    width: 184px;
    height: 60px
}

#root .card.offer-documents-card.offerform-card {
    background: transparent;
    box-shadow: none;
}

#root .add-contact-btn {
    width: 138px;
    height: 85px;
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    font-weight: bold;
    color: #000;
    font-size: 16px;
}

.add-circle {
    border: 1px solid #000;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    margin: 0 auto;
    margin-bottom: 8px;
}

section.offer-form {
    margin-top: 40px;
}

#root .btn-client-type {
    width: 100%;
    min-height: 60px;
    border: 1px solid #000;
    padding: 0 10px;
    box-shadow: none;
}

.offer-code {
    margin-bottom: 20px;
}

.offer-code span:first-child {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    display: block;
}

.offer-code span:last-child {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
}

.offere-search {
    position: relative;
}

.offer-circle {
    position: absolute;
    top: 12px;
    left: 15px;
}

.parent-search.form-control.offere-search.form-control {
    padding-left: 45px;
}

#root .card .offer-card {
    border-radius: 20px;
    border: 1px solid #000;
    // padding: 20px 10px;
    padding: 20px 10px 50px 10px;
}

#root .card .offer-card .form-group {
    margin-bottom: 0;
}

#root .keyifo-btn {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 42px;
    border: 1px solid #000;
}

.form-label,
.legaltextarea.Provisions label,
.legaltextarea label,
.offer-card .form-group label {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-wrap: nowrap;
}

.offer-card .custom-control {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    min-height: 35px;
    margin-top: 8px;
}

.parent-search.form-control.property-search {
    padding-left: 15px;
    border-radius: 12px;
}

.offer-heading {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;
    text-transform: capitalize;
    margin-bottom: 30px;
}

.legaltextarea textarea.form-control {
    border-radius: 8px;
    border-color: #000;
    padding: 20px;
}

#root .escrow-agent-btn {
    background-color: #E2D5FF;
    color: #000;
}

#root .escrow-status {
    background-color: #FAD284;
    color: #000;
}

#root .btnadd {
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 17px 35px;
    margin-top: 30px;
    text-wrap: nowrap;
}

footer.transfooter {
    background: #E2D5FF;
    height: 306px;

}

#root .footer-submit-btn,
#root .cancel-btn {
    border: 1px solid #000;
    padding: 18px 65px;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.footer-content {
    display: flex;
    justify-content: center;
    padding-top: 80px;
}

h4.sub-heading {
    color: #000;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
}

.card.offer-card.undercontract-card {
    background: #E2D5FF;
    border-radius: 20px;
    margin-top: 70px;
}

// section.offer-form.right-section.contract-form,
// section.offer-form.left-section.contract-form {
//     margin-top: 0;
// }

#root .btn-green {
    background-color: #87D6A2;
    color: #000;
}

#root .blue-btn {
    background-color: #9ABCF3;
    color: #000;
}

.closing-cost-top {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 40px;

}

.closing-cost-bottom {
    margin-top: 30px;
}

a.gift-link {
    color: #000;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
}

// #root .c-main>.container-fluid{
// height: calc(100% - 278px)!important;
// }

.borderstyle {
    border-left: 1px solid #000;
    padding-top: 40px;
    margin-top: 45px;
}

section.main-section {
    display: flex;
    overflow: hidden;
}

.contact-parent {
    position: relative;
    display: flex;
}

// .teamcard-parent{
//     position: relative;
// }

ul.contact-dropdown {
    padding: 0;
    position: absolute;
    z-index: 99;
    width: 537px;
    left: -8px;
    top: 81px;
}

#root button.btn.callbtn {
    width: 215.96px;
    height: 126px;
    border: 1px solid #000;
}

#root ul.contact-dropdown .contact-details-card {
    border-radius: 30px;
    border: 1.5px solid #EEE;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);

}

#root ul.contact-dropdown .contact-details-card {
    padding: 0;
}

#root ul.contact-dropdown .contact-details-card .card-body {
    padding: 32px;
}

#root p.contact-dropdown-heading {
    font-size: 16px;
    color: #000;
    margin-bottom: 12px;
}

.contact-details-card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-property-flex .form-check-input[type="radio"] {
    transform: scale(1.5);
    accent-color: #000;
}

.inner-property-flex .form-check-label {
    color: #000;
    font-size: 14px;
    margin-left: 3px;
    margin-right: 12px;
    margin-top: 2px;
    font-weight: 500;
}

.inner-property-flex .btn.btn-border.statebtn{
    padding: 17px 40px;
}

.radio-property {
    margin-left: 48px;
}

.personal-property-checkbox {
    line-height: 30px;
}

.transaction-table .custom-control-input:checked~.custom-control-label::before,
.transaction-table .custom-control-label::after,
.transaction-table .custom-control-label::before {
    border-color: #000;
    height: 20px;
    width: 20px;
    border-radius: 5px;
}

.transaction-table .custom-control-input:checked~.custom-control-label::before {
    background-color: #000;
}

span.checkbox-label {
    margin-left: 6px;
}

#root .accordion-header .h6-custom {
    font-size: 14px;
}

#root button.btn.btn-docs {
    border: 1px solid #000;
    color: #000;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 26px;
    margin: 0px 6px;
    position: relative;
    background: #fff;

}

.sharedocs-btn {
    z-index: 11;
}

#root button.btn.btn-docs.sharedocs-btn:hover,
#root button.btn.btn-docs.sharedocs-btn:active {
    background-color: #000;
    color: #fff;

}

#root button.btn.btn-docs.sharedocs-btn.active {
    background-color: #000;
    color: #fff;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 15px solid #000;
    position: absolute;
    top: -21px;
}

#root button.btn.btn-docs.reviewdocs-btn:hover {
    background-color: #7922D6;
    border-color: #7922D6;
    color: #fff;
}

#root button.btn.btn-docs.reviewdocs-btn.active {
    background-color: #7922D6;
    border-color: #7922D6;
    color: #fff;
}

.sharedocs-main {
    position: relative;
}

.docs-container {
    background: #EBE2FF;
    border-radius: 5px;
    height: 250px;
    position: absolute;
    width: 100%;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 218px;
    right: -33px;
    z-index: 2;
}

#root .btn-comon {
    border: 1px solid #000;
    color: #000;
    width: 160px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
}

.button-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 15px;
}

.submitpopup .modal-content {
    background-color: #FFFFFF;
    max-width: 100%;
}

.submitpopupsection {
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal-root .popup-heading {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 30px;
}

.submitpopupsection span:first-child {
    display: block;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    color: #000;
    font-size: 16px;
}

.submitpopupsection span:nth-child(2) {
    font-size: 16px;
    color: #000;
}

.documentsentgif {
    display: block;
    margin: 0 auto;
}

.submitpopup .modal-content {
    border-radius: 0;
}

#root .bg-pink {
    background-color: #FFCDB8;
    color: #000;
}

#root .bg-akaroa {
    background-color: #BAAB98;
    color: #000;
}

#root .yellow-bg {
    background-color: #FAFD7D;
}

#root .ten {
    background-color: #D7CCBE;
}

#root .light-blue {
    background-color: #AB94E0;
}

#root .orange {
    background-color: #FAD284;
}

#root .pink {
    background-color: #F5C0BE;
}

#root .sky {
    background-color: #D5FFF5;
}

#root .lilac {
    background-color: #EAE4FF;
}

.h1.texarea-label {
    font-size: 16px;
    font-weight: 600;
}

.personal-property-checkbox label {
    margin: 0 10px;
    line-height: 26px;
}

.emailworkflow-table tr td,
.emailworkflow-table tr th,
.emailworkflow-table tr td,
.emailworkflow-table tr th {
    border-color: #313131;
}

#root .emailworkflow-table .accordion-item {
    background-color: transparent;
}

.emailworkflow-table td {
    color: #000;
    font-size: 16px;
}

.Travertinecolors {
    background-color: #D7CCBE;
}

table.table.table-bordered.mission-table.missiontable-agent.transaction-table tbody tr td {
    background-color: #fff;
}

#root .mission-accordian.mission-accordian-undercontract .accordion-item {
    border-bottom: none;
}

section.HomeWaranty {
    background: #fff;
    padding: 16px;

}

section.HomeWaranty .table th,
section.HomeWaranty .table td {
    padding: 0.7rem 1.6rem;
}

section.HomeWaranty .table td:first-child {
    width: 0;
}

section.HomeWaranty label.form-label.col-auto.col-form-label {
    padding: 0;
}

section.HomeWaranty .table-striped tbody tr:nth-of-type(odd) {
    background-color: #ebe2ff94;
}

section.HomeWaranty .table-hover tbody tr:hover,
section.HomeWaranty .table-striped tbody tr:focus {
    background-color: #e2d5ff;
}

section.HomeWaranty .table-striped tbody tr:nth-of-type(odd):hover {
    background-color: #e2d5ff;
}

section.HomeWaranty .table tr {
    text-wrap: nowrap;
}

.personal-property-checkbox label {
    margin-bottom: 0;
    margin: 0 8px;
}
.HomeWaranty .position-relative {
    display: block;
    max-height: calc(100vh - 232px);
    overflow: auto;
    margin-bottom: 10px;
}

.search-dropdown.state-dropdown {
    width: auto;
    height: auto;
    max-height: 200px;
    background: #fdfdfd;
    overflow-y: auto;
    // width: 100%;
    // padding: 10px 0px;
    border-radius: 5px;
    z-index: 100;
    // position: absolute;
    border: 1px solid #efefef;
}

.search-dropdown.state-dropdown div {
    cursor: pointer;
    padding: 8px 24px;
    color: #000;
}

.search-dropdown.state-dropdown div:hover {
    background-color: #000;
    color: #fff;

}

.transaction-table .fa-eye:before {
    font-size: 18px;
}

.offerform-card input[type="checkbox"] {
    accent-color: #000;
    height: 18px;
    width: 18px;

}

.sepration-line {
    border-bottom: 1px solid #00000026;
}

.HomeWaranty .table tbody.align-middle {
    border-bottom: 1px solid #d8dbe0;
}

.HomeWaranty thead {
    background-color: #231236;
    color: #fff;
}

.mission-cancelled input[type="checkbox"] , .mission-accordian input[type="checkbox"]{
    accent-color: #000;
    height: 18px;
    width: 18px;
}

.pale-blue {
    background-color: #C7BBF5;
}

#root .master-mission-table .blue {
    background-color: #3B0082;
    color: #fff;
}

#root .grey {
    background-color: #B1BDC9;
}

#root .lender-btn {
    border: 1px solid #000;
    color: #000;
    font-weight: 600;
    border-radius: 8px;
    padding: 8px 20px;
    margin-right: 10px;
}

button.btn.signaturebtn {
    border: 1px solid #000;
    color: #000;
    font-weight: 500;
    padding: 7px 14px;
}

// .claim-card.card{
//     font-size: 15px;
//     color: #000;
// }

// #root .claim-card .card .card-body{
//     padding: 0 15px;
// }

button.btn.claim-btn{
    background: black;
    color: #fff;
    padding: 7px 16px;
    font-weight: 500;
    font-size: 16px;
}

#root .claim-table{
    padding: 20px;
}
  #root .claim-table .table thead {
    background: #000;
    color: #fff;
  }
#root .claim-table .table td,
#root .claim-table .table thead th{
    border: none;
    padding: 24px 20px;
    text-wrap: nowrap;
}

#root .claim-table .table tr{
    box-shadow: 0 6px 0px 0 rgba(60, 75, 100, 0.14), 0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 3px 11px 0 rgba(60, 75, 100, 0.2);
}
#root .claim-table table td:nth-child(1),
#modal-root .claim-table table td:nth-child(1){
    max-width: fit-content;
}

#root .signaturebtn {

    border: 1px solid #000;
    
    color: #000;
    
    font-weight: 500;
    
    padding: 7px 14px;
    background-color: #000;
    color: #fff;
    
    }




@media (max-width: 1600px) {}

@media (min-width: 1200px) {
    .card-button-flex {
        display: flex;
    }
}

@media (min-width: 991px) {}

@media (max-width: 991px) {
    .borderstyle {
        border: none;
        padding: 0;
        margin: 0;
    }

    #root .btn-sell {
        width: 160px;
    }

    .closing-cost-bottom {
        margin-top: 18px;
    }

    .closing-cost-top {
        padding-bottom: 25px;
    }

    #root .btnadd {
        width: 100%;
    }

}

@media (max-width: 1200px) {
    .contact-parent {
        display: block;
    }

    #root .contrect-btn {
        margin-left: 0;
        height: 50px;
    }
}

@media (max-width: 576px) {

    .contact-parent {
        display: flex;
    }

    .inner-property-flex {
        display: flex;
        flex-wrap: wrap;
    }

    .statebtn {
        width: 47%;
        text-wrap: balance;
    }

    .property-grid {
        max-width: 100%;

    }

    .property-address-input {
        max-width: 100%;
        min-width: 100%;
    }

    .offer-code-txt {
        font-size: 13px;
    }

    #modal-root .transaction-modal .modal-body {
        max-height: 76vh;
    }

    .sm-inner-property-flex {
        display: block;
    }

    .tabmain {
        text-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 20px;
    }

    .card.offer-documents-card {
        padding: 16px 5px;
    }

    section.main-section {
        display: block;
    }

    #root .selleronly-btn {
        margin: 16px 0px;
    }

    .sm-margin-custom {
        margin-top: 18px;
    }

    #root .btn-sell {
        margin-left: 10px;
    }

    #root .contrect-btn {

        margin-top: 15px;

    }

    #root .btn-sell {
        width: auto;
    }

    .document-card-head h1 a {
        font-size: 22px;
    }

    .card-transaction-btn {
        display: block;
    }

    button.btn.btnadd {
        width: 100%;
    }

    .document-card-head {
        margin-bottom: 20px;
    }

    section.offer-form.right-section {
        margin-top: 0;
    }

    .card.offer-card.undercontract-card {
        margin-top: 30px;
    }

    #root button.btn.btn-docs {
        padding: 8px 19px;
    }

    .offeragent{
        width: 88%;
    }

}

