.filter-bar {
  margin-bottom: 10px;
  .filters {
    width: 65%;
    @include bp(mobile) {
      width: auto;
    }
  }
  .form-group {
    max-width: 220px;
    margin-bottom: 0;
  }
  .input-group {
    max-width: 300px;
  }
}

//style for all types filter dropdown so it doesnt get cut off

.min-width-130 {
  .form-control {
    min-width: 130px;
  }
}

//margin for mobile on email button and add new//

.add-new-email-button-container {
  @media screen and (max-width: 406px) {
    margin-top: 10px;
  }
}