.react-calendar {
  border: none;
  @media (max-width: 375px) {
    width: 270px;
  }
  .react-calendar__tile {
    color: $text-color;
  }
  .react-calendar__tile--active {
    background: $info !important;
    color: white !important;
  }
  .react-calendar__tile--now {
    background-color: $light;
  }
  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background: $light;
  }
  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }
  .react-calendar__tile:disabled {
    background-color: transparent;
    opacity: .5;
  }
  .react-calendar__navigation button:disabled {
    background-color: transparent;
    opacity: .5;
    color: inherit;
  }
  button.react-calendar__navigation__arrow {
    font-size: 18px;
  }
  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: $light;
  }
  .react-calendar__navigation__label__labelText {
    font-weight: 500;
    font-size: 18px;
    color: $text-color;
    @media (max-width: 375px) {
      width: 270px;
    }
  }
}