.overflow-list {
  overflow-y: auto;
}

// Sidebar lists
.clickable-list-item {
  li {
    padding: 6px 6px 6px 12px;
    border-left: 3px solid transparent;
    cursor: pointer;
    &.active {
      background: rgba($light, .5);
      border-left: 3px solid $info;
      font-weight: 600;
    }
    &:hover {
      background: rgba($light, .3);
    }
    p {
      margin-bottom: 0;
    }
  }
}




// Activity list
.activity-log {
  li {
    display: flex;
    padding: 0.2rem 0.5rem;
    .icon {
      width: 16px;
      margin-right: 0.5rem;
      color: $info;
    }
    .timestamp {
      min-width: 100px;
      margin-right: 1rem;
    }
    .by-line {
      font-style: italic;
      opacity: 0.5;
    }
  }
}
