.login-bg {
  padding-top: 120px;
  background-image: url('/images/purple-pattern.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  .card {
    border-radius: 0;
    border: none;
    max-width: 800px;
    margin: auto;
  }
  .logo-graphic-wrapper {
    position: relative;
    overflow: hidden;
    background: $info;
    left: -10px;
    min-height: 350px;
    .login-graphic {
      position: absolute;
      left: -80px;
      top: 30px;
      width: 100%;
      opacity: .1;
    }
  }
  .login-logo {
    width: 200px;
    padding-bottom: 45px;
    @include bp(mobile) {
      width: 250px;
    }
  }
}


.user-image {
  border-radius: 50%;
  margin: 2px;
}
