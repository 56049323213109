.c-main {
  max-height: calc(100vh - 56px);
  min-height: auto;
  z-index: 1;
  > .container-fluid {
    height: 100%;
    .page-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .content-wrapper {
      display: flex;
      flex: 1 1 auto !important;
      overflow-y: auto;
      > .col {
        height: 100%;
        .overflow-content-wrapper {
          flex: 1 1 auto !important;
          overflow-y: auto;
          .equal-height-cards.flex-fill {
            overflow-y: auto;
            > .col {
              max-height: 100%;
              .card {
                max-height: 100%;
                overflow-y: auto;
              }
            }
          }
        }
      }
    }
  }
}

.c-wrapper {
  @media (min-width: 992px)  {
    margin-left: 270px;
  }
}

.main-content-wrapper {
  flex: 1 1 auto !important;
  overflow-y: visible;
}

.reduced-gutters {
  margin: 0 -7.5px;
  > .col {
    padding: 0 7.5px;
  }
  > [class^='col'] {
    padding: 0 7.5px;
  }
}

.overflow-scroll {
  overflow-y: auto;
}


// Responsive
@include bp(laptop) {
  .c-main {
    padding-top: 1rem;
    > .container-fluid {
      padding: 0 15px;
    }
  }
  .row {
    margin: 0 -7.5px;
    > .col {
      padding: 0 7.5px;
    }
  }
  .min-height-400 {
    min-height: 400px;
  }
}

@include bp(mobile) {
  .c-main > .container-fluid .content-wrapper {
    display: block !important;
    flex: none !important;
    overflow-y: inherit !important;
    height: 100% !important;
    > .col {
      height: auto;
    }
  }
  .main-content-wrapper {
   .card-body.position-absolute {
     position: relative !important;
   }
  }
  .sidebar-list {
    max-height: 300px !important;
  }
}
