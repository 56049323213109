
table.clickable {
  tbody tr:hover {
    cursor: pointer;
    background: $light;
  }
  @include bp(desktoponly) {
    tr {
      .form-control {
        border: 1px solid transparent;
        appearance: none;
      }
      &:hover {
        .form-control {
          border: 1px solid #e4e7ea;
          position: relative;
        }
        .custom-select-caret{
          position: relative;
          .caret {
            display: block !important;
            pointer-events: none;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 4px;
            width: 20px;
          }
        }
      }
    }
  }
  @include bp(mobile) {
    .custom-select-caret{
      max-width: 250px;
    }
  }
}

.table-dark {
  background-color: $secondary;
}

//Controls table width of columns
table {
  td:nth-child(1) {
    max-width:200px;
    @include bp(mobile) {
      max-width:100%;
    }
  }
  td:nth-child(3) {
    min-width: 130px;
  }
  td:nth-child(4) {
  //  max-width:200px;
   @include bp(mobile) {
      max-width:100%;
    }
  }
  td:nth-child(9) {
    max-width:200px;
    @include bp(mobile) {
       max-width:100%;
     }
   }
}
.email-column {
  @include bp(mobile) {
    max-width: 100px;
    text-overflow: clip;
  }
}


.status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  &.selling {
    background: #a0c97c;
  }
  &.buying {
    background: #92AFDB;
  }
  &.buying-selling {
    background: #92AFDB;
    position: relative;
    z-index: 0;
    &::after {
      content: '';
      position: absolute;
      width: 50%;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #a0c97c;
      border-radius: 0 10rem 10rem 0;
      z-index: -1;
    }
  }
}

.pagination {
  .page-item {
    .page-link {
      border-radius: inherit;
    }
    &:first-child {
      .page-link {
        border-radius: 0.2rem 0 0 0.2rem !important;
      }
    }
    &:last-child {
      .page-link {
        border-radius: 0 0.2rem 0.2rem 0 !important;
      }
    }
  }
}

.mobile-flex {
  @include bp(mobile) {
    thead {
      display: none;
    }
    tbody {
      tr {
        border-bottom: 10px solid transparent;
        td {
          display: block;
          border: none;
          padding: 0 0 8px 0;
          &:first-child {
            border-top: 1px solid $light;
            padding-top: 10px;
          }
        }
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}

.icon-off {
  color: rgba($text-color, .3);
}
.icon-on {
  color: $info;
}

.notes-wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.c-sidebar-backdrop {
  z-index: 0 !important;
}
.table-slide {
  margin-top: 56px; //height of top nav
  overflow: auto;
  z-index: 1222222;

  @include bp(tablet) {
    padding-top: 0;
    margin-top: 66px; //height of top nav + gap
  }
  @include bp(mobile) {
    width: 100%;
  }
  .header {
    background-color: $secondary;
    padding: 15px;
    color: white;
  }
  .body {
    padding: 15px;
  }
  .form-group {
   margin-bottom: 0 !important;
  }
}

//styles for buyer-seller dropdown on slide in and contact details page//

.badge-dropdown {
  text-align: center;
  select {
    display: inline-block;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    border: 0;
    vertical-align: baseline;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 8rem;
    font-size:14px;
  }
  option {
    background-color: white;
    color: black;
  }
}

.badge-dropdown-buyer {
  select {
  font-size: 14px;
  font-weight: bold;
  background: #92AFDB;
  color: white;
  position: relative;
  z-index: 0;
  }
}

.badge-dropdown-seller {
  select {
  font-size: 14px;
  font-weight: bold;
  background: #a0c97c;
  color: white;
  }
}

.badge-dropdown-buyer-seller {
  select {
    font-size: 14px;
    font-weight: bold;
    background-image: linear-gradient(110deg, rgb(146, 175, 219), rgb(146, 175, 219) 50%, rgb(160, 201, 124) 0px) !important;
    color: white;
    position: relative;
    z-index: 0;
  }
 }

 //mobile label for last worked and last connected//
 .mobile-label{
  display:none;
  @include bp(mobile) {
    display:block;
    span {
      font-weight: 700;
    }
  }
 }

 // email button in  table and on leads detials page //
 .email-button {
  padding: 4px 6px;
  min-height: 33px;
 }

 //contact name wrap fix //

 .contact-name {
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
 }



 //table sticky

 .fixed_header {
  height: 70vh;
}
.fixed_header thead {
  position: sticky;
    top: 0;
    z-index: 999;
}

