// If you want to override variables do it here
@import "partials/theme/variables";
// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Styles that need to be outside of the root - modals, tooltips
@import "partials/components/lists";

@import "partials/components/transaction";

@import "../assets/font/Roslindale_Web/fonts.css";
@import "../assets/font/NeueTarasque/full/fonts.css";
@import "../assets/font/Secular/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.required-indicator {
  color: $danger;
}

:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 1.5em;
  --color-green: #000;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
  --bs-table-striped-bg: #ebe2ff;
}

body {
  background: #fff;
  // font-family: NeueTarasque;
}

#root,
#modal-root {
  @import "partials/theme/mixins";
  @import "partials/theme/typography";
  @import "partials/theme/icons";
  @import "partials/theme/buttons";
  @import "partials/theme/userprofile";

  @import "partials/layout/page-layout";
  @import "partials/layout/navigation";
  @import "partials/layout/scrollbar";

  @import "partials/components/badges";
  @import "partials/components/calendar";
  @import "partials/components/cards";
  @import "partials/components/collapsible";
  @import "partials/components/emails";
  @import "partials/components/filters";
  @import "partials/components/forms";
  @import "partials/components/growlers";
  @import "partials/components/login";
  @import "partials/components/modals";
  @import "partials/components/support";
  @import "partials/components/tabs";
  @import "partials/components/tables";
}

#root .c-header,
#modal-root .c-header {
  background: #e2d5ff;
}

#root .c-main,
#modal-root .c-main {
  background: #ebe2ff;
  max-height: 100%;
}

#root .c-sidebar-dark,
#modal-root .c-sidebar-dark,
#root .c-sidebar .c-sidebar-brand,
#modal-root .c-sidebar .c-sidebar-brand {
  background-color: #fff !important;
  border-radius: 0;
}

#root .c-sidebar .c-sidebar-nav li a,
#modal-root .c-sidebar .c-sidebar-nav li a {
  color: #000;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon:hover {
  color: #000;
}

#root .c-sidebar .c-sidebar-nav li a.c-active,
#modal-root .c-sidebar .c-sidebar-nav li a.c-active,
#root .c-sidebar .c-sidebar-nav li a.c-active:hover,
#modal-root .c-sidebar .c-sidebar-nav li a.c-active:hover {
  background-color: #e2d5ff;
}

.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon:hover {
  color: #000;
}

.loder-color {
  color: #8b76bc;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42rem;
  width: 100%;
  /* Adjust the height as needed */
}

.user-role-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.user-role-heading>h1 {
  background-color: rgba(139, 118, 188, 0.2);
  box-shadow: 1px 2px 3px 4px rgba(139, 118, 188, 0.5);
  padding: 20px;
}

.c-header-nav-item.profileDet {
  padding: 10px;
  font-weight: 500;
  margin-left: 10px;
}

.stageinput {
  .form-control {
    display: block;
    width: 7rem !important;
  }

  thead {
    tr th {
      white-space: nowrap !important;
    }
  }
}

.justify-content-left {
  justify-content: flex-start;
}

.selling {
  color: #a0c97c !important;
  background: transparent !important;
}

.buying {
  color: #92afdb !important;
  background: transparent !important;
}

.contact-name {
  text-transform: capitalize;
}

.leadStatus div {
  border-right: 1px solid #5f5f5f;
  padding: 0 10px;
}

.leadStatus div:last-child {
  border-right: 0;
}

.leadStatus div:first-child {
  padding-left: 0;
}

.customTxtArea textarea.form-control {
  height: 100px;
  width: 300px !important;
  box-shadow: 2px 5px 15px #efefef;
}

/*Multi select*/
.form-multi-select-option.form-multi-select-option-with-checkbox:before {
  background-color: transparent;
  color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #888888;
  border-radius: 4px;
  content: "";
  display: block;
  height: 15px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 0.7rem;
  width: 15px;
  padding-bottom: 20px;
  padding: -9px 12px;
  padding-right: 17px;
  padding-left: 4px;
}

.form-multi-select-option.form-multi-selected:before {
  content: "\2713";
  background-color: #888888;
  font-weight: bolder;
  font-size: 14px;
}

.form-multi-select-option.form-multi-select-option-with-checkbox {
  padding: 0.5rem 3.1rem;
}

.form-multi-select-option {
  border-radius: var(--cui-form-multi-select-option-border-radius);
  cursor: pointer;
  margin-bottom: 2px;
  padding: var(--cui-form-multi-select-option-padding-y) var(--cui-form-multi-select-option-padding-x);
  position: relative;
  vertical-align: top;
}

.form-multi-select-options {
  color: var(--cui-form-multi-select-options-color);
  font-size: 1rem;
  padding: var(--cui-form-multi-select-options-padding-y) var(--cui-form-multi-select-options-padding-x);
}

.form-multi-select-all {
  background-color: var(--cui-form-multi-select-select-all-bg);
  border: 0;
  border-bottom: var(--cui-form-multi-select-select-all-border);
  color: var(--cui-form-multi-select-select-all-color);
  display: block;
  text-align: start;
  width: 100%;
}

.form-multi-select.show {
  background-color: var(--cui-form-multi-select-focus-bg);
  border-color: var(--cui-form-multi-select-focus-border-color);
  box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
  color: var(--cui-form-multi-select-focus-color);
}

.dropdown-menu {
  --cui-dropdown-zindex: 1000;
  --cui-dropdown-min-width: 10rem;
  --cui-dropdown-padding-x: 0;
  --cui-dropdown-padding-y: 0.5rem;
  --cui-dropdown-spacer: 0.125rem;
  --cui-dropdown-font-size: 1rem;
  --cui-dropdown-color: rgba(44, 56, 74, 0.95);
  --cui-dropdown-bg: #fff;
  --cui-dropdown-border-color: var(--cui-border-color-translucent);
  --cui-dropdown-border-radius: 0.375rem;
  --cui-dropdown-border-width: 1px;
  --cui-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --cui-dropdown-divider-bg: var(--cui-border-color-translucent);
  --cui-dropdown-divider-margin-y: 0.5rem;
  --cui-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15);
  --cui-dropdown-link-color: #4f5d73;
  --cui-dropdown-link-hover-color: #475468;
  --cui-dropdown-link-hover-bg: #d8dbe0;
  --cui-dropdown-link-active-color: hsla(0, 0%, 100%, 0.87);
  --cui-dropdown-link-active-bg: #321fdb;
  --cui-dropdown-link-disabled-color: #9da5b1;
  --cui-dropdown-item-padding-x: 1rem;
  --cui-dropdown-item-padding-y: 0.25rem;
  --cui-dropdown-header-color: #8a93a2;
  --cui-dropdown-header-padding-x: 1rem;
  --cui-dropdown-header-padding-y: 0.5rem;
  background-clip: padding-box;
  background-color: var(--cui-dropdown-bg);
  border: var(--cui-dropdown-border-width) solid var(--cui-dropdown-border-color);
  border-radius: var(--cui-dropdown-border-radius);
  box-shadow: 0 4px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.14),
    0 1px 10px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.12),
    0 2px 4px -1px rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.2);
  color: var(--cui-dropdown-color);
  display: none;
  font-size: var(--cui-dropdown-font-size);
  list-style: none;
  margin: 0;
  min-width: var(--cui-dropdown-min-width);
  padding: var(--cui-dropdown-padding-y) var(--cui-dropdown-padding-x);
  position: absolute;
  text-align: left;
  z-index: var(--cui-dropdown-zindex);
}

.form-multi-select.show {
  background-color: var(--cui-form-multi-select-focus-bg);
  border-color: var(--cui-form-multi-select-focus-border-color);
  box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
  color: var(--cui-form-multi-select-focus-color);
}

.form-multi-select>div:not([class]):first-of-type:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.form-multi-select-selection-cleaner {
  background-color: transparent;
  background-image: var(--cui-form-multi-select-cleaner-bg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: var(--cui-form-multi-select-cleaner-width) auto;
  border: 0;
  border-right: 1px solid var(--cui-form-multi-select-cleaner-border-color, #b1b7c1);
  box-sizing: content-box;
  height: var(--cui-form-multi-select-cleaner-height);
  padding: var(--cui-form-multi-select-cleaner-padding-y) var(--cui-form-multi-select-cleaner-padding-x);
  position: absolute;
  right: var(--cui-form-multi-select-indicator-padding);
  top: 50%;
  transform: translateY(-50%);
  width: var(--cui-form-multi-select-cleaner-width);
}

.form-multi-select-selection {
  display: inline;
  padding: 0;
}

.form-multi-select-tag {
  display: inline-block;
  margin: 3px;
  border: 1px solid #efefef;
  border-radius: 4px;
  background: #efefef;
  padding: 3px 9px;
}

.form-multi-select-tag-delete {
  background-color: transparent;
  border: 0;
  margin-left: 0.5rem;
  padding: 0;
}

.main-content-wrapper.card .dropdown-menu.show {
  min-height: 300px;
  min-width: 500px;
  overflow: scroll;
  padding: 15px;
}

.form-multi-select-selection-tags {
  min-height: 2.25rem;
  border: 1px solid #e4e7ea;
  border-radius: 5px;
}

.form-multi-select>div:not([class]):first-of-type:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.form-multi-select-selection {
  display: inline;
  padding: 0;
}

.form-multi-select-search:placeholder-shown {
  width: 100%;
}

.form-multi-select-selection-tags .form-multi-select-search {
  height: 2.3rem;
  padding-left: 10px;
}

.email-form .form-multi-select-selection-tags {
  margin-top: 15px;
}

.form-multi-select-with-cleaner>div[aria-expanded="false"] {
  max-height: 200px;
  overflow: auto;
}

button.form-multi-select-selection-cleaner::before {
  content: "\2716";
}

button.form-multi-select-selection-cleaner {
  position: absolute;
  right: 20px;
  border: none;
  top: 18px;
}

.form-multi-select-search {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--cui-form-multi-select-search-bg);
  border: 0;
  border-radius: var(--cui-form-multi-select-search-border-radius);
  color: var(--cui-form-multi-select-search-color);
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  width: auto;
}

.stageinput td:nth-child(1) {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 11;
}

.stageinput td:nth-child(2) {
  position: sticky;
  left: 38px;
  background-color: #fff;
  z-index: 11;
}

.stageinput th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 22;
  background-color: #231236;
}

.stageinput th:nth-child(2) {
  position: sticky;
  left: 38px;
  z-index: 22;
  background-color: #231236;
}

.disabledcolumn {
  pointer-events: none;
}

.disabledcolumn textarea {
  background: #ebebeb;
}

.loader-position {
  position: absolute;
  right: 50%;
  bottom: 50%;
}

#root .tab-content {
  background-color: #fff;
}

#root .card .card,
#modal-root .card .card {
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}

.CustomDatepicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c5c5c5;
  border-radius: 0.2rem;
  padding: 0 0 0 4px;
  height: 30px;
  width: 135px;
}

.CustomDatepicker .dropdown {
  display: none;
}

.CustomDatepicker .dateInput:hover.dateInput {
  display: block;
}

.CustomDatepicker:hover.CustomDatepicker .dropdown {
  display: block;
}

.form-multi-select-with-cleaner>div[aria-expanded="true"] {
  max-height: 200px;
  overflow: auto;
}

.form-multi-select-option.form-multi-selected:before {
  content: "\2713";
  background-color: #888888;
}

span.sidebar-close {
  position: absolute;
  right: -29px;
  background-color: #8b76bc;
  padding: 4px;
  display: none;
  top: 0px;
  height: 30px;
  width: 30px;
  text-align: center;
  font-weight: 700;
  transform: rotate(90deg);
}

html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -290px;
}

span.dateInput {
  padding-left: 25px;
}

.CustomDatepicker:hover.CustomDatepicker span.dateInput {
  padding: 0 5px;
}

.user-relation {
  border: none;
  background: transparent;
  font-size: 30px;
  margin-left: 15px;
}

.btn.btn-primary.d-block.userprofile-socialbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#root .email-button,
#modal-root .email-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 35px;
  text-wrap: nowrap;
}

#root .userprofile-socialbtn,
#modal-root .userprofile-socialbtn>div {
  display: flex !important;
}

.emailmodalicon {
  display: flex;
}

.virtual-scroller.form-multi-select-options {
  max-height: 280px !important;
  overflow: auto;
}

.details label {
  font-weight: 800;
}

.details-heading {
  margin-bottom: 0.8px !important;
  margin-top: 0.8rem;
}

.tab-content {
  background-color: #eaecf0;
}

.d-flex.flex-column.jutsify-content-between.h-100 {
  padding-bottom: 100px;
}

html:not([dir="rtl"]) .form-check {
  padding-left: 0.5rem;
}

.checklistitem {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 20px 23px;
  margin-bottom: 15px;
}

// .nav-link {
//   color: #c18500;
// }

// .nav-link:hover {
//   color: #f3a802;
// }

input[type="checkbox"] {
  accent-color: #c18500;
  border-radius: 20px;
  width: 18px;
  height: 15px;
}

.permission-check>label {
  font-weight: 500;
  white-space: nowrap;
}

.icon .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  margin-right: 6px;
}

.icon {
  // padding-top: 8px;
  cursor: pointer;
}

.request {
  margin-right: 5px;
}

.svg-icon svg {
  fill: #546177;
}

span.seperate-line {
  margin-right: 8px;
}

.checklistMain {
  max-height: calc(100vh - 295px);
  overflow-y: auto;
}

#root .c-sidebar-dark,
#modal-root .c-sidebar-dark,
#root .c-sidebar .c-sidebar-brand,
#modal-root .c-sidebar .c-sidebar-brand {
  background-color: #000;
}

#modal-root table.clickable tbody tr:hover td:first-child,
#root table.clickable tbody tr:hover td:first-child {
  background: #eaecf0;
}

.dropdowndiv .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
}

.dropdowndiv .form-control {
  padding: 0.375rem 0.4rem;
}

.AssignPermissionbtn button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #000;
  border: none;
  border-radius: 4px;
  color: #fff;
  outline: none;
}

.assing-btn {
  margin-top: 10px;
}

img.c-sidebar-brand-full.img-fluid {
  width: 85%;
}

#root .login-bg .logo-graphic-wrapper,
#modal-root .login-bg .logo-graphic-wrapper {
  justify-content: center;
  align-items: center;
}

#root .login-bg .logo-graphic-wrapper .login-graphic,
#modal-root .login-bg .logo-graphic-wrapper .login-graphic {
  width: 50%;
  left: auto;
  top: auto;
  opacity: 1;
}

.flex-header {
  display: flex;
  flex-wrap: wrap;
}

#root .login-bg .card,
#modal-root .login-bg .card {
  max-width: 1200px;
}

#root .login-bg .logo-graphic-wrapper,
#modal-root .login-bg .logo-graphic-wrapper {
  min-height: 500px;
}

#root .login-bg .logo-graphic-wrapper,
#modal-root .login-bg .logo-graphic-wrapper {
  background: #a795db;
}

#root .login-btn .btn-primary {
  color: black;
  background: transparent;
  border-radius: 20px;
  font-weight: 500;
  font-size: 24px;
  border-color: #000;
  position: absolute;
  left: 10px;
  top: 0;
  padding: 0;
}

#root .login-btn .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.google-logo {
  width: 20px;
}

#root .login-bg .login-logo,
#modal-root .login-bg .login-logo {
  width: 400px;
}

// styling for transaction
.transaction-main {
  width: 100%;
  overflow: auto;
  padding: 0px 15px;
  white-space: nowrap;
}

h1.page-header-title.mb-0 {
  color: #fff;
}

.heading {
  font-weight: 600;
  width: 20%;
}

.prop-add input[type="text"] {
  border: 1px solid #e4e7ea;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.card-body {
  padding: 0;
}

.main-content-wrapper.card {
  height: calc(100vh - 210px);
}

.AssignPermissionbtn {
  position: absolute;
  bottom: 12px;
}

.user-role {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 8rem;
}

.transaction-wrapper {
  display: flex;
  grid: 52px / auto auto auto auto;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid rgba(45, 38, 51, 0.3);
  gap: 100px;
  padding-bottom: 20px;
}

.listing-price-wrapper {
  border-bottom: 1px solid rgba(45, 38, 51, 0.3);
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.checkbox {
  display: grid;
  grid: 30px / auto auto auto;
  column-gap: 20px;
}

.checkbox input[type="checkbox"] {
  margin-right: 6px;
  height: 18px;
  width: 18px;
}

.listing-price {
  display: flex;
  gap: 40px;
  padding: 10px 0px;
}

.price input[type="text"] {
  width: 100%;
  border: 1px solid #babcbf;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.commission-wrapper input[type="text"],
.warranty-wrapper input[type="text"],
.date-input input[type="text"] {
  border: 1px solid #babcbf;
  width: 60%;
  text-align: center;
  outline: rgb(105, 103, 103);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

input {
  outline: rgb(105, 103, 103);
}

.prop-add input {
  width: 100%;
  padding: 4px;
}

textarea.textarea {
  width: 70%;
  outline: rgb(105, 103, 103);
  padding: 6px 10px;
  border: 1px solid #babcbf;
  border-radius: 0.25rem;
}

.d-flex.date-input {
  margin-top: 30px;
}

.commission-wrapper {
  display: flex;
  border-bottom: 1px solid rgba(45, 38, 51, 0.3);
  padding-bottom: 24px;
  padding-top: 12px;
}

.commission-wrapper-main .checkbox {
  grid: 30px / auto auto;
}

.warranty-wrapper {
  border-bottom: 1px solid rgba(45, 38, 51, 0.3);
  padding: 30px 0px;
  display: flex;
  gap: 40px;
}

.warranty-company {
  background-color: #3e2b4b;
  color: #fff;
  padding: 20px;
  padding-bottom: 30px;
}

.warranty-company input[type="search"] {
  background-color: #271236;
  border: none;
  padding: 4px;
  color: #fff;
  margin-bottom: 15px;
}

h6.warranty-heading {
  background-color: #ab94e0;
  padding: 5px;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.image>img {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}

span.image {
  margin-left: 15px;
}

.prop-add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

span.agent-styling {
  padding: 6px 20px;
  color: #fff;
}

.display-flex {
  display: flex;
  gap: 20px;
}

span.office-exc,
span.custombgstyle,
.inspection-bg {
  padding: 6px 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.agent-styling {
  background-color: #806ea7;
}

span.office-exc {
  background-color: #ab94e0;
}

span.custombgstyle {
  background-color: #c8b6a9;
}

.active-color {
  color: #ab94e0;
}

.inspection-bg {
  background-color: #84b394;
}

.sample {
  text-align: center;
}

.checkbox label {
  display: flex;
  align-items: center;
}

.loginDetails {
  padding: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
}

.useraccess .main-content-wrapper .card {
  background: transparent;
  box-shadow: unset;
}

#root .clickable-card,
#modal-root .clickable-card {
  border: 1px dashed #8b76bc;
}

.signup .html:not([dir="rtl"]) .form-check {
  padding-left: 1rem;
}

.signup input.form-control {
  padding-left: 45px;
  border-radius: 12px;
  border: 1px solid #1e1d1e;
  background: #fff;
  height: 46px;
}

.signup label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 500;
}

.loginBtn,
.loginBtn:hover {
  background: #000;
  color: #fff;
  border-radius: 50px;
  font-weight: 500;
  padding: 18px 70px;
  font-size: 14px;
}

.signupBtn,
.signupBtn:hover {
  background: #000;
  color: #fff;
  padding: 16px 0px;
  border-radius: 50px;
  font-weight: 500;
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.social {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: none;
  justify-content: center;
  align-items: center;
  background-color: #000;
  position: relative;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

#root .login-bg .card,
#modal-root .login-bg .card {
  position: relative;
  vertical-align: center;
  margin-top: 60px;
  background: #ebe2ff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
  width: 1000px;
  padding: 15px 200px;
  border-radius: 10px;
}

span.mt-3.not-regidtered {
  position: absolute;
  bottom: 50px;
  right: -170px;
}

#login .login-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  padding-left: 30px;
  margin-bottom: 40px;
}

#login h1.signin-heading {
  font-size: 38px;
  color: #000;
  font-weight: 600;
  margin-top: 50px;
  margin-left: 50px;
}

#root .login-bg,
#modal-root .login-bg {
  padding-top: 0px;
  overflow-y: auto;
}

#root .login-bg {
  background-color: #ebe2ff;
  background-image: none;
}

#root .login-bg {
  height: calc(100vh - 100px);
}

svg.tringle-bg {
  position: fixed;
  left: 0;
  top: 0;
}

.login-option-btn,
.login-option-btn:hover {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #000;
  background: #ab94e0;
  padding: 12px 50px;
  margin-top: 15px;
  text-decoration: none;
}

span.loginSignup {
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

span.mt-3.signup-option {
  display: flex;
  justify-content: end;
}

.not-registared {
  color: var(--BlackestBlack, #000);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

button.btn.signupbtn {
  display: block;
  float: right;
  border-radius: 50px;
  border: 1px solid #000;
  background: #ab94e0;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 26px;
}

#root .signup-bg .card {
  box-shadow: none;
  margin-top: -14px;
  padding: 0px 0px;
  width: 100%;
}

#root .signup-bg .signup-heading {
  color: #000;
  font-size: 36px;
  font-weight: 600;
  margin-top: 70px;
}

#root .signup-bg .card-left {
  border-radius: 10px;
  background: #ebe2ff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  margin-bottom: 130px;
}

#root .signup-bg .card-body {
  padding: 0;
}

#root .signup-bg .card-left .left-card-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 15px;
}

.login-option {
  margin-top: 50px;
}

span.login-option-text {
  display: block;
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
}

#root .signup-bg .card-left .left-card-paragraph {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 50px;
}

.btn-custom-style {
  border-radius: 8px;
  font-weight: 500;
  border: 1px solid #000;
  padding: 15px;
  width: 100%;
  font-size: 14px;
  text-align: center;
}

.buy-btn,
.buy-btn:hover {
  color: #fff;
  background: #1e1d1e;
  margin-right: 10px;
}

.sell-btn,
.sell-btn:hover {
  background: #fff;
  color: #000;
}

.buy-sell-btn,
.buy-sell-btn:hover {
  background: #fff;
  color: #000;
  margin-top: 30px;
}

#root .signup-bg-left .card {
  background-color: red;
}

#root .signup-bg {
  height: calc(100vh - 100px);
}

.bg-body-color {
  /* background: red; */
  border-radius: 10px;
  background: #ebe2ff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
  width: 541px;
  height: 447px;
}

.form-outline {
  position: relative;
}

i.fas.fa-eye.password-toggle,
i.fas.fa-eye-slash.password-toggle {
  position: absolute;
  right: 13px;
  bottom: 16px;
  cursor: pointer;
  color: #0000004d;
}

.fa-fw {
  position: absolute;
  top: 56px;
  left: 15px;
  color: #0000004d;
}

span.social .btn-primary,
span.social .btn-primary:focus {
  border: none !important;
  box-shadow: none !important;
}

.fw-bold {
  font-weight: 600;
}

section.otpvrfy .form-control {
  width: 5rem;
  height: 3.5rem;
  border-radius: 12px;
  text-align: center;
}

.otpvrfy .flex {
  gap: 20px;
}

.customflex {
  display: flex;
}

.collapsebutton {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  display: none;
}

.plus-minus.collapsed:after {
  content: "+";
  top: 0;
}

.plus-minus:after {
  content: "-";
  top: 0;
}

// verify identity screen
.grid {
  display: grid;
  grid-gap: var(--card-padding);
  margin: 0px auto;
  max-width: 60em;
  grid-template-columns: auto auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 35px;
  grid-template-columns: auto auto auto auto;
}

.team {
  position: relative;
  // margin-right: 25px;
}

#root .user-image {
  position: absolute;
}

label.identitycard {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
  display: block !important;

  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: 0.2s ease-out, border-color 0.2s ease-out;
    width: var(--radio-size);
    display: block;

    &::after {
      border: var(--radio-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: "";
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: rotate(45deg) translate(-50%, -50%);
      width: 0.375rem;
    }

    &:checked {
      background: var(--color-green);
      border-color: var(--color-green);
    }
  }

  .card:hover .radio {
    border-color: var(--color-dark-gray);

    &:checked {
      border-color: var(--color-green);
    }
  }
}

.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #e2d5ff;
    border-radius: 12px;
    margin-right: 20px;
  }
}

.card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked~.plan-details {
  border-color: var(--color-green);
}

.radio:focus~.plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled~.plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled~.plan-details .plan-type {
  color: var(--color-dark-gray);
}

.card:hover .radio:disabled~.plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card:hover .radio:disabled {
  border-color: var(--color-gray);
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.react-tel-input .form-control {
  min-width: 100%;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-radius: 3px !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 15px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
}

// RDX Home Screen Styling

.custom-container {
  padding: 0px 60px;
}

.profileCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.navHome {
  justify-content: space-between;
}

.navHome .navbar-nav li.nav-item .nav-link {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.navul {
  gap: 30px;
  line-height: 18px;
}

ul.navbar-nav.navview {
  gap: 6px;
}

.parent-search.form-control {
  height: 50px;
  border-radius: 75px;
  border: none;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.1);
  padding-left: 90px;
  border: 1px solid #000;
  // padding-right: 50px;
}

.navbarsearch:focus {
  box-shadow: none;
}

.navbarsearch {
  border: 0;
  background: transparent;
}

.flex-filters .navbar-nav li.nav-item a {
  color: #787778;
}

.searchform {
  min-width: 440px;
  position: relative;
  margin-right: 30px;
}

.iconsearch {
  position: absolute;
  z-index: 1;
  line-height: 52px;
  left: 70px;
  font-size: 16px;
}

button.searchfilter {
  position: absolute;
  height: 50px;
  border-radius: 12px 0px 0px 12px;
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  background: transparent;
  padding: 13px 19px;
}

.search-option {
  position: absolute;
  right: 15px;
  top: 10px;
}

.HomeNamv {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 999;
}

.sticky-nav {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
}

.navHome .navbar {
  padding: 1rem 0rem;
}

.filters-product {
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  padding: 13px 20px;
}

.filters-product span:last-child {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.filter-flex {
  display: flex;
  align-items: center;
}

.parent-filter-flex {
  display: flex;
  align-items: center;
}

.productlist {
  margin-bottom: 10px;
  min-height: calc(100vh - 600px);
}

// navbar styling
.navHome .nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #ab94e0;
  transition: width 0.2s;
}

.navul .nav-link:hover::after {
  width: 100%;
  transition: width 0.4s ease-in;
}

.navHome .nav-link.active::after {
  content: "";
  width: 100%;
  background: #ab94e0;
  height: 3px;
}

.bg-nav.active {
  background-color: #ab94e0;
  padding: 6px 0px;
  display: block;
  width: 120px;
}

.bg-nav.active img.house1,
.bg-nav.active .nav-link {
  filter: invert(0%) sepia(91%) saturate(0%) hue-rotate(250deg) brightness(50%) contrast(200%);
}

// productlisting styling

.singleproduct {
  margin-top: 70px;
  min-height: 100vh;
}

#idx-filter-modal .btn-close {
  position: absolute;
}

#idx-filter-modal .modal-title {
  margin: 0 auto;
}

#idx-filter-modal .btn-group-filter {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin-right: 20px;
}

.btn-group__item {
  border: none;
  min-width: 6rem;
  padding: 2rem 3.06rem;
  cursor: pointer;
  margin: 0;
  transition: all 300ms ease-out;
  background: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  white-space: nowrap;
}

.slider-range {
  width: 100%;
  padding: 0 12px;
}

.range {
  background-color: #ebe2ff;
  height: 6px;
  width: 100%;
  border-radius: 10px;
}

.rangethumb {
  background: rgb(171, 148, 224);
  width: 22px;
  height: 21.475px;
  border-radius: 50%;
  outline: none;
  transform: translate(565.5px, -9px);
  border: 2px solid #000;
}

.mainrangebg {
  position: relative;
}

.price-range {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

img.barchart {
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6px;
  background-color: rgb(0, 0, 0);
  z-index: 1;
}

button.btn.btn-circle {
  padding: 4px 22px;
  border-radius: 69px;
  border: 1px solid var(--light-main-border-divider-border-default, #dfe2e6);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-right: 15px;
}

button.btn.btn-circle.active {
  background: #000;
  color: #fff;
}

#idx-filter-modal .modal-body {
  max-height: 75vh;
}

#idx-filter-modal .modal-header {
  align-items: center;
}

#idx-filter-modal .modal-footer {
  justify-content: flex-start;
  padding: 2.5rem 1.75rem;
}

#idx-filter-modal .footer-close-btn {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration: underline;
}

.btn-center {
  margin: 0 auto;
  display: flex;
  padding: 12px 34px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #e2d5ff;
  border: none;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

#idx-filter-modal h6 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

#idx-filter-modal .btn-group__item label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#idx-filter-modal button.btn-group__item.active {
  background: #ab94e0;
  color: #000;
  font-weight: 500;
}

#idx-filter-modal button.btn-group__item.active img {
  filter: invert(0%) sepia(91%) saturate(0%) hue-rotate(250deg) brightness(50%) contrast(200%);
}

.idxdropdown,
.idxdropdown:hover,
.idxdropdown:focus,
.idxdropdown:active {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--light-main-border-divider-border-default, #dfe2e6);
  padding: 8px 12px;
  background: transparent;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.idx-filter-form .input-group {
  flex-direction: row-reverse;
}

.searchbg {
  position: relative;
}

.input-group-append {
  position: absolute;
  top: 6px;
}

button#button-addon1 i.fa.fa-search {
  color: #000;
}

.idx-input-margin-top {
  margin-top: 1.5rem;
}

.special-feature {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  color: #000;
  font-weight: 500;
}

.special-feature-input input[type="checkbox"] {
  accent-color: #000;
  width: 23px;
  height: 23px;
  margin-bottom: 26px;
}

.savebtndiv {
  margin-bottom: 70px;
}

.btn.savesearchbtn {
  padding: 12px 38px;
  border-radius: 8px;
  background: #000;
  color: #fff;
  float: right;
}

button.clear-btn {
  margin-left: 8px;
  width: 72px;
}

.input-group-filter {
  position: absolute;
  bottom: 12px;
  right: 15px;
}

.idx-search-input {
  border-radius: 8px;
  border: 1px solid var(--light-main-border-divider-border-default, #dfe2e6);
  padding: 0px 36px;
  height: 48px;
}

.productcard {
  border-radius: 12px !important;
}

.productcard img {
  border-radius: 12px 12px 0px 0px;
  cursor: pointer;
}

.featured-idx-img {
  height: 255px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
}

.gallery-carousel .featured-idx-img {
  height: calc(100vh - 190px);
  object-fit: cover;
}

.content-row {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap-reverse;
}

.card-price {
  font-weight: 700;
  line-height: 28px;
  color: #000;
}

.cardaddress1 {
  font-weight: 600;
  color: #000;
}

.cHVLag ul {
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  margin-top: 15px;
}

.cHVLag ul li:first-child {
  list-style: none;
}

.cHVLag span {
  font-size: 14px;
  font-weight: 700;
  margin-right: 4px;
  color: #000;
}

span.wishlist,
span.dislike {
  position: absolute;
  display: flex;
  height: 34px;
  width: 34px;
  top: 10px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
}

span.wishlist {
  right: 8px;
}

span.dislike {
  right: 55px;
}

.wishlist-icon {
  font-size: 24px;
  color: red;
}

.dislike-icon,
.wishlist-regular-icon {
  font-size: 24px;
}

.productcard .sale-txt,
.productcard .new-text {
  position: absolute;
  left: 8px;
  padding: 5px 25px;
  border-radius: 5px;
}

.productcard .sale-txt {
  top: 10px;
  background: #e2d5ff;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}

.productcard .new-text {
  top: 55px;
  background: #000;
  color: #fff;
}

.sorting {
  display: flex;
}

.sorting span {
  margin-left: 6px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
}

#root h4.product-title {
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
  font-size: 18px;
}

.searchBtn {
  background: #ab94e0;
  position: absolute;
  right: 6px;
  // top: 5px;
  border-radius: 100%;
  padding: 5px;
  height: 40px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBtn .icon {
  color: #000;
  font-size: 16px;
}

a.navbar-brand img {
  width: 200px;
}

.flex-filters {
  display: flex;
}

.HomeNamv.navbar-collapse {
  justify-content: space-between;
}

.flex-filters .navbar-nav {
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

// delete product card styling

.remove-modal .card,
.remove-modal .card .card-header,
.remove-modal .card .card-body,
.remove-modal .card .card-footer {
  border-radius: 10px;
  background: #e2d5ff;
  padding: 22px 0px;
}

.remove-modal h2.card-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.remove-modal .card .card-header,
.remove-modal .card .card-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 30px 0px;
}

.remove-modal p.card-text,
.remove-modal h2.card-title {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 20px;
}

.remove-modal .btn-confirm {
  border-radius: 8px;
  background: #1e1d1e;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 15px 50px;
}

.card .card-check {
  margin: 0 auto;
  width: 30px;
  accent-color: #000000;
}

//  product details page

img.gallary-img,
.left-img {
  width: 100%;
  position: relative;
}

.left-gallary {
  height: 15.6rem;
}

.big-img {
  height: 32rem;
}

.gallar {
  display: flex;
  column-gap: 10px;
}

.right.img {
  display: grid;
  width: -100%;
  grid-template-columns: auto auto;
  width: 100%;
  column-gap: 10px;
  row-gap: 6px;
}

section.sale {
  margin-top: 30px;
}

span.sale-text {
  border-radius: 5px;
  background: #e2d5ff;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 3px 15px;
  text-transform: uppercase;
}

#sale .price-details h1 {
  color: #000;
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
}

.address span:first-child {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  display: block;
}

.address span:last-child {
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.action {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

img.download-img {
  width: 46px;
}

.spancircle {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #fff;
  border-radius: 50px;
  font-size: 20px;
  display: inline-flex;
  height: 38px;
  width: 38px;
  justify-content: center;
  align-items: center;
}

section.productdetail {
  background: #ebe2ff;
  height: 201px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 30px;
}

.property-fecility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
}

span.facility-similar-style {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 2px;
}

.bed,
.garage,
.bath,
.dimention,
.garage,
.wrench {
  display: flex;
  align-items: end;
}

.property-overview {
  margin-bottom: 100px;
}

#property-overview .property-overview-heading {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
}

#property-overview .property-overview-paragraph {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.square {
  display: flex;
  height: 279px;
  border-radius: 18px;
  border: 1.5px solid #0f0f0f;
  background: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.square:hover {
  background-color: #000;
}

#property-overview .square:hover span,
#property-overview .square:hover span a,
#property-overview .square:hover span p,
#property-overview .square:hover span h6 {
  color: #fff;
}

#property-overview .square:hover img {
  filter: invert(98%) sepia(2%) saturate(0%) hue-rotate(129deg) brightness(102%) contrast(102%);
}

.property-overview strong,
h3 {
  color: #000;
}

#accordion-main .accordion-item {
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 20px 0px;
  border-color: #000;
  border-bottom: 1px solid #000;
}

#accordion-main .accordion-button:focus {
  box-shadow: none;
}

#accordion-main .collapse:not(.show) .accordion-item {
  background-color: #ab94e0;
}

#accordion-main button.accordion-button {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
}

#accordion-main .accordion-button:not(.collapsed) {
  box-shadow: none;
}

#accordion-main .accordion-button::after {
  content: "\f078";
  font-family: "FontAwesome";
  float: inline-end;
  transition: all 0.3s ease-in;
}

#accordion-main .accordion-button.collapsed::after {
  transform: rotate(180deg);
  transition: all 0.3s ease-in;
}

.image-sm {
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

section.property-overview ul li {
  font-size: 16px;
  color: #000;
}

section.property-overview table {
  text-wrap: nowrap;
}

section.property-overview .table tr {
  font-size: 16px;
}

.key-information-table .table> :not(caption)>*>*,
.prophist .table> :not(caption)>*>* {
  box-shadow: none;
}

.key-information-table .table td,
.key-information-table .table th,
.prophist .table td,
.prophist .table th {
  padding-left: 20px;
}

.square span a {
  color: #000;
  text-decoration: underline;
  font-size: 16px;
}

#property-overview .square h6 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
}

#accordion-main .table-striped tbody tr:nth-of-type(odd) {
  background: #ebe2ff;
}

#accordion-main .table> :not(:last-child)> :last-child>* {
  border-bottom: none;
  border-top: none;
}

.siderow {
  padding: 0px 60px;
}

.scheduleTour {
  background: rgba(120, 119, 120, 0.05);
  padding: 30px 60px;
}

.scheduled-square {
  height: 170px;
}

.btnSchedule {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  border-radius: 24px;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 6px 6px 60px 0px rgba(0, 0, 0, 0.04);
  padding: 5px 80px;
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  flex-shrink: 0;
  white-space: nowrap;
}

.btnSchedule:hover {
  border: 1px solid #000;
  box-shadow: 0px 4px 4px 0px #ab94e0;
  flex-shrink: 0;
}

.btnSchedule:active:focus {
  background: #1e1d1e;
  color: #ab94e0;
}

// scheduled tour section

.accordion-button:not(.collapsed)::after {
  filter: invert(0%) sepia(58%) saturate(0%) hue-rotate(15deg) brightness(70%) contrast(165%);
}

.scheduled-square section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.scheduled-square .tile {
  height: 170px;
  width: 100%;
  position: relative;
  border-radius: 18px;
  outline: none;
  box-shadow: 15px 15px 25px rgba(2, 28, 53, 0.05);
  border: 1.5px solid #ab94e0;
}

#scheduled-square h6 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}

#scheduled-square h4 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.scheduled-square input[type="checkbox"] {
  -webkit-appearance: none;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 18px;
  outline: none;
}

.scheduled-square input[type="checkbox"]:after {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f111";
  font-size: 22px;
  color: #e2e6f3;
}

.scheduled-square input[type="checkbox"]:checked .scheduled-square .tile {
  border: 1.5px solid #1e1d1e;
  background: #ebe2ff;
}

.scheduled-square input[type="checkbox"]:checked:after {
  font-weight: 900;
  content: "\f058";
  border: none;
  color: #000;
}

.scheduled-square label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.scheduled-square label .fas {
  font-size: 60px;
  color: #2c2c51;
}

.scheduled-square input[type="checkbox"]:checked+label .fas {
  animation: grow 0.5s;
}

@keyframes grow {
  50% {
    font-size: 80px;
  }
}

.scheduled-square label h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #7b7b93;
}

.scheduled-square a {
  font-family: "Poppins", sans-serif;
  width: 350px;
  background-color: #2c2c51;
  color: #ffffff;
  padding: 15px 0;
  margin: 30px auto;
  position: relative;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
}

.checkbox-alias {
  display: flex;
  height: 116px;
  border-radius: 10px;
  border: 1.5px solid rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: relative;
  transition: all 250ms ease-out;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}

.checkbox-alias span {
  display: block;
}

.calande-week span:nth-child(1),
.calande-week span:nth-child(3) {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.calande-week span:nth-child(2) {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.invisible-checkboxes input[type="checkbox"] {
  display: none;
}

.invisible-checkboxes input[type="checkbox"]:checked+.checkbox-alias {
  background-color: #ebe2ff;
  border: 1.5px solid #000;
}

.invisible-checkboxes input[type="checkbox"]:checked+.checkbox-alias span {
  color: #000;
}

.paymentgraph {
  background: #ebe2ff;
}

img.chartimg {
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.pricehead {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.chartcalc ul {
  display: block;
  margin: 0 auto;
  font-size: 16px;
}

.chartcalc li {
  list-style-type: none;
  position: relative;
  border-bottom: 1px solid #ab94e0;
  padding: 14px 0px;
  color: #000;
}

.chartcalc li:before {
  top: 18px;
  content: " ";
  background: #ab94e0;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  left: -1.5em;
}

.payment-price strong {
  color: #000;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

span.payment-price {
  margin-left: 130px;
}

.chartcalc li:nth-child(3n + 2):before {
  background: #3b0082;
}

.chartcalc li:nth-child(3n + 3):before {
  background: rgba(0, 0, 0, 0.3);
}

.chartcalc li:nth-child(3n + 4):before {
  background: #000;
}

// pricedivright
.pricediv {
  border-radius: 18px;
  border: 1.5px solid #000;
  background: #fff;

  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricediv span {
  display: block;
  color: #000;
  font-style: normal;
}

.pricediv span a {
  text-decoration: underline;
  color: #000;
  margin-right: 10px;
}

.mydiv span:nth-child(1),
.mydiv span:nth-child(3) {
  font-size: 16px;
  font-weight: 500;
}

.mydiv span:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
}

.pricesection {
  margin-top: 50px;
}

.duesection {
  margin-top: 30px;
}

.duesection span {
  display: block;
  color: #000;
  font-style: normal;
}

.duesection span:nth-child(1) {
  font-size: 20px;
  font-weight: 700;
}

.duesection span:nth-child(2) {
  font-size: 24px;
  font-weight: 600;
}

.duesection span:nth-child(3) {
  font-size: 16px;
  font-weight: 400;
}

.duesection span:nth-child(4) {
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
}

.estimatedvalue .chartcalc ul li:before {
  background: none;
}

.estimatedvalue .chartcalc ul {
  padding-left: 0rem;
  padding-bottom: 50px;
}

.prophist {
  margin-top: 50px;
}

.prophist span {
  display: block;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}

.prophist span:nth-child(5) {
  font-weight: 600;
  margin: 15px 0px;
  font-size: 20px;
}

.prophist span:nth-child(6) {
  font-size: 20px;
  font-weight: 600;
}

.prophist .table th {
  border-top: 0;
  border-bottom: 0;
}

.prophist .table th,
.prophist .table td {
  padding-left: 18px;
}

.prophist .table-striped tbody tr:nth-of-type(odd) {
  background: #ebe2ff;
}

.prophist.table>thead,
.key-information-table .table>thead {
  color: #000;
}

// attractiondiv styling

.attractiondiv {
  height: 279px;
  flex-shrink: 0;
  border-radius: 18px;
  border: 1.5px solid #0f0f0f;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.attractiondiv span {
  display: block;
  color: #000;
}

.attractiondiv span:nth-child(2),
.attractiondiv span:nth-child(3) {
  font-size: 20px;
  font-weight: 600;
}

.attractiondiv span:nth-child(2) {
  margin-top: 20px;
}

.attractiondiv span a {
  color: #000;
  text-decoration: underline;
}

.attractiondiv span:nth-child(3) {
  margin-top: 30px;
}

// Multirange slider

.price-slider {
  position: absolute;
  width: 100%;
}

.price-range-main {
  margin-bottom: 40px;
}

.price-slider .slider {
  position: relative;
  width: 96%;
  background-color: transparent;
}

.price-slider .slider__track,
.price-slider .slider__range,
.price-slider .slider__left-value,
.price-slider .slider__right-value {
  position: absolute;
}

.price-slider .slider__track,
.price-slider .slider__range {
  border-radius: 3px;
  height: 5px;
}

.price-slider .slider__track {
  background-color: #ebe2ff;
  width: 100%;
  z-index: 1;
}

.price-slider .slider__range {
  background-color: #000;
  z-index: 2;
}

.price-slider .slider__left-value,
.price-slider .slider__right-value {
  color: #000000;
  font-size: 12px;
  margin-top: 20px;
  font-weight: 600;
}

.price-slider .slider__left-value {
  left: 6px;
}

.price-slider .slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.price-slider .thumb,
.price-slider .thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ab94e0 !important;
  outline: 2px solid #000;
}

.price-slider .thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 96%;
  outline: none;
}

.price-slider .thumb--left {
  z-index: 3;
}

.price-slider .thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.price-slider .thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.price-slider .thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

// upcomings styling

.siderow.upcomings {
  background: rgba(235, 226, 255, 0.5);
}

.upcomingcalender {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

img.karie {
  width: 100%;
  border-radius: 8px;
}

.contactbtn {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.agentname {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.similarproduct .productcard {
  margin-right: 15px;
}

.similarproduct span.wishlist {
  height: 24px;
  width: 24px;
}

.similarproduct .wishlist-icon {
  font-size: 16px;
}

// footer styling

footer.footer {
  background: #e2d5ff;
  border-top: 1px solid #e2d5ff;
  // position: fixed;
  // bottom: -10px;
  right: 0;
  width: 100%;
}

.footer-right {
  margin-left: 120px;
}

.social-icon-main {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
}

span.social-icon {
  background: #000;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ab94e0;
  border-radius: 50%;
}

.social-icon-main a:hover {
  text-decoration: none;
}

span.social-icon:hover {
  background-color: #cbb9ac;
  color: #000;
}

span.social-icon i {
  font-size: 20px;
}

.right-border {
  border-right: 1px solid #000;
}

.call-us,
.request-footer,
.text-us {
  padding: 15px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #000;
  font-weight: 600;
}

.call-us,
.text-us {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.22);
  white-space: nowrap;
}

button.btn.footer-btn {
  border: 1px solid #1e1d1e;
  background: #ab94e0;
  border-radius: 50px;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
  padding: 3px 90px;
  white-space: nowrap;
}

.profile-dropdown {
  display: inline-block;
  position: relative;
  margin: auto;
  font-weight: bold;
  font-size: 1.3rem;
  border-radius: 3px;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
  cursor: pointer;
}

.profile-dropdown * {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
}

.profile-dropdown input[type="checkbox"] {
  display: none;
}

.profile-dropdown input[type="checkbox"]:checked~ul {
  display: block;
  animation: pulse 0.5s;
}

.profile-dropdown input[type="checkbox"]:checked~img {
  background: #ebe2ff;
}

.profile-dropdown img {
  display: inline-block;
  vertical-align: middle;
  margin: 0rem 0rem 0.1rem 0rem;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  background: #ebe2ff;
}

.profile-dropdown span {
  display: inline-block;
  vertical-align: sub;
  width: 125px;
  margin-right: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile-dropdown ul {
  // display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 109%;
  right: 0;
  width: 100%;
  min-width: 426px;
  border-radius: 30px;
  border: 1.5px solid #eee;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
}

.profile-dropdown ul li a {
  display: flex;
  padding: 0.95rem 2rem;
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}

.profile-dropdown ul li a i {
  font-size: 1.3rem;
  vertical-align: middle;
  margin: 0 0.75rem 0 -0.25rem;
}

.profile-dropdown ul li a:hover {
  background: #ebe2ff;
  color: #000;
}

.profile-dropdown ul li:first-child a:hover {
  border-radius: 30px 30px 0 0;
}

.profile-dropdown ul li:last-child a:hover {
  border-radius: 30px;
}

.profile-dropdown:after {
  content: "";
  display: table;
  clear: both;
}

.table td,
.table th {
  padding: 0.7rem 1.6rem;
  text-transform: inherit;
}

.table td:nth-child(1),
.table td:nth-child(2),
.table th:nth-child(1),
.table th:nth-child(2) {
  padding: 0.75rem;
}

// profile page styling
#profile2.nav-link:focus,
#profile2.nav-link:hover {
  background: #ebe2ff;
  color: #000;
}

section.profile {
  background: #ebe2ff;
  color: #000;
  padding: 30px 150px;
}

.profile-container {
  border-radius: 10px;
  background: #fff;
  padding: 50px 40px;
  margin-bottom: 60px;
}

h1.profiletitle {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

img.profileimg {
  max-width: 100%;
  width: 120px;
  border-radius: 100px;
  margin-top: 40px;
  margin-bottom: 10px;
}

#profile a.changeimagelink {
  display: block;
  color: #000;
  text-decoration: underline;
  font-weight: 700;
}

#profile .profilename {
  color: #000;
  font-size: 70px;
  font-weight: 600;
  margin-top: 50px;
}

.profileDetails {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
}

.profiledetailsicon {
  font-size: 20px;
  color: #000;
}

#root p.details-para {
  display: inline-block;
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
}

#profile h1.contactinformationheading {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.sign-out-btn {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 6px 40px;
  margin: 0 auto;
  width: 250px;
  margin-bottom: 18px;
  justify-content: center;
}

.contactinformation {
  margin: 40px 0px;
}

.socail-left-sec {
  display: grid;
  gap: 30px;
}

.social-rightsec {
  display: grid;
  gap: 42px;
}

button.btn.edit-btn {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 50px;
  background: #fff;
  padding: 10px 50px;
  margin-top: 20px;
}

.contact-sec img {
  width: 20px;
}

.contact-sec {
  display: grid;
  gap: 25px;
}

#profile .details-sec a {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.paired-btn {
  display: block;
  border-radius: 50px;
  border: 1px solid #000;
  background: #e2d5ff;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 30px;
  margin-top: 30px;
}

.btn-flex {
  display: flex;
  align-items: flex-end;
}

.delete-btn {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

.save-btn,
.save-btn:hover {
  border-radius: 50px;
  border: 1px solid #000;
  background: #1e1d1e;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 73px;
  display: inline-flex;
  margin: 0 auto;
}

#favourite h1.favourite-title {
  color: #000;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}

.profile-edit-popup .modal-header {
  display: block;
  border: none;
  padding-bottom: 0;
  padding: 15px 40px;
  background: #ebe2ff;
}

.profile-edit-popup .modal-body {
  background: #ebe2ff;
  padding: 30px 40px;
}

h1.profile-edit-title {
  color: #000;
  font-size: 40px;
  font-weight: 600;
}

.edit-contact-form {
  margin-top: 20px;
  padding-bottom: 30px;
}

.edit-contact-form label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.edit-contact-form input {
  border-radius: 12px;
  border: 1px solid #1e1d1e;
  background: #fff;
  height: 48px;
}

.delete-contect-btn {
  display: block;
  text-decoration: underline;
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.edit-contact-dropdown,
.edit-contact-dropdown:hover {
  border-radius: 12px;
  border: 1px solid #1e1d1e;
  background: #fff;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#edit-contact-form .btn-secondary:focus,
.btn-secondary.focus {
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(212, 136, 72, 0.25);
}

// savedlist styling
img.img-fluid.save-left-product {
  width: 100%;
  height: 272px;
  border-radius: 12px 0px 0px 12px;
}

.product-right img {
  height: 132px;
  width: 100%;
}

img.img-fluid.save-right-product:first-child {
  border-radius: 0px 12px 0px 0px;
}

img.img-fluid.save-right-product:last-child {
  margin-top: 8px;
  border-radius: 0px;
}

.card .savedlist-title {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

button.productlistPrev,
button.productlistNext {
  border: 1px solid #ebe2ff;
  border-radius: 3px;
  padding: 4px 20px;
  background: #ebe2ff;
  color: #000;
}

button.productlistPrev:disabled,
button.productlistNext:disabled {
  border: 1px solid #efefef;
  border-radius: 3px;
  padding: 4px 20px;
  background: rgba(235, 226, 255, 0.26);
  color: #000;
}

button.productlistPrev {
  margin-right: 15px;
}

button.productlistNext {
  margin-left: 15px;
}

.productpagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.favourite {
  margin-top: 50px;
}

.dislike-right {
  position: absolute;
  display: flex;
  height: 34px;
  width: 34px;
  top: 10px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  right: 10px;
  background-color: #000;
  color: #fff;
}

.dislike-btn {
  height: 38px;
  width: 38px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ab94e0;
  color: #000;
}

input.save-input {
  border-radius: 5px;
  border: 1px solid #000;
  height: 36px;
  width: 60%;
  padding: 10px;
}

.searchform ul {
  position: absolute;
  top: 51px;
  left: 0;
  z-index: 6;
  width: 100%;
  padding: 22px;
  max-height: 300px;
  height: auto;
  border-radius: 10px;
  overflow-y: auto;
}

.searchform ul li {
  list-style: none;
  margin-bottom: 5px;
  cursor: pointer;
}

.copyright {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 18px;
}

// Account Styling
section.account,
.custom-bg {
  background: #ebe2ff;
  padding-top: 40px;
  padding-bottom: 80px;
}

#root section.account .card {
  background: #fff;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  height: 229px;
  cursor: pointer;
}

#root section.account .card:hover {
  background: #1e1d1e;
}

#root section.account .card:hover h1.card-heading,
#root section.account .card:hover p.card-para,
#root section.account .card:hover .right-arrow {
  color: #fff;
}

#root section.account .card:hover img {
  filter: invert(95%) sepia(5%) saturate(0%) hue-rotate(22deg) brightness(106%) contrast(105%);
}

#root section.account .card:focus {
  background: #ab94e0;
}

#root section.account .card .card-body {
  padding: 36px 30px;
}

#root .main-heading {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
}

#root section.account .card .card-body .card-heading {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

#root section.account .card .card-body .card-para {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.nav-link-card,
.nav-link-card:hover {
  text-decoration: none;
}

#root section.account-setting .card {
  height: auto;
}

.notifaction-card {
  display: flex;
  justify-content: space-between;
}

.arrow-btn {
  border: 1.8px solid #eeeeee;
  border-radius: 8px;
  font-size: 20px;
  padding: 0px 24px;
  color: #000;
}

button.btn.delete-account-btn {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid #000;
  background: #e2d5ff;
  padding: 12px 20px;
  margin: 70px 0px;
}

#root h1.notifaction-heading {
  font-size: 20px;
  color: #000;
  margin: 0px 23px;
  font-weight: 700;
}

.btn-fill,
.btn-fill:hover {
  border-radius: 8px;
  border: 1px solid #000;
  background: #1e1d1e;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 50px;
}

.btn-border,
.btn-border:hover {
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 50px;
}

#root .savedlist {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.save-btn-border,
.save-btn-border:hover {
  border-radius: 50px;
  border: 1px solid #000;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 70px;
  margin: 0 auto;
}

#root .notifaction-modal .modal-header,
#root .notifaction-modal .modal-body {
  padding: 15px 30px;
}

#root .notifaction-modal .modal-footer {
  padding: 25px 20px;
}

#root .notifaction-modal .modal-header {
  align-items: center;
}

#root .modal .btn-close {
  position: absolute;
  right: 30px;
}

#root .modal .modal-title {
  margin: 0 auto;
}

form.login-form {
  width: 300px;
}

form.login-form .form-control {
  padding: 22px 45px;
  border-radius: 12px;
  border: 1px solid #1e1d1e;
  background: #fff;
  margin-bottom: 40px;
}

.form-label {
  color: #000;
}

.facebook-connection {
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  padding-right: 50px;
}

.facebook-connection span,
.google-connection span {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.fa-icon {
  position: absolute;
  top: 60px;
  left: 15px;
  color: #bbbbbb;
}

#root .paired-modal .modal-body {
  height: 60vh;
}

p.paired-para {
  margin-left: 46px;
  margin-top: 12px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

button.btn.btn-paired {
  border-radius: 8px;
  border: 1px solid #000;
  background: #e2d5ff;
  font-weight: 500;
  color: #000;
  padding: 14px 30px;
  font-size: 16px;
}

.container-fluid.team-container {
  padding: 0 230px;
}

// section.team {
//     height: calc(100vh - 100px);
// }

.team-card .card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  padding: 15px;
}

img.team-img {
  width: 100%;
  max-width: 136px;
  min-width: 60px;
  border-radius: 100%;
}

.team-image {
  position: relative;
}

.team-left {}

img.lion-icon {
  max-width: 40px;
  position: absolute;
  right: -12px;
  top: -50px;
  z-index: 9;
}

.team-right {
  margin-top: 20px;
}

#root .team-right span :nth-child(1) {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  display: block;
  text-decoration: underline;
  margin-bottom: 15px;
}

.team-right span:nth-child(2) {
  color: #000;
  font-size: 15px;
  font-weight: 700;
  display: block;
}

#root button.btn.team-btn {
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  width: 46%;
  height: 60px;
  margin-top: 20px;
}

.profile-menu {
  border: 1px solid #000;
  padding: 6px 14px;
  border-radius: 50px;
}

span.line2 {
  height: 19px;
  width: 1px;
  background: #000;
  margin-top: 8px;
}

span.line {
  height: 25px;
  width: 1px;
  background: #000;
}

#root .reset-bg .card {
  margin-top: 50px;
}

#reset h1.reset-heading {
  font-size: 38px;
  color: #000;
  font-weight: 600;
  margin-top: 50px;
  margin-left: 50px;
}

.HomeNamv .navbar {
  padding: 0.5rem 0rem;
}

.filter-container {
  background: #ebe2ff;
  padding: 30px 0px;
  margin-bottom: 30px;
  z-index: 9;
}

.dropdown.filterdropdown {
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  padding: 6px 3px;
  margin-right: 16px;
}

.filterdropdown .dropdown-toggle {
  color: #000;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobileview {
  display: none !important;
}

.clearall {
  font-weight: 500;
  color: #000;
}

#root .view-type-nav ul li a {
  font-size: 14px;
  font-weight: 400;
}

#root .view-type-nav ul li a.active {
  font-weight: 600;
}

#root .dislike-modal .modal .btn-close {
  position: initial;
  display: flex;
}

#root .dislike-modal .modal .modal-header {
  display: block;
  padding-top: 30px;
  border-color: rgba(0, 0, 0, 0.15);
}

#root .dislike-modal .modal .modal-content {
  background-color: #e2d5ff;
}

#root .dislike-modal .modal .modal-header h3 {
  text-align: center;
  color: #000;
  font-weight: 500;
}

#root .dislike-modal .modal .modal-header p {
  width: 230px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
}

#root .dislike-modal .modal .modal-body .remove-session {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto;
  text-align: center;
}

#root .dislike-modal .modal .modal-body .form-check {
  margin: 0 auto;
  text-align: center;
}

#root .dislike-modal .modal .modal-body .form-check-input:checked[type="checkbox"] {
  padding: 18px;
  border-radius: 8px;
  margin-top: 10px;
}

#root .dislike-modal .modal .modal-body .form-check-input:checked {
  background-color: #000;
  border-color: #000;
}

#root .dislike-modal .modal .modal-body {
  height: 110px;
}

#root .dislike-modal .modal .modal-footer {
  padding: 30px 15px;
  border-color: rgba(0, 0, 0, 0.15);
}

// 06/10/23 styling
#root .filter-heading {
  color: #000;
  font-size: 20px;
  font-weight: 800;
}

.dropdown.filterdropdown .filterdropdown-menu {
  padding: 40px 0 20px 0;
  width: auto;
  border-radius: 30px;
  border: 1.5px solid #eee;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  margin: 10px 0 !important;
}

span.rectangle {
  display: flex;
  width: 140px;
  height: 126px;
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

span.rectangle:hover {
  background-color: #000;
  color: #fff;
}

span.rectangle:hover span {
  color: #fff;
}

span.rectangle:hover img {
  filter: invert(96%) sepia(97%) saturate(12%) hue-rotate(237deg) brightness(103%) contrast(200%);
}

img.custome-color.buildingsvg {
  filter: invert(0%) sepia(91%) saturate(0%) hue-rotate(250deg) brightness(4%) contrast(100%);
}

span.rectangle:hover img.custome-color.buildingsvg {
  filter: invert(8%) sepia(100%) saturate(119%) hue-rotate(237deg) brightness(401%) contrast(100%);
}

.rectangle span:last-child {
  display: block;
  margin-top: 10px;
  color: #000;
  font-weight: 500;
}

button.btn.btn-done {
  border-radius: 50px;
  border: 1px solid #000;
  padding: 15px 60px;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.bottom-line {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px !important;
}

.custome-color {
  filter: invert(0%) sepia(91%) saturate(0%) hue-rotate(250deg) brightness(50%) contrast(200%);
}

.dropdown.filterdropdown ul .btn-group {
  width: 500px;
}

.dropdown.filterdropdown ul .btn-check:checked+.btn-outline-primary {
  background-color: #000;
  border-color: #0000;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px 0px 0px 8px;
}

.dropdown.filterdropdown ul .btn-outline-primary {
  border-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.dropdown.filterdropdown .btn-outline-primary:hover {
  background-color: #000;
  color: #fff;
}

.price-dropdown {
  width: 100%;
}

.price-dropdown .btn:focus {
  box-shadow: none;
}

.btn-number {
  display: flex;
  width: 50px;
  height: 32px;
  padding: 4px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 69px;
  border: 1px solid #000;
  color: #000;
}

.btn-number:hover {
  background-color: #000;
  color: #fff;
}

.any {
  background-color: #000;
  color: #fff;
}

.tempany {
  background-color: #000;
  color: #fff;
  padding: 10px 57px;
}

.anystyle {
  padding: 10px 57px;
}

span.street-name {
  font-size: 19px;
  font-weight: 600;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 210px;
  display: inline-block;
  margin-bottom: -8px;
}

.truncate-line {
  font-size: 16px;
  font-weight: 500;
}

.show {
  display: block;
}

button.btn.btn-number.active {
  background: #000;
  color: #fff;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}
ul.search-dropdown.width-inherit{
  width: auto !important;
}
ul.search-dropdown {
  width: 586px;
  position: absolute;
  z-index: 5;
  height: auto;
  max-height: 200px;
  background: #fdfdfd;
  overflow-y: auto;
  // width: 100%;
  padding: 10px 0px;
  border-radius: 5px;
  border: 1px solid #efefef;
  line-height: 30px;
}

ul.search-dropdown li {
  list-style: none;
  padding: 2px;
  cursor: pointer;
  padding: 3px 24px;
  color: #000;
}

ul.search-dropdown li:hover {
  background-color: #000000;
  color: #fff;
}

// Contact Page Css

.signup textarea.form-control {
  height: auto;
  padding: 22px 45px;
  border-radius: 12px;
  border: 1px solid #1e1d1e;
  background: #fff;
}

form.filter-modal-form .form-control {
  height: 48px;
  border-radius: 8px;
}

button.viewallphoto {
  position: absolute;
  z-index: 9;
  bottom: 40px;
  right: 20px;
  border-radius: 5px;
  border: 1px solid #000;
  background: #fff;
  width: 160px;
  height: 42px;
}

.total-result {
  margin-bottom: 25px;
}

.sub-address-inner {
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.7);
}

button.btn.btn-login a {
  color: #fff;
  border-color: #ab94e0 !important;
  text-decoration: none;
}

.check-icon i {
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon i.fa-solid.fa-check {
  border: 1px solid #28a745;
  background: #28a745;
}

.check-icon i.fa-solid.fa-xmark {
  border: 1px solid #dc3545;
  background: #dc3545;
}

.icon-social {
  display: flex;
  gap: 15px;
}

.details-sec input {
  padding: 0 10px;
  width: auto;
  height: 35px;
  border-radius: 6px;
  border: 1px solid #000;
}

.social-info-main input {
  width: 300px;
  margin: 0px 30px;
}

.details-sec {
  display: flex;
  align-items: center;
}

.price-dropdown select.form-control {
  height: 48px;
  min-width: 200px;
  border-radius: 6px;
  border-color: #000;
  color: #000;
}

.monthlypayment {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

span.rectangle.active,
span.rectangle.active span {
  background: #000;
  color: #fff;
}

.nofound_clas {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nofound_clas img {
  height: 55vh;
}

span.rectangle.active img {
  filter: invert(8%) sepia(100%) saturate(119%) hue-rotate(237deg) brightness(401%) contrast(100%);
}

.property-type {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
  margin-top: 20px;
}

.grid-cusom {
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto auto;
  width: 460px;
}

.custom-flex {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.display-flex-bed {
  display: flex;
  gap: 20px;
}

ul.dropdown-menu.filterdropdown-menu li {
  padding: 0 24px;
}

button.btn.btn-custom-style.sell-btn.active,
button.btn.btn-custom-style.buy-sell-btn.active {
  background-color: #000;
  color: #fff;
}

#createnewpass .reset-heading {
  font-size: 38px;
  color: #000;
  font-weight: 600;
  margin-top: 50px;
  margin-left: 50px;
}

.no-image {
  background-color: #e5ecee;
  display: flex;
  justify-content: center;
  align-items: center;
}

// favorite Maodal styling
.modal-favorite .modal-content {
  border-radius: 10px;
}

#root .modal-favorite .btn-close {
  position: initial;
}

.modal-favorite .modal-header {
  border-bottom: none;
}

.modal-favorite .modal-footer {
  border-top: none;
}

button.btn.btn-addto.list,
button.btn.btn-no-thanks {
  display: block;
  border-radius: 50px;
  border: 1px solid #000;
  margin: 0 auto;
  width: 182px;
  height: 60px;
  font-weight: 500;
  white-space: nowrap;
  font-size: 16px;
  margin-bottom: 15px;
}

button.btn.btn-addto.list {
  background: #e2d5ff;
  color: #000;
}

button.btn.btn-no-thanks {
  background: #1e1d1e;
  color: #fff;
}

p.favorite-text {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  width: 214px;
  margin: 0 auto;
}

// savedlist modal styling

button.btn.btn-create-new-list {
  border-radius: 50px;
  border: 1px solid #000;
  background: #fff;
  width: 215px;
  height: 60px;
  font-weight: 500;
  color: #000;
  font-size: 16px;
  margin: 30px 0;
}

span.savelistproduct-text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

#root .SavedListsModal .modal-title {
  font-style: normal;
  font-weight: 500;
  color: #000 !important;
}

// teamprofile styling

section.teamprofile-bg {
  background: #ebe2ff;
  padding-top: 40px;
  padding-bottom: 20px;
}

#root h1.teamprofile-main-heading {
  font-size: 70px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}

section.teamabout {
  margin-top: 45px;
}

.container-fluid.teamprofile-container {
  padding: 0px 160px;
}

#root h1.about-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;
  color: #000;
  font-size: 38px;
  padding-bottom: 20px;
}

.teamprofile-card .card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

.teamprofile-img {
  width: 115px;
  height: 115px;
}

#root p.address {
  display: inline-block;
  color: #000;
}

.teamprofile-icon {
  width: 18px;
}

button.btn.team-btn.message-btn {
  margin: 15px 0px 15px 0px;
}

.about-content,
.review-button,
.teamprofile-contact {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
}

.about-content p {
  width: 80%;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

#root .about-subheading {
  font-size: 24px;
  font-style: normal;
  color: #000;
  font-weight: 700;
}

// rivewcard styling

.card.rivewcard {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  width: 396px;
  height: 345px;
  padding: 50px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

span.star-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 8px;
}

.rivew {
  display: flex;
  margin-right: 20px;
}

.review-button {
  margin-bottom: 30px;
}

.teamprofile-contact {
  margin-bottom: 100px;
}

.contact-btn {
  display: flex;
  margin: 0 auto;
}

#root button.btn.team-btn a {
  color: #000;
  text-decoration: none;
}

#root button.btn.team-btn:hover {
  background: #000;
  color: #fff;
}

button.btn.team-btn:hover a {
  color: #fff;
}

.team .back {
  top: 40px;
}

.team .back:focus,
.team .back:active {
  top: 30px;
}

// Referals Styling

section.account.account-setting.refferal {
  height: calc(100vh - 100px);
}

.referal-card-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.referal-card-img img {
  width: 140px;
}

.referal-card-content h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px;
}

.referal-card-content p {
  width: 320px;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.referal-card-content p:last-child {
  margin-top: 15px;
}

img.arrow-Back {
  width: 24px;
}

.back.profile-arrow-back {
  top: 130px;
}

.back.profile-arrow-back:focus,
.back.profile-arrow-back:active {
  top: 130px;
}

.back {
  position: absolute;
  left: 24px;
  top: 158px;
  cursor: pointer;
}

// .back:focus,
// .back:active {
//   background: #000;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   top: 150px;
//   left: 14px;
//   filter: drop-shadow(0px 4px 4px #ab94e0);
// }

#root .modal-saved h5.modal-title {
  color: #000 !important;
  font-weight: 500;
  font-style: initial;
}

button.btn.btncheck,
button.btn.btn-cross {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

button.btn.btncheck {
  background: #28a745;
}

button.btn.btn-cross {
  background-color: #dc3545;
}

.back:focus img.arrow-Back,
.back:active img.arrow-Back {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.savelistproduct {
  cursor: pointer;
}

#root .ReferalModal .refferal-heading {
  font-size: 36px;
  color: #000;
  font-weight: 700;
}

#root .modal-saved .modal-content {
  height: 88vh;
}

input.savedlist-input {
  width: 220px;
}

.back.singleproduct-back {
  top: 125px;
  z-index: 9;
  left: 57px;
  background: transparent;
  box-shadow: none;
}

.back-btn-txt {
  margin-left: 10px;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
}

.mobileviewedit {
  display: none;
}

// Multirange slider

.price-slider {
  position: absolute;
  width: 100%;
}

.price-range-main {
  margin-bottom: 40px;
}

.price-slider .slider {
  position: relative;
  width: 96%;
  background-color: transparent;
}

.price-slider .slider__track,
.price-slider .slider__range,
.price-slider .slider__left-value,
.price-slider .slider__right-value {
  position: absolute;
}

.price-slider .slider__track,
.price-slider .slider__range {
  border-radius: 3px;
  height: 5px;
}

.price-slider .slider__track {
  background-color: #ebe2ff;
  width: 100%;
  z-index: 1;
}

.price-slider .slider__range {
  background-color: #000;
  z-index: 2;
}

.price-slider .slider__left-value,
.price-slider .slider__right-value {
  color: #000000;
  font-size: 12px;
  margin-top: 20px;
  font-weight: 600;
}

.price-slider .slider__left-value {
  left: 6px;
}

.price-slider .slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.price-slider .thumb,
.price-slider .thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ab94e0 !important;
  outline: 2px solid #000;
}

.price-slider .thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 96%;
  outline: none;
}

.price-slider .thumb--left {
  z-index: 3;
}

.price-slider .thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.price-slider .thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.price-slider .thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

// .stageinput tr:first-child {
//   border-bottom: 1px solid #d8dbe0;
// }
.tabbtn {
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 18px;
  padding: 30px;
}

.tabbtn.active {
  border-bottom: 9px solid #000;
  position: relative;
  top: 4px;
}

.tabmain {
  display: flex;
  align-items: center;
  gap: 40px;
  border-bottom: 1px solid #000;
}

.myreffral-table {
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 860px;
}

.myreffral-table td:first-child {
  color: #000;
  font-size: 24px;
  font-weight: 400;
}

.myreffral-table td:last-child {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.myreffral-table tr {
  background-color: #fff;
}

#root .table-heading {
  text-align: center;
  color: #000;
  margin: 0 auto;
  font-size: 20px;
  margin-top: 50px;
}

.points-section {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 50px;
}

.points-dark-text {
  color: #000;
  font-weight: 600;
  font-size: 24px;
  display: block;
}

.points-section p {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.ready-to-use {
  margin: 70px 0;
}

.ready-to-use-txt {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
}

.card.referal-card {
  padding: 45px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 750px;
  border-radius: 10px;
  background: #ebe2ff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
}

#root .card.referal-card h4.card-title {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

#root .card.referal-card p.card-text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.reffral-bg {
  background: #ebe2ff;
  padding-top: 40px;
  padding-bottom: 80px;
  height: calc(100vh - 100px);
}

a.btn.btn-primary.btn-reffral {
  border-radius: 50px;
  border: 1px solid #000;
  background: #1e1d1e;
  width: 213px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.profile-container.message-container {
  position: relative;
}

.vl {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
  position: absolute;
  left: 42%;
  margin-left: -3px;
  top: 0;
}

.container-fluid.message-container-main {
  width: 1300px;
}

section.profile.message {
  height: calc(100vh - 100px);
}

.message-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.btn.message-btn {
  border-radius: 8px;
  border: 1px solid #000;
  background: #1e1d1e;
  color: #fff;
  width: 180px;
  height: 58px;
  font-weight: 500;
  font-size: 16px;
}

#root .message-heading {
  color: #000;
  font-size: 40px;
  font-weight: 600;
}

.search-input-field {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 20px;
  top: 50px;
}

input.message-search-input {
  width: 100%;
  border-radius: 50px;
  padding: 15px 40px;
  outline: none;
  border: 1px solid #1e1d1e;
  margin: 30px 0;
  font-size: 16px;
}

.userprofileimg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-table .table th,
.message-table .table td {
  padding: 10px;
}

.chat-profile {
  display: block;
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

h1.message-right-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

table.table.message-table td,
table.table.message-table th {
  border-top: none;
}

span.userprofileimg {
  position: relative;
}

span.circle-active {
  position: absolute;
  height: 16px;
  width: 16px;
  background: #04e000;
  border-radius: 50%;
  right: -5px;
  top: -16px;
  border: 3px solid #fff;
}

span.circle {
  width: 23px;
  height: 23px;
  position: absolute;
  background: #ab94e0;
  top: 4px;
  left: 61px;
  border-radius: 50%;
  border: 2px solid#000;
}

.relative {
  position: relative;
}

input.sendtoinput {
  width: 100%;
  padding: 8px 15px;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.hl {
  position: absolute;
  width: 58%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  top: 94px;
  right: 0;
}

.hltop {
  width: 58%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  top: 40px;
}

img.groupchat:nth-child(3) {
  position: absolute;
  left: 16px;
  top: 8px;
}

// chatApp styleng starts

.app {
  margin-top: 60px;
}

.app .contact-list {
  background-color: white;
  width: 200px;
  overflow: auto;
}

.app .contact-list .title {
  padding: 12px;
  margin: 0;
  padding-bottom: 0;
  font-size: 150%;
}

.app .contact-list ul {
  list-style: none;
  margin: 0;
  padding: 12px;
}

.app .contact-list ul li {
  margin: 8px 0;
  padding: 8px 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.app .contact-list ul li.active,
.app .contact-list ul li:hover {
  background-color: #e1f0fa;
  color: #3498db;
  border-radius: 6px;
}

.app .messages {
  margin: 12px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.app .messages .messages-history {
  overflow: auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
  height: 420px;
}

.app .messages .messages-history .message {
  width: 100%;
  padding: 12px;
  padding-top: 0;
}

.app .messages .messages-history .message:last-child {
  padding-top: 12px;
}

.app .messages .messages-history .message.me {
  text-align: right;
}

.app .messages .messages-history .message.me .message-body {
  color: #000;
  background-color: #e2d5ff;
  border-radius: 16px 0px 16px 16px;
}

.app .messages .messages-history .message .message-body {
  background-color: #f3f3f3;
  color: #333;
  font-weight: 400;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 6px;
  max-width: 400px;
  min-width: 75px;
  line-height: 20px;
  text-align: initial;
  border-radius: 0px 16px 16px 16px;
}

.app .messages .messages-inputs {
  position: relative;
  display: flex;
  margin-top: 40px;
}

.app .messages .messages-inputs input[type="text"] {
  background-color: transparent;
  border: 1px solid #000;
  padding: 18px 15px;
  resize: vertical;
  flex: 1 1 auto;
  border-radius: 50px;
}

.app .messages .messages-inputs button {
  padding: 0 12px;
  background-color: #ab94e0;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 8px;
  border-radius: 50%;
  height: 44px;
  width: 44px;
}

.app .messages .messages-inputs button:hover i {
  color: #3498db;
}

.app .messages .messages-inputs button i {
  vertical-align: middle;
  transition: all 0.2s ease-out;
}

.header-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  top: 130px;
  position: absolute;
  width: 58.2%;
  right: 0;
}

#root .chat-header-heading {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.profile-flex {
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.cenetr {
  display: flex;
  align-items: center;
}

.circle-icon {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
}

// Dashboard Main

.UnclaimedLeads-card span,
#root .UnclaimedLeads-card .card-title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

#root .card.unclaimedcardhover:hover {
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px #ab94e0;
}

#root .UnclaimedLeads-card .card-title {
  font-size: 20px;
}

#root .card.UnclaimedLeads-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
}

#root .card.UnclaimedLeads-card .card-body {
  padding: 20px;
}

.card.UnclaimedLeads-card p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

#root .unclaimedleads {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.dashboardcard {
  padding: 20px;
  border-radius: 12px;
}

button.btn.btn-claim {
  border-radius: 8px;
  border: 1px solid #000;
  background: #1e1d1e;
  // width: 138px;
  padding: 10px 40px;
  height: 40px;
  color: #fff;
  margin-top: 20px;
}

#root button.btn.btn-claim:hover,
#root button.btn.btn-work:hover {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  transition: none;
}

.card-arrow img {
  height: 32px;
  width: 32px;
}

.card-arrow img:hover {
  background: #fff;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  padding: 7px;
  filter: drop-shadow(0px -5px 2px rgba(0, 0, 0, 0.25));
}

.btn-work,
.btn-work:hover {
  width: 100%;
  border: 1px solid #000;
  background: #1e1d1e;
  color: #fff;
  padding: 10px 0;
}

.card-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  rotate: 180deg;
}

.daily-report {
  border-right: 1px solid #000;
}

.report-custom {
  margin-top: 20px;
}

#root h1.report-heading {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}

.card.reportcard {
  padding: 20px 0;
  border-radius: 12px;
  height: 370px;
}

span.aquare {
  width: 16px;
  height: 16px;
  display: block;
  border: 1px solid #000;
}

.purple {
  background-color: #ab94e0;
}

.cream {
  background-color: #e5dcd5;
}

.brown {
  background-color: #99784a;
}

.report-indicator {
  position: absolute;
  bottom: -10px;
  right: 80px;
}

#root button.btn.btn-claim.btn-call,
#root button.btn.btn-call {
  width: auto;
  margin: 0;
  background: transparent;
  color: #000;
  border: 1px solid #000;
  box-shadow: none;
  border-radius: 8px;
  font-weight: 600;
  padding: 10px 20px;
  text-wrap: nowrap;
}

#root button.btn.btn-call:hover {
  background-color: #000;
  color: #fff;
}

.card.dashboardcard.workflowcard {
  background: #e2d5ff;
}

#root .realshion h1 {
  font-size: 14px;
  font-weight: 500;
  color: #4f5d73;
}

#root .assignment-modal .modal-body,
#modal-root .assignment-modal .modal-body {
  overflow: initial;
}

button.btn-close:after {
  content: "\2716";
}

button.btn-close {
  border: none;
  background: transparent;
}

.custom-select-caret .form-multi-select-with-cleaner>div[aria-expanded="false"],
.form-multi-select-with-cleaner>div[aria-expanded="true"] {
  max-height: 120px;
}

.custom-select-caret .form-multi-select.show,
.custom-select-caret .form-multi-select-with-cleaner>div[aria-expanded="true"] {
  padding-top: 15px;
}

.namedropdown {
  width: 100%;
}

.assigned-leads li {
  list-style: none;
}

.relationship {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

span.relationship-name {
  display: flex;
  justify-content: space-between;
}

span.relationship-name span {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #d48848;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 4px;
}

span.relationship-name span:hover {
  background-color: #d48848;
  color: #fff;
}

.assignmodal-spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-primary {
  height: 36px;
}

.assigned-leads {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto auto;
}

.assigned-leads li {
  background: #9883cb;
  display: flex;
  padding: 5px 12px;
  border-radius: 3px;
  color: #fff;
  text-transform: capitalize;
}

.form-flex {
  display: flex;
  align-items: baseline;
  gap: 15px;
}

.form-flex .form-multi-select-selection-tags {
  width: 50%;
}

.assigned-leads li span {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table tr td span.d-block,
#root h3 {
  text-transform: capitalize;
}

.form-control {
  height: calc(1.7em + 0.75rem + 2px);
}

.add-contact-input {
  position: relative;
}

.add-lead-search {
  position: absolute;
  right: 0;
  top: 31px;
}

.collapse-btn {
  display: none;
}

.rotated {
  transform: rotate(180deg);
}

.chervon-rotate {
  transition: transform 0.5s ease;
}

.form-flex .dropdown-menu.show {
  position: fixed !important;
}

// dashboard styling

#root .dashboard-accordian .accordion-item,
#root .mission-accordian .accordion-item {
  background-color: #fff;
  border-bottom: 1px solid #000;
}

.all-contact-card {
  padding: 20px;
  border-radius: 12px;
}

.dashboard-accordian .table thead th {
  text-wrap: nowrap;
  color: #000;
}

.dashboard-accordian .table td,
.dashboard-accordian .table th {
  min-width: 200px;
  color: #000;
  padding: 0.7rem 1rem;
}

.dark-text {
  color: #000;
}

.dashboard-accordian .table td:nth-child(2),
.dashboard-accordian .table td:nth-child(8),
.dashboard-accordian .table td:nth-child(13) {
  background-color: #87d6a2;
}

.dashboard-accordian .table td:nth-child(3) {
  background-color: #000;
  color: #fff;
}

.dashboard-accordian .table td:nth-child(9) {
  background-color: #ab94e0;
}

.dashboard-accordian .table td:nth-child(11) {
  background-color: #999;
}

.dashboard-accordian .table td:nth-child(14),
.dashboard-accordian .table td:nth-child(15) {
  background-color: #9abcf3;
}

// Mission styling

.mission-card-header .parent-search.form-control {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.mission-card-header .iconsearch {
  line-height: 43px;
}

.mission-card-header button.searchfilter {
  padding: 10px 19px;
  height: 40px;
}

.mission-card-header .searchBtn {
  width: 40px;
  height: 40px;
  right: 16px;
}

.mission-dropdown.dropdown {
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  height: 40px;
}

.mission-dropdown button.dropdown-toggle.btn.btn-secondary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mission-dropdown button.dropdown-toggle.btn.btn-secondary {
  background: #fff;
  border-radius: 8px;
  height: 38px;
  box-shadow: none !important;
}

.mission-card .card-header {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  height: 79px;
  border-bottom: none;
  padding: 20px 18px;
}

button.btn.btn-mission {
  background: #000;
  color: #fff;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #000;
}

.mission-dropdown .dropdown-menu.show {
  transform: translate(0px, 40px) !important;
}

h1.Title {
  position: absolute;
  top: -55px;
  z-index: 9;
  color: #000;
}

.mission-table tbody tr td {
  background-color: #ebe2ff;
  color: #000;
}

.mission-table tbody tr td:last-child {
  background-color: transparent;
}

.mission-table tr th:nth-child(1) {
  min-width: 560px;
}

.dashboard-accordian .mission-table tr th {
  color: #fff;
}

.table-black {
  background-color: #000;
  color: #fff;
}

.mission-table .Pending {
  background-color: #fad284;
}

.mission-table .needaudit {
  background-color: #baab98;
}

.mission-table tr td,
.mission-table tr th,
.master-mission-table tr td,
.master-mission-table tr th {
  border-color: #313131;
}

#root .master-mission-table tr td {
  color: #000;
}

#root .master-mission-table thead tr th,
#root .master-mission-table tr td {
  text-wrap: nowrap;
}

#root .master-mission-table td:nth-child(1) {
  max-width: 100%;
}

.sorting-icon {
  max-width: 15px;
}

.details .form-group {
  margin-bottom: 0;
}

#root .user-title {
  text-transform: capitalize;
}

#root .inner-btn1,
#modal-root .inner-btn1,
#root .inner-btn2,
#modal-root .inner-btn2,
#root .referral-btn,
#modal-root .referral-btn,
#root .gift-btn,
#modal-root .gift-btn {
  padding: 0 15px;
}

.sorting-right {
  margin-left: -5px;
}

.txt-black {
  color: #000;
}

.footer-txt {
  font-size: 18px;
  color: #000;
}

.footer-border {
  border-bottom: 1px solid;
  margin-bottom: 5px;
}

.email-btn {
  border: 1px solid #000;
  color: #000;
  margin-top: 12px;
  padding: 10px 26px;
}

button.btn.email-btn:hover {
  background: #000;
  color: #fff;
}

.shimmerTable {
  margin-bottom: 12px;
}

.shimmerBorder {
  padding-bottom: 10px;
  border-bottom: 5px solid #ebebeb;
}

input.form-control.search-form,
input.form-control.search-form:focus {
  border: none;
  box-shadow: none;
}

.searchform {
  position: relative;
}

.search-form-list {
  padding: 5px;
  z-index: 9999;
  position: absolute;
  width: 90%;
  left: 15px;
  max-height: 100px;
}

.searchlistdata {
  margin-left: 15px 20px;
}

#root .fixed_header thead,
#modal-root .fixed_header thead {
  top: -1px;
}

// .card-body.p-3.d-flex.align-items-center.flex-wrap.justify-content-between {
//     display: block!important;
// }

.cardflex {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Media Query starts from here

@media (max-width: 1600px) {
  #root .login-bg {
    height: calc(100vh - 10px);
  }

  .d-flex.flex-column.jutsify-content-between.h-100 {
    padding-bottom: 100px;
  }

  #root .filter-bar .filters,
  #modal-root .filter-bar .filters {
    width: auto;
    margin-bottom: 10px;
    grid-row-gap: 12px;
  }

  .checklistitem {
    gap: 22px 40px;
  }

  .siderow {
    padding: 0px 15px;
  }

  .scheduleTour {
    padding: 30px 15px;
  }
}

@media (min-width: 1200px) {
  .collapseExample {
    display: flex;
    justify-content: space-between;
    flex: auto;
  }
}

@media (min-width: 991px) {
  .btn-group__item {
    padding: 2rem 3rem;
  }

  #idx-filter-modal .modal-body {
    max-height: 72vh;
  }

  #root .c-sidebar,
  #modal-root .c-sidebar {
    margin: auto;
  }

  #root .c-wrapper,
  #modal-root .c-wrapper {
    margin-left: 256px;
  }
}

@media (max-width: 991px) {
  span.sidebar-close {
    display: block;
  }

  ul.dropdown-menu.filterdropdown-menu li {
    width: 100%;
  }

  .price-dropdown select.form-control {
    min-width: auto;
  }

  .HomeNamv .navbar {
    padding: 0px 15px;
    overflow-y: auto;
  }

  .custom-container {
    padding: 15px;
  }

  .navbar-nav {
    display: inline-block;
    line-height: 30px;
  }

  #root .login-bg .login-logo,
  #modal-root .login-bg .login-logo {
    width: 300px;
  }

  .flex-filters .navbar-nav {
    text-align: center;
    margin-top: 20px;
  }

  .navul {
    gap: 40px;
  }

  .searchform {
    margin-right: 0;
    min-width: auto;
  }

  .productlist {
    margin-bottom: 50px;
  }

  button.btn.footer-btn {
    padding: 3px 70px;
  }

  .bedrooms-btn {
    line-height: 50px;
  }

  #idx-filter-modal .modal-dialog {
    max-width: 750px;
  }

  .btn-group__item {
    padding: 2rem 2.5rem;
  }

  #sale .price-details h1 {
    font-size: 40px;
  }

  .address span:first-child {
    font-size: 26px;
  }

  .address span:last-child {
    font-size: 20px;
  }

  .property-fecility img {
    width: 30px;
    height: auto;
  }

  .siderow {
    padding: 0px 15px;
  }

  .scheduleTour {
    padding: 30px 15px;
  }

  .profile-dropdown ul {
    min-width: 300px;
    right: 0px;
  }

  .container-fluid.team-container {
    padding: 54px 30px;
  }

  .navbarsearch {
    width: auto;
  }

  ul.dropdown-menu.filterdropdown-menu.pricedropdown.show {
    width: 476px;
  }

  button.btn.btn-login.signupbtn.loginbtn-top {
    float: initial;
    padding: 8px 20px;
  }

  // filter styling

  .filter-flex {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    margin-top: 20px;
  }

  .parent-filter-flex {
    display: block;
  }

  .dropdown.filterdropdown {
    margin-right: 0;
  }

  .filter-container {
    padding: 20px 0px;
  }

  .submitbtn {
    padding: 15px 80px;
    margin-bottom: 30px;
  }

  img.arrow-Back {
    top: 136px;
  }

  #root section.account .card {
    height: 280px;
  }

  section.profile {
    padding: 60px 0px;
  }

  .profile-container {
    padding: 50px 20px;
  }

  .HomeNamv .nav-link::after {
    visibility: hidden;
  }

  svg.tringle-bg {
    display: none;
  }

  #root .login-bg .card {
    padding: 0 30px;
    width: 100%;
  }

  span.mt-3.not-regidtered {
    right: -10px;
  }

  #root .signup-bg .card {
    padding: 0;
  }

  img.gallary-img,
  .left-img,
  .big-img {
    height: auto;
  }

  .desktopview {
    display: none !important;
  }

  .mobileview {
    display: block !important;
    right: 110px;
    position: absolute;
    top: 40px;
  }

  .modal-dialog {
    max-width: 710px;
  }

  .container-fluid.message-container-main {
    width: 100%;
  }

  .header-line {
    width: 56%;
  }

  .vl {
    left: 44.2%;
  }

  .social-icon-main {
    gap: 12px;
  }

  span.social-icon i {
    font-size: 18px;
  }

  span.social-icon {
    height: 45px;
    width: 45px;
  }

  .tabmain {
    gap: 0;
  }
}

@media (max-width: 1200px) {
  #root .login-bg {
    height: calc(100vh - 30px);
  }

  #login h1.signin-heading {
    margin-left: 0;
  }

  #root .login-bg .card {
    width: 100%;
    margin-top: 30px;
  }

  .d-flex.flex-column.jutsify-content-between.h-100 {
    padding-bottom: 0px;
  }

  .checklistMain {
    max-height: calc(100vh - 275px);
    overflow-y: auto;
  }

  .commission-wrapper input[type="text"],
  .warranty-wrapper input[type="text"],
  .date-input input[type="text"] {
    width: 100%;
  }

  .commission-wrapper,
  .date-input {
    gap: 20px;
  }

  .checkbox input[type="checkbox"] {
    height: auto;
  }

  #root .inner-section-bg,
  #modal-root .inner-section-bg {
    padding: 16px;
  }

  .customflex {
    margin-bottom: 10px;
  }

  .add-user {
    margin-top: 10px;
  }

  .container-fluid.team-container {
    padding: 0 50px;
  }

  #root button.btn.team-btn {
    width: 100%;
  }

  .team-left {
    margin-top: 20px;
  }

  section.account,
  .custom-bg {
    padding-top: 30px;
  }

  img.arrow-Back {
    width: 16px;
  }

  #root .main-heading {
    font-size: 38px;
    margin-bottom: 15px;
    margin-left: 10px;
  }

  .footer-right {
    margin-left: 30px;
  }

  section.profile {
    padding: 30px 50px;
  }
}

@media (max-width: 576px) {

  .stageinput th:nth-child(1),
  .stageinput th:nth-child(2),
  .stageinput td:nth-child(1),
  .stageinput td:nth-child(2) {
    position: initial;
  }

  .flex-header {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10px;
  }

  .table td:nth-child(1),
  .table td:nth-child(2),
  .table th:nth-child(1),
  .table th:nth-child(2) {
    padding: 0.75rem;
  }

  #root .mobile-flex tbody tr td,
  #modal-root .mobile-flex tbody tr td {
    display: table-row;
  }

  #root .mobile-flex thead {
    display: block;
  }

  .mobileviewedit {
    display: block;
  }

  .grid {
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    grid-column-gap: 8px;
  }

  .team {
    top: -15px;
    margin-bottom: 6px;
  }

  .desktopviewedit {
    display: none;
  }

  a.navbar-brand img {
    width: 150px;
  }

  #root .login-bg,
  #modal-root .login-bg,
  .signup-bg {
    padding-top: 0px;
  }

  #root .login-bg {
    height: calc(100vh - 95px);
  }

  #root .userprofile_section,
  #modal-root .userprofile_section {
    display: block;
  }

  span.sidebar-close {
    display: block;
  }

  #root .inner-section-bg,
  #modal-root .inner-section-bg {
    margin-top: 20px;
  }

  .checklistMain {
    max-height: calc(100vh - 160px);
  }

  .checklistitem {
    display: block;
  }

  #root .login-bg .login-logo,
  #modal-root .login-bg .login-logo {
    width: 18rem;
  }

  .listing-price-wrapper {
    margin-bottom: 0px;
  }

  .checkbox {
    display: block;
  }

  .listing-price {
    display: block;
    margin-bottom: 0px;
  }

  textarea.textarea {
    width: 100%;
  }

  .date-input {
    margin-bottom: 30px;
  }

  .transaction-wrapper,
  .warranty-wrapper {
    display: block;
  }

  .commission-wrapper {
    display: grid;
    grid: 65px / auto auto;
  }

  .listingtype {
    margin-bottom: 15px;
  }

  .wrapper1 {
    margin-bottom: 19px;
  }

  span.office-exc,
  span.custombgstyle {
    width: fit-content;
  }

  .input-margin {
    margin: 15px 0px;
  }

  .commission-wrapper-main .checkbox {
    display: block;
  }

  .display-flex {
    display: block;
    width: fit-content;
    margin-bottom: 30px;
  }

  .custom-margin {
    margin-bottom: 12px;
  }

  .checkbox label {
    white-space: normal;
  }

  .save-btn {
    margin-bottom: 15px;
    text-align: center;
    padding: 17px 50px;
  }

  // .customTxtArea textarea.form-control,
  // .stageinput .form-control,
  // .CustomDatepicker {
  //   width: 100% !important;
  // }

  #root .c-header,
  #modal-root .c-header {
    justify-content: flex-start;
  }

  ul.pl-3.align-items-start.top-right-menu.c-header-nav {
    width: 50px;
    padding-left: 0px !important;
  }

  #root .c-header .top-right-menu .c-header-nav-item,
  #modal-root .c-header .top-right-menu .c-header-nav-item {
    height: 52px;
  }

  .permission-check {
    margin-top: 20px;
  }

  .main-content-wrapper.card {
    height: calc(100vh - 85px);
  }

  // #root .fixed_header,
  // #modal-root .fixed_header {
  //   height: 100vh;
  // }

  img.c-sidebar-brand-full {
    width: 8rem;
  }

  #root .tab-content.overflow-scroll,
  #modal-root .tab-content.overflow-scroll {
    min-height: auto;
  }

  #root .userprofile-social,
  #modal-root .userprofile-social {
    display: block;
  }

  #root .userprofile-socialbtn,
  #modal-root .userprofile-socialbtn {
    margin-top: 12px 0px 4px 0px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .emailmodalicon {
    align-items: center;
  }

  .inner-section .ml-5 {
    margin-left: 0px !important;
  }

  .inner-section .pl-5 {
    padding-left: 0px !important;
  }

  #root .fav-section,
  #modal-root .fav-section {
    display: block;
  }

  // .table th,
  // .table td {
  //   padding: 0.75rem 0.2rem;
  // }

  .customflex {
    display: grid;
    grid-template-columns: auto auto;
  }

  .collapsebutton {
    display: flex;
  }

  div#collapseExample {
    margin-top: 15px;
  }

  .bg-nav.active {
    padding: 9px 0px;
  }

  .filter-container {
    padding: 0px 0px;
  }

  .copyright {
    font-size: 14px;
    padding: 12px;
  }

  .loginBtn,
  .loginBtn:hover {
    padding: 15px 48px;
  }

  .flex-filters .navbar-nav {
    margin: 0;
  }

  li.nav-item:last-child {
    // padding-right: 30px;
  }

  html:not([dir="rtl"]) .navbar-nav {
    padding-left: 0px;
  }

  .productlist {
    margin-bottom: 20px;
  }

  footer.footer {
    bottom: 0;
    padding: 10px 0px;
  }

  button.btn.footer-btn {
    padding: 3px 123px;
  }

  .custom-margin {
    margin-top: 20px;
  }

  .navHome {
    display: block;
  }

  .HomeNamv .navbar-nav {
    display: block;
    margin-left: 15px;
    line-height: 26px;
  }

  .filters-product span:last-child {
    font-size: 13px;
  }

  .big-img,
  .left-gallary {
    height: auto;
  }

  #sale .price-details h1 {
    text-align: center;
    font-size: 35px;
  }

  .action {
    justify-content: center;
  }

  .address,
  .square,
  .scheduled-square,
  .pricediv,
  .attractiondiv {
    margin-bottom: 20px;
  }

  .property-fecility {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
    justify-content: inherit;
  }

  .address span:first-child {
    font-size: 22px;
  }

  .sub-address-inner {
    font-size: 16px;
  }

  .agentname {
    margin-top: 20px;
  }

  .property-overview {
    margin-bottom: 20px;
  }

  button.viewallphoto {
    width: 138px;
    height: 38px;
    bottom: 4px;
    right: 8px;
    font-size: 14px;
  }

  #root .login-bg .card,
  #modal-root .login-bg .card {
    padding: 0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  #login h1.signin-heading,
  #reset h1.reset-heading {
    margin-left: 6px;
  }

  .login-option {
    position: absolute;
    bottom: -854px;
    padding-bottom: 40px;
  }

  #root .signup-bg .card-left {
    margin-bottom: 4px;
  }

  #root .signup-bg .signup-heading {
    margin-top: 30px;
  }

  .favourite {
    margin-top: 40px;
    margin-bottom: 110px;
  }

  #profile .profilename {
    font-size: 50px;
  }

  section.account {
    padding-bottom: 160px;
  }

  #root section.account .card .card-body {
    padding: 36px 20px;
  }

  .notifaction-card {
    align-items: center;
  }

  .arrow-btn {
    padding: 0px 14px;
    height: 45px;
  }

  #login h1.signin-heading {
    margin-top: 40px;
  }

  svg.tringle-bg {
    display: none;
  }

  span.mt-3.not-regidtered {
    right: 10px;
  }

  .dropdown.filterdropdown .filterdropdown-menu {
    width: auto;
  }

  .display-flex.grid-cusom {
    display: grid;
  }

  .dropdown.filterdropdown.pricefilter ul .btn-group {
    width: 100%;
  }

  footer.footer {
    padding: 0;
  }

  .productpagination {
    margin-bottom: 20px;
  }

  ul.dropdown-menu.filterdropdown-menu li {
    padding: 0px 16px 0px 16px;
  }

  .pricefilter ul.dropdown-menu.filterdropdown-menu li {
    padding: 0px 40px 0px 40px;
  }

  .filter-flex {
    grid-template-columns: auto auto;
  }

  .custom-flex {
    display: block;
  }

  ul.dropdown-menu.filterdropdown-menu.pricedropdown.show {
    width: auto;
    right: 0;
  }

  .display-flex-bed {
    gap: 12px;
  }

  span.rectangle {
    width: 90px;
    height: 90px;
    font-size: 14px;
  }

  .grid-cusom {
    gap: 12px;
  }

  button.btn.btn-done {
    padding: 14px 57px;
  }

  .price-dropdown {
    width: 200px;
  }

  .social-rightsec {
    margin-top: 30px;
  }

  .referal-card-content {
    padding: 0px 40px;
  }

  .referal-card-content p {
    width: auto;
  }

  .nofound_clas img {
    height: 44vh;
  }

  .nofound_clas {
    margin-bottom: 100px;
  }

  .profile-dropdown ul {
    right: -30px;
  }

  .back.profile-arrow-back {
    top: 112px;
  }

  .mobileview {
    top: 31px;
    right: 100px;
  }

  .siderow {
    margin-top: 30px;
  }

  .social-info-main input {
    width: auto;
    margin: 0px 18px;
  }

  .icon-social {
    gap: 10px;
  }

  .viewallphoto img {
    width: 18px;
  }

  #root section.account .card {
    height: auto;
  }

  .back {
    top: 115px;
  }

  .back:focus,
  .back:active {
    top: 106px;
  }

  #root section.account h1.main-heading {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 12px;
  }

  .gallery-modal .modal-dialog {
    min-width: auto;
  }

  .btnSchedule {
    padding: 5px 60px;
  }

  .container-fluid.message-container-main {
    width: auto;
  }

  .vl {
    display: none;
  }

  #root .message-heading {
    font-size: 28px;
  }

  button.btn.message-btn {
    width: 145px;
    height: 48px;
    font-size: 14px;
  }

  section.profile.message {
    height: auto;
  }

  #root .c-main {
    max-height: 100%;
  }

  .collapse-btn {
    display: block;
    margin-top: 10px;
  }

  #root .clickable-card {
    margin-top: 10px;
  }

  .flex-user {
    display: grid;
    grid-template-columns: 2fr 2fr 0fr;
  }

  button.clear-btn {
    margin-left: 8px;
  }

  .social-icon-main {
    gap: 15px;
  }

  span.social-icon {
    height: 40px;
    width: 40px;
  }

  .footer-left {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .footer-right {
    margin-left: auto;
  }

  .footer-left img {
    width: 250px;
  }

  .social-icon-main {
    margin: 20px 0;
  }

  .right-border {
    border: none;
  }

  .singleproduct {
    margin-top: 20px;
  }

  img.team-img {
    max-width: 90px;
  }

  #root button.btn.team-btn {
    height: 50px;
  }

  .card-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  section.account,
  .custom-bg {
    padding-top: 40px;
  }

  .container-fluid.team-container {
    padding: 40px 30px;
  }

  section.profile {
    padding: 48px 0px;
  }

  .transaction-card-btn {
    display: block;
  }

  .tabbtn {
    padding: 0px 15px;
  }
}

// home page css start
.header-bar {
  padding: 10px;
  background-color: #ab94e0;
}

.top-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.head-btn {
  border: 2px solid black;
  padding: 5px 20px;
  border-radius: 35px;
  background-color: white;
}

.req-btn {
  border: 2px solid black;
  padding: 5px 20px;
  border-radius: 8px;
  background-color: white;
}

.top-header button {
  margin-right: 50px;
}

.nav-section .inner-area {
  display: flex;
  padding: 20px;
  align-items: center;
}

.nav-wrapper {
  border-bottom: 0.5px solid #00000040;
}

// .nav-container {
//   margin: 0 auto;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 20px;
// }

.nav-heart-user {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  gap: 5px;
  border: 1px solid #000;
  border-radius: 30px;
  padding: 10px;
  margin-left: 20px;
  width: 120px;
}

.nav-heart>i {
  font-size: 20px;
}

.nav-user>i {
  font-size: 20px;
}

.line {
  width: 1px;
  height: 32px;
  background: #000;
}

.close-icon {
  position: relative;
}

.close-icon>i {
  position: absolute;
  top: -30px;
  right: 50px;
  font-size: 20px;
}




.milford-google-form {
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  right: 50%;
  background: #fff;
  transform: translate(50%, 10%);
  padding: 10px;
  width: 100%;
  max-width: 570px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .googleform {
    width: 100%;
    display: flex;
    align-items: end;
  }

  .distance-box {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 1rem;

    &>div {
      display: flex;
      min-width: 188px;
    }
  }
}
.scroll-y{
  overflow: auto;
}

.edit-doc-modal{
  width: 80%;
    height: 80vh;
    position: absolute;
    z-index: 999;
    border: 1px solid grey;
    border-radius: 10px;
    background: #fff;
}
.overflow-y{
  overflow-y: auto;
}
.manage-height {
  height: auto !important;
.table-responsive {
  overflow-y: auto;
  // max-height: calc(100vh - 220px);
  margin-top: 10px;
  // overflow-x: ;
}
}
.offer-form-loader{
  position: absolute;
  right: 50%;
  top: 50%;
  z-index: 889;
  margin-top: 50px;
}
.form-submitted{
  position: absolute;
    margin-top: 60px;
    right: 43%;
}

.offerFormSubmitedTxt{
  background: #000;
  color: #fff;
  padding: 20px;
  border-radius: 25px;
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}
.agentCallBtn, .agentMsgBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .actionIcon{
     margin-bottom: 10px;
  }
  .agentCallTxt, .agentMsgText{
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }
}


.Toastify__toast-container.Toastify__toast-container--top-right{
  top: 5em !important;
}

section.main-section.update-offer-form {
  padding: 0 20px;
}
.bg-fade{
  position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 2;
    top: 0;
    left: 0;
    border-radius: 15px;
}
.form-select.btn.btn-border.btn-client-type option{
text-align: left;
padding: 10px;
}

.agreeCheckbox input[type="checkbox"]{
  width: 20px;
  height: 20px;
  }
.regiter-errorMsg{
  color: #ff0000;
  margin-top: 2px;
  // position: absolute;
  }
  .document_set{
    overflow: auto;
    height: 90vh;
margin: 10px;
border: 1px solid grey;
  }
  .custom_select_dropdown .css-1u9des2-indicatorSeparator{
display: none;
  }

  @mixin theme-bg-primary {
    background-color: #ebe2ff;
  }
.resetPassword .modal-dialog .modal-content{
  @include theme-bg-primary;
  color: #000;
  .modalCloseIcon{
    margin: 40px 40px 50px 0px;
    text-align: right;
    cursor: pointer;
  }
  .title{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 29px;
  }
  .closePopupBtn-white{
    margin-top: 65px;
    width: 182px;
    height: 60px;
    border-radius: 50px;
    border: 1px solid #000;
    background: #FFF;
    color: #000;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: 106px;
  }
}
.userProfileImg{
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  margin-top: 30px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}



// 30-07-2024
.back.profile-arrow-back:focus, .back.profile-arrow-back:active {
  top: inherit !important;
}

.card-transaction-btn ul.contact-dropdown {
  width: 100%;
}
.react_select .css-13cymwt-control, .react_select .css-t3ipsp-control {
  border-color: #000 !important;
  min-height: 60px;
  box-shadow: none;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  
}

.react_select .css-19bb58m, .react_select .css-t3ipsp-control .css-19bb58m{
  color: #000;

}
.react_select .css-1u9des2-indicatorSeparator, .react_select .css-t3ipsp-control .css-1u9des2-indicatorSeparator {
  display: none;
}
.react_select .css-tj5bde-Svg {
  fill: #000;
}
@media (max-width:1440px) {
  #root .card-transaction-btn ul.contact-dropdown .contact-details-card .card-body {
    padding: 30px 15px;
}
#root .card-transaction-btn button.btn.callbtn {
  width: 100%;
  height: 96px; 
}
}


.d-none{
  display: none;
}
.accounting_team.widthBtn{
  max-width: 350px;
}
.closing_card.input-validation-wrapper .form-control{
          min-height: 50px !important;
        font-size: 14px;
        border: 1px solid #000;
    padding: 0 10px;
    box-shadow: none;
}
.search-dropdown.state-dropdown {
  position: absolute;
}
.bottom-custom-dropdown {
  position: relative;
}
.bottom-custom-dropdown .search-dropdown.state-dropdown {
  bottom: 53px;
}


//  -----------------------------------------------------------------------KR ----------------------------------
.filter-modal-form span.rectangle.active img{
  filter: brightness(0)invert(1);
}
.filter-model .btn-close{
  position: absolute;
  right: 14px;
  }
  .range-drop select{
  padding: 8px;
  border-color: #e4e7ea;
  }
  .append-range{
    top: 2px;
  }
  .state-grid .state-container{
    display: flex;
    width: 140px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #000;
    background: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  .state-grid .state-container .state-txt{
    display: block;
    color: #000;
    font-weight: 500;
  }
  .state-grid .state-container:hover {
    background-color: #000;
    color: #fff;
  }
  .state-grid .state-container:hover .state-txt{
    color: #fff;
  }
  .form-select:focus{
    box-shadow: none;
  }
  .range-drop .css-13cymwt-control,  .range-drop .css-t3ipsp-control{
  padding: 4px;
  border-color: #e4e7ea;
  border-radius: 6px;
  min-height: 46px;
  }
  .range-drop .css-t3ipsp-control {
    min-height: 46px;
    box-shadow: none;
  }
  .range-drop .css-1u9des2-indicatorSeparator{
  display: none;
  }
  span.btn.btn-circle {
    padding: 4px 22px;
    border-radius: 69px;
    border: 1px solid var(--light-main-border-divider-border-default, #dfe2e6);
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-right: 15px;
  }
  
  span.btn.btn-circle.active {
    background: #000;
    color: #fff;
  }
