.c-sidebar {
  @include border-radius(10px);
  @include bp(tablet) {
    @include border-radius(0);
    padding-top: 12px;
  }
  @media (min-width: 992px)  {
    margin: 12px;
  }
  .c-sidebar-brand {
    background: $secondary;
    border-radius: 10px 10px 0 0;
  }
  .c-sidebar-nav {
    padding: 0 10px;
    li {
      a {
        margin-top: 4px;
        @include border-radius(4px);
        &.c-active {
          background: $info;
          @include shadow;
          &:hover {
            background: $info;
          }
        }
        &:hover {
          background: rgba($info, .2);
          @include transition;
        }
      }
    }
    .sub-nav-item {
      margin-left: 36px;
      margin-top: 0;
      padding: 8px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -10px;
        height: 100%;
        width: 2px;
        background: $info;
      }
    }
  }
}

.c-sidebar-dark {
  background-color: $secondary;
}

.banner {
  background: url('/images/header-bg.jpg');
  background-size: cover;
  height: 175px;
  width: 100%;
  position: absolute;
  z-index: 0;
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: $info;
    opacity: .7;
  }
}

.c-header {
  background: transparent;
  box-shadow: none;
  @include bp(tablet) {
    position: relative;
    justify-content: space-between;
  }
  .c-header-brand {
    @include bp(tablet) {
      background: rgba($secondary, .8);
      padding: 0 8px;
    }
  }
  .page-header-title {
    color: white;
  }
  .top-right-menu {
    .c-header-nav-item {
      height: 36px;
      display: flex;
      align-items: center;
      background: $secondary;
      @include border-radius(0 0 4px 4px);
      color: white;
      .c-header-nav-link {
        color: white;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
}
