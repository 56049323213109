@mixin bp($point) {
  @if $point == desktoponly {
    @media (min-width: 1024px) { @content; }
  }
  @else if $point == laptop {
    @media (max-width: 1700px) { @content; }
  }
  @else if $point == tablet {
    @media (max-width: 1024px)  { @content; }
  }
  @else if $point == mobile {
    @media (max-width: 500px)  { @content; }
  }
}

@mixin transition {
  transition: 0.3s all ease;
}


@mixin rounded($radius: 4px) {
  border-radius: $radius;
}

@mixin border-radius($pixel...) {
  border-radius: $pixel;
}

@mixin shadow {
  box-shadow: rgba(60, 75, 100, 0.14) 0px 1px 1px 0px, rgba(60, 75, 100, 0.12) 0px 2px 1px -1px, rgba(60, 75, 100, 0.2) 0px 1px 3px 0px;
}
