.nav-tabs {
  border: none;
  .nav-item {
    margin-right: 30px;
    @include bp(mobile) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
    .nav-link {
      border: none;
      font-weight: normal;
      color: $text-color;
      border-bottom: 2px solid transparent;
      &.active {
        border-bottom: 2px solid $info;
        font-weight: 500;
        background: transparent;
        &:hover {
          border-bottom: 2px solid $info;
        }
      }
      &:hover {
        border-bottom: 2px solid rgba($info, .5);
      }
    }
  }
}
.tab-content {
  padding: 1rem;
  margin-top: .5rem;
  background: $light;
  &.overflow-scroll {
    overflow-y: auto;
    position: relative;
    min-height: 400px;
    .tab-pane.active {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: calc(100% - 2rem);
    }
  }
}
