.custom-dropdown {
  max-width: 50%;
  background: #231236;
  color: #fff;
  border-radius: 0px;
  border: 1px solid #231236;
  max-height: 31px;
}

.custom-btn {
  border: 1px solid #231236;
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  color: #121214;
  max-height: 31px;
  text-decoration: none;
}

.font-size-set {
  font-size: 40px;
  margin-top: 10px;
  font-family: serif;
}

.accordionheader {
  background-color: #ab94e0;
  display: flex;
  $accordion-icon-transform: rotate(-180deg);
}

button.accordion-button.collapsed {
  background: none;
  color: #1c052b;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
}

button.accordion-button.collapsed h4 {
  margin-bottom: 0px;
}

button:focus {
  outline: none;
}

button.accordion-button {
  background: none;
  color: #1c052b;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.accordion-body {
  padding: 10px;
  background: #fff;
}

button.btn-global {
  background: #231236;
  border: none;
  margin: 2px;
  color: #fff;
  font-size: 12px;
  padding: 5px 15px;
}

.relationship_status {
  margin: 0.8rem;
  font-weight: bold;
}

.light_content {
  font-weight: 100;
}

.icon-right {
  margin-left: 3rem;
}

.accordion-item {
  background-color: #ab94e0;
}

.accordion__arrow h4 {
  font-size: 15px;
  $accordion-icon-transform: rotate(-180deg);
}

.form-border {
  border: 1px solid #000;
  padding: 8px 15px;
}

.btn-purple {
  border: 1px solid #ab94e0;
}

button.btn-global.btn-align {
  text-align: left;
}

.text-black {
  color: #000;
}

.h6-custom {
  font-size: 14px;
}

.sendemail_text {
  display: flex;
}

.profile_connections {
  display: flex;
}

.accordian-head {
  font-size: 16px;
  padding: 10px 8px;
  background-color: #ab94e0;
  color: #fff;
  margin: 0;
}

.accor-content-area {
  background-color: #fff;
  padding: 20px 0 20px 12px;

  .form-border span svg {
    margin-top: -7px;
  }

  .col p {
    color: #000;
  }
}

.commun-log-col-bg {
  padding: 0 !important;
  text-align: center;

  h5 {
    background-color: #000;
    font-size: 14px;
    font-style: normal;
    color: #fff !important;
    margin: 0;
    padding: 10px 8px;
    font-weight: 700;
  }
}

.commun-log-col-border {
  border-left: 1px solid #82788d;
  border-right: 1px solid #82788d;
}

.transtn-bordr-botm {
  border-bottom: 1px solid #82788d;
  padding-bottom: 12px !important;
}

.email-btn-send>div {
  display: inherit;
}

.main-top-btns svg {
  margin-top: -5px;
}

.row-box {
  display: flex;
  flex-wrap: wrap;
}

// UserProfile CSS
.text-purple {
  color: #ac94e0;
}

.user-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
}

.relationship_user {
  display: flex;
  flex-direction: row;
}

.relationship_status_to {
  text-decoration: underline;
}

.userdetails-update {
  display: flex;
  padding: 34px 0;
}

.edit_user_button {
  border: 1px solid black;
  border-radius: 18px;
  font-weight: 700;
  padding: 10px 34px;
  margin-right: 15px;
  cursor: pointer;
}

.edit_userBtn {
  background-color: #ab94e0;
  color: #000;
}

.Delete_userBtn {
  background-color: black;
  color: #fff;
}

.inner-section {
  border: 1px solid #776799;
  border-radius: 16px;
  padding: 25px;
}

.contact_details .form-control {
  background-color: #f0eaff;
  border-color: #47454c;
  border-radius: 0;
  margin-bottom: 12px;
  height: 50px;
}

.details_title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
}

.inner-section-bg {
  background: #e2d5ff;
  border-radius: 16px;
  padding: 30px;
}

.userprofile_button {
  border: 1px solid #ab94e0;
  padding: 3px 30px;
  font-size: 0.9rem;
  font-weight: 600;
}

.userprofile_img {
  border-radius: 50%;
}

.userprofile_section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

.userprofile-social {
  display: flex;
  gap: 10px;
}

.userprofile-socialbtn {
  background: #e2d5ff;
  border: 1px solid #000;
  padding: 6px 20px;
  border-radius: 11px;
  color: #000;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
}
.arrow-down {
  text-align: center;
}
.arrow-down svg{
  border: 1px solid #e2d5ff;
  padding: 11px;
  border-radius: 50%;
  color: #000;
  width: 50px;
  height: 50px;
  background: #e2d5ff;
}
.fav svg{
  background-color: #fff;
}

.contact_details_inputfield {
  height: 50px !important;
}

.contact_details_button {
  text-align: center;
}

.contact_details_button button {
  border-radius: 0.5rem;
  padding: 14px 25px;
  width: 15rem;
  margin-top: 3rem;
}

.inner-btn {
  background: #3b0082;
  border-radius: 0;
  padding: 5px 30px;
}

.sub_title_inner {
  font-size: 0.96rem;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.inner-btn1 {
  background: #e2d5ff;
  border: 1px solid #000;
  padding: 10px 30px;
  border-radius: 11px;
}

.inner-btn2 {
  background: #fff;
  border: 1px solid #000;
  padding: 10px 30px;
  border-radius: 11px;
}

.btn-request {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 11px;
}

.review-btn {
  background: #e2d5ff;
  font-weight: 500;
  width: 100%;
  max-width: 150px;
}

.gift-btn {
  background: #99784a;
  border-radius: 0;
  padding: 5px 30px;
  width: 100%;
  max-width: 150px;
}

.referral-btn {
  background: #fff;
  border-radius: 0;
  padding: 5px 30px;
  width: 100%;
  max-width: 150px;
}

.activity {
  font-size: 1.0rem;
}

.fav-section {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  background: #fff;
  border-radius: 17px;
  padding: 20px 20px;
  width: 100%;
  max-width: 340px;
  margin: auto;
}

@media (max-width: 1700px) {
  .row-box {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px;
  }
}