.form-subheader {
  padding: 1rem 0;
  margin: 1.5rem 0;
  color: $info;
  border-bottom: 1px solid $light;
  &:first-child {
    margin-top: .5rem;
  }
}

.textarea-with-controls {
  border: 1px solid #e4e7ea;
  background-color: white;
  border-radius: 2px;
  textarea {
    border: none;
    resize: none;
  }
  .textarea-controls {
    text-align: right;
  }
}

.card-header {
  .form-group {
    margin-bottom: 0;
  }
}

.input-group.prepend {
  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.form-section {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba($dark, .2);
}

.fixed-button-bar {
  position: sticky;
  bottom: -20px;
  padding: 0.5rem;
  display: flex;
  justify-content: end;
  background-color: $dark;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px -6px 15px 7px rgba($light,1);
}

.fixed-button-bar-slidein {
  position: sticky;
  bottom: -20px;
  padding: 0.5rem;
  display: flex;
  justify-content: start;
  background-color: $dark;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px -6px 15px 7px rgba($light,1);
}


.file-input {
  background-color: rgba(white, .2);
  border: 2px dashed rgba($dark, .5);
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 15px;
    img {
      max-width: 100%;
    }
}
.contact-modal-form {
.typeahead-wrapper {
  background: white;
  z-index: 2;
  width: 100%;
  max-height: 200px;
  padding: .5rem;
  border: 1px solid #e4e7ea;
  border-radius: 0 0 0.25rem 0.25rem;
  margin-top: -3px;
  margin-bottom: 10px;
    .form-group {
      margin-bottom: .5rem;
    }
    .typeahead-options {
      max-height: 140px;
      width: 100%;
      overflow-y: auto;
    }
}
}
// secondary-card-header //
.secondary-contact-card {
  max-width: 60%;
}