.c-main > .container-fluid {
  position: relative;
}

.growler-wrapper {
  position: fixed;
  bottom: 10px;
  margin-left: 10px;
  .dismiss {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
  }
}