@font-face {
    src: url('./Secular-Light.otf') format("otf"),
         url('./Secular-Light.woff2') format("woff2"),
         url('./Secular-Light.woff') format("woff");
    font-family: 'Secular';
    font-style: normal;
    font-weight: 300;
  }
@font-face {
    src: url('./Secular-Regular.otf') format("otf"),
         url('./Secular-Regular.woff2') format("woff2"),
         url('./Secular-Regular.woff') format("woff");
    font-family: 'Secular';
    font-style: normal;
    font-weight: normal;
  }
@font-face {
    src: url('./Secular-Thin.otf') format("otf"),
         url('./Secular-Thin.woff2') format("woff2"),
         url('./Secular-Thin.woff') format("woff");
    font-family: 'Secular';
    font-style: normal;
    font-weight: 200;
  }
@font-face {
    src: url('./Secular-Variable[wght].ttf') format("ttf"),
         url('./Secular-Variable[wght].woff2') format("woff2"),
         url('./Secular-Variable[wght].woff') format("woff");
    font-family: 'Secular';
    font-style: normal;
    font-weight: normal;
  }