.clickable-icon {
  cursor: pointer;
  @include transition-default;
  &:hover {
    opacity: .65;
  }
  &.disabled {
    pointer-events: none;
    opacity: .45;
  }
}


.large-icon {
  width: 3rem;
  height: 3rem;
  color: $primary;
}


// Icon does not appear centered due to shape
.offset-icon {
  margin-top: -4px !important;
}