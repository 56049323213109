.email-form {
  .form-group.inline {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light;
    padding-bottom: 10px;
    margin-bottom: 10px !important;
    @include bp(mobile) {
      flex-wrap: wrap;
    }
    label {
      margin-bottom: 0;
    }
    .form-control {
      border: none;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .textarea-controls.wysiwyg-controls {
    padding: 4px;
    margin: 10px;
    display: inline-flex;
    text-align: left;
    border-radius: 2px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12), 0px 2px 4px -1px rgba(0, 0, 0, .20);
  }
  p.badge {
    font-size: 100%;
  }
}

//email modal //

.wysiwyg-box {
  .ql-container {
    min-height: 200px;
  }
  .ql-editor {
    min-height: 200px;
  }
}
.email-form {

  .form-group.inline {

    .form-group {
      margin-bottom: 0;
    }
  }
  .typeahead-wrapper {
  margin-left: 15px;

  }

  .typeahead-options {

    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: white;
    padding: 10px;
    z-index: 100;
    margin-top:5px;

  .clickable-icon {
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    &:hover{
      background: $light;
    }
    }
  }
}