.full-height-embed {
  text-align: center;
  iframe {
    width: 75%;
    margin: auto;
    border: none;
    height: 100vh;
    @include bp(laptop) {
      width: 100%;
    }
  }
}
